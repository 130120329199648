import React, { useState } from "react";

import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { HeaderComponent } from "../../../../_default/interfaces/base";
import { useAppSelector } from "../../../../../store/hooks";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";

export default function HeaderMenu({ id }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as HeaderComponent;

  let widthTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "rem",
      label: "rem",
    },
    // {
    //   value: "%",
    //   label: "%",
    // },
  ];

  let maxWidthTypes = [
    {
      value: "px",
      label: "px",
    },
  ];

  const [selectedGap, setSelectedGap] = useState(
    component.attributes.headerGapNumber
      ? component.attributes.headerGapNumber
      : "0"
  );

  const [selectedGapUnit, setSelectedGapUnit] = useState(
    component.attributes.headerGapUnit
      ? component.attributes.headerGapUnit
      : "px"
  );

  const [selectedWidth, setSelectedWidth] = useState(
    component.attributes.headerWidthNumber
      ? component.attributes.headerWidthNumber
      : "0"
  );

  const [selectedWidthUnit, setSelectedWidthUnit] = useState(
    component.attributes.headerGapUnit
      ? component.attributes.headerGapUnit
      : "px"
  );

  function extractNumbers(str: string | number) {
    if (str === "") {
      return 0;
    }

    if (str === 0) {
      return 0;
    }

    if (str === "0") {
      return 0;
    }

    if (str === "none") {
      return 0;
    }

    if (str === "small") {
      return 12;
    }

    if (str === "medium") {
      return 16;
    }

    if (str === "large") {
      return 20;
    }

    let numbers;

    if (typeof str === "string" && str.includes("blur")) {
      numbers = str.match(/blur\((-?\d+)px\)/);

      if (numbers[1] === null) {
        return 0;
      } else {
        return numbers[1];
      }
    } else if (typeof str === "string") {
      numbers = str.match(/^-?\d+/);

      if (numbers === null) {
        return 0;
      } else if (numbers[0] !== null) {
        return numbers[0];
      }
    }

    if (numbers) {
      return numbers[0];
    }

    return "";
  }

  const blurUnits = [{ value: "px", label: "px" }];

  const justifyItemTypes = [
    {
      value: "space-between",
      label: "space-between",
    },
    {
      value: "space-around",
      label: "space-around",
    },

    {
      value: "space-evenly",
      label: "space-evenly",
    },
    {
      value: "start",
      label: "left",
    },

    {
      value: "center",
      label: "center",
    },
    {
      value: "end",
      label: "right",
    },
  ];

  function onStickyChange(val: any) {
    // if (val.value === "none") {
    //   updateStructureComponent(id, "attributes.styles.backdropFilter", "none");
    // }

    updateStructureComponent(
      id,
      "attributes.styles.position",
      val.value === "none" ? "none" : "sticky"
    );

    updateStructureComponent(
      id,
      "attributes.styles.top",
      val.value === "none" ? "none" : "0px"
    );

    updateStructureComponent(
      id,
      "attributes.styles.zIndex",
      val.value === "none" ? 99 : 99
    );
  }
  const stickyPosition = [
    { value: "sticky", label: "Sticky" },
    { value: "none", label: "Unset" },
  ];

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Select
        onChange={onStickyChange}
        isSearchable={false}
        className="mr-2"
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={
          stickyPosition.find((x) => {
            return x.value === component.attributes.styles.position;
          })
            ? stickyPosition.find((x) => {
                return x.value === component.attributes.styles.position;
              })
            : stickyPosition[1]
        }
        options={stickyPosition}
        placeholder=""
      ></Select>

      <div
        className="background-menu_input"
        style={{ marginLeft: "5px", marginRight: "5px" }}
      >
        <span>blur(</span>
        <input
          type="number"
          style={{ width: "30px" }}
          className="global-editor__input builder-input"
          size={1}
          onChange={(e) => {
            updateStructureComponent(
              id,
              "attributes.styles.backdropFilter",
              `blur(${e.target.value ? extractNumbers(e.target.value) : 0}px)`
            );

            // updateStructureComponent(
            //   id,
            //   "attributes.components.language.size",
            //   e.target.value +
            //     languageSizeUnits.find(
            //       (x) =>
            //         x.value ===
            //         extractUnits(
            //           component.attributes.components.language.size ?? "12px"
            //         )
            //     )?.value
            // );
          }}
          value={extractNumbers(
            component.attributes.styles.backdropFilter ?? 0
          )}
        ></input>

        <span>)</span>
        <Select
          isSearchable={false}
          menuPortalTarget={document.getElementById("react-select-container")}
          className="m-0"
          styles={TextEditorSelectStyle}
          value={blurUnits[0]}
          onChange={(val) => {
            // updateStructureComponent(
            //   id,
            //   "attributes.components.language.size",
            //   extractNumbers(
            //     component.attributes.components.language.size ?? "12px"
            //   ) + val.value
            // );
          }}
          options={blurUnits}
          placeholder=""
        ></Select>
      </div>

      <span style={{ marginRight: "5px", marginLeft: "5px" }}>Max width: </span>

      <div style={{ display: "flex", gap: "5px" }}>
        <input
          type="number"
          className="global-editor__input builder-input spacing-menu_input"
          size={1}
          value={selectedWidth}
          onChange={(ev) => {
            // if (ev.target.value === "" || ev.target.value === "0") {
            //   setSelectedWidth("px");
            //   return;
            // }

            if (ev.target.value === "0") return;

            setSelectedWidth(ev.target.value);

            updateStructureComponent(
              id,
              "attributes.headerWidthNumber",
              ev.target.value
            );

            updateStructureComponent(
              id,
              "attributes.headerWidthUnit",
              selectedWidthUnit ? selectedWidthUnit : "px"
            );
          }}
        />

        <Select
          menuPosition="fixed"
          className="m-0 button-menu_input"
          isSearchable={false}
          styles={TextEditorSelectStyle}
          noOptionsMessage={({}) => ""}
          value={
            maxWidthTypes.find((x) => x.value === selectedGapUnit)
              ? maxWidthTypes.find((x) => x.value === selectedGapUnit)
              : maxWidthTypes[0]
          }
          onChange={(val) => {}}
          options={maxWidthTypes}
          placeholder=""
        ></Select>
      </div>
    </div>
  );
}
