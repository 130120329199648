import store from "../../../store";
import { Editor } from "./editorApi";
import {
  UPDATE_EDITOR,
  UPDATE_ACTIVE_EDITOR,
  UPDATE_EDITOR_STATE,
} from "./editorReducer";

export function updateEditor(payload: Editor) {
  return store.dispatch(UPDATE_EDITOR(payload));
}

export function updateEditorState(payload: { hasEdited: boolean }) {
  return store.dispatch(UPDATE_EDITOR_STATE(payload));
}

export function updateActiveEditor(payload: Editor) {
  return store.dispatch(UPDATE_ACTIVE_EDITOR(payload));
}
