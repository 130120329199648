import { addHeader, addFooter } from "../../../utils/Components";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { AllComponents } from "../../_default/interfaces/base";
import AddIcon from "@mui/icons-material/Add";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import usePageLanguage from "../../_default/hooks/usePageLanguage";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import ReusableComponentsAPI from "../../../store/reducers/builderReducers/reusableComponents/reusableComponentsApi";
import { ContentCopy } from "@mui/icons-material";
import StructureAPI, {
  loadWebpageComponents,
} from "../../../store/reducers/builderReducers/structure/structureApi";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import TemplateAPI from "../../../store/reducers/builderReducers/templates/templateApi";
import { setStructure } from "../../../store/reducers/builderReducers/structure/structureActions";
import InfoIcon from "@mui/icons-material/Info";
export default function AddHeaderOrFooter({ typeName }: any) {
  const templates = useAppSelector((state) => state.builder.templates);
  const pages = useAppSelector((state) => state.builder.pages);
  const structure = useAppSelector((state) => state.builder.structure);
  const languages = useAppSelector((state) => state.builder.languageSettings);

  const [filteredReusableHeadersMap, setFilteredReusableHeadersMap] = useState<
    any[]
  >([]);

  const [infoVisible, setInfoVisible] = useState(false);

  const [selectedReusableHeaderOption, setSelectedReusableHeaderOption] =
    useState<null | number>(null);

  const [headerOptionsOpen, setHeaderOptionsOpen] = useState(false);
  const [headerDetailOptionsOpen, setHeaderDetailOptionsOpen] = useState(false);
  const [headerExistingOptionsOpen, setHeaderExistingOptionsOpen] =
    useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState<
    null | number
  >(null);

  const locale = usePageLanguage();

  const [headerNameValue, setHeaderNameValue] = useState<string>("");

  var onClick = () => addHeader();
  if (typeName === "footer") {
    onClick = () => addFooter();
  }

  useEffect(() => {
    ReusableComponentsAPI.loadReusableComponents().then((x) => {});

    const allTemplates = templates.toJS();

    const headers = allTemplates
      .filter((x) => (x.component as any)?.type === "header")
      .map((x) => {
        console.log(x, "THI SIS THE X");

        return {
          name: x.name,
          language_id: x.language_id,
          id: (x.component as any).id,
          template_id: x.id,
        };
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    setFilteredReusableHeadersMap(headers);

    // if (pages.active) {
    //   StructureAPI.loadWebpageComponents(pages.active).then((x) => {
    //     console.log(x, "THIS IS THE STURCUTRE");
    //   });
    // }
  }, [structure, pages.active, headerExistingOptionsOpen]);

  return (
    <>
      <div
        className={`add-${typeName}`}
        style={{ cursor: "pointer" }}
        // onClick={() => {
        //   if (typeName === "header") {
        //     setHeaderOptionsOpen(!headerOptionsOpen);
        //   } else {
        //     onClick();
        //   }
        // }}
      >
        {/* {typeName === "header" && headerOptionsOpen ? (
          <>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "5px",
                padding: "5px",
              }}
            >
              <div
                className="pages-button"
                style={{
                  height: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: "12px",
                }}
                onClick={() => {
                  // addHeader();

                  setHeaderDetailOptionsOpen(true);
                }}
              >
                <NoteAddIcon style={{ fontSize: "15px" }} />
                Add new {`${typeName}`}
              </div>
              <div
                className="pages-button"
                style={{
                  height: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: "12px",
                }}
              >
                <CreateNewFolderIcon style={{ fontSize: "15px" }} />
                Add existing {`${typeName}`}
              </div>
            </div>
          </>
        ) : (
          ""
        )} */}

        {typeName === "footer" && (
          <span
            className="pages-button"
            style={{
              height: "fit-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              fontSize: "12px",
              textTransform: "capitalize",
              marginBottom: "4px",
            }}
          >
            + {`${typeName}`}
          </span>
        )}

        {typeName === "header" && !headerOptionsOpen && (
          <div
            className=""
            style={{
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              fontSize: "12px",
              minWidth: "270px",
              marginBottom: "4px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "5px",
                padding: "0px",
                width: "100%",
                paddingTop: "3px",
              }}
            >
              <div
                className="pages-button"
                style={{
                  height: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: "12px",
                }}
                onClick={() => {
                  //addHeader(null);
                  setHeaderExistingOptionsOpen(false);
                  setHeaderDetailOptionsOpen(!headerDetailOptionsOpen);
                }}
              >
                <AddIcon style={{ fontSize: "15px" }} />
                Add {`${typeName}`}
              </div>
              <div
                className="pages-button"
                style={{
                  height: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: "12px",
                }}
                onClick={() => {
                  setHeaderDetailOptionsOpen(false);
                  setHeaderExistingOptionsOpen(!headerExistingOptionsOpen);
                }}
              >
                <AddToPhotosIcon style={{ fontSize: "15px" }} />
                Select {`${typeName}`}
              </div>
            </div>

            {headerDetailOptionsOpen && !headerExistingOptionsOpen && (
              <div
                style={{
                  width: "100%",
                  padding: "5px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "start",
                    width: "100%",
                    justifyContent: "start",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>Header name</span>
                  <input
                    type="text"
                    id="headerNameInput"
                    style={{ width: "100%", fontSize: "15px" }}
                    value={headerNameValue}
                    className="form-input builder-input"
                    placeholder="Write header name ..."
                    onChange={(e) => {
                      setHeaderNameValue(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "5px",
                  }}
                >
                  <button
                    className="sidebar-save-btn"
                    onClick={() => {
                      // GEt the input value
                      const headerName = (
                        document.getElementById(
                          "headerNameInput"
                        ) as HTMLInputElement
                      ).value;

                      if (headerName) {
                        addHeader(null, headerName, locale);
                      }
                    }}
                  >
                    Add header
                  </button>
                </div>
              </div>
            )}

            {!headerDetailOptionsOpen && headerExistingOptionsOpen && (
              <div style={{ width: "100%", padding: "5px 10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "start",
                    width: "100%",
                    justifyContent: "start",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>
                    Select existing header
                  </span>
                  {filteredReusableHeadersMap?.map((header) => {
                    const flag = languages.activeLanguages.find(
                      (x) => x.id === header.language_id
                    )?.language.icon_url;

                    console.log(filteredReusableHeadersMap, "THIS IS THE FLAG");
                    const foundTemplate = templates.find(
                      (x) => x.id === header.template_id
                    );
                    return (
                      <>
                        <div
                          key={header.id}
                          className="singleHeaderOption"
                          style={{
                            justifyContent: "space-between",
                            border: `1px solid ${
                              selectedReusableHeaderOption === header.id
                                ? "#d6d6d6"
                                : "transparent"
                            }`,
                          }}
                          onClick={() => {
                            if (selectedReusableHeaderOption === header.id) {
                              setSelectedReusableHeaderOption(null);
                            } else {
                              setSelectedReusableHeaderOption(header.id);
                              setShowCopyConfirmation(null);
                            }
                          }}
                        >
                          <div>
                            <img
                              src={flag ? flag : ""}
                              style={{
                                width: "20px",
                                height: "auto",
                                marginRight: "5px",
                              }}
                            />
                            {header.name}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                if (showCopyConfirmation === header.id) {
                                  setShowCopyConfirmation(null);
                                  return;
                                }

                                setShowCopyConfirmation(header.id);
                                setSelectedReusableHeaderOption(null);
                              }}
                            >
                              <ContentCopy style={{ fontSize: "15px" }} />{" "}
                            </div>
                            <div
                              style={{
                                textAlign: "start",
                                textTransform: "none",
                              }}
                            >
                              {foundTemplate &&
                              foundTemplate.webpages?.map((x) => {
                                const pageName = pages.list.find(
                                  (xe) => xe.id === x
                                )?.name;

                                if (pageName) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })?.length > 0 ? (
                                <DeleteConfirmModal
                                  title={`
                                <div style="margin-top: 5px">
                                This template is used in the following pages: 
                                </div>
                                 <div>
                                 <b> 
                                 ${templates
                                   .find((x) => x.id === header.template_id)
                                   ?.webpages?.map((x) => {
                                     const pageName = pages.list.find(
                                       (xe) => xe.id === x
                                     )?.name;

                                     if (!pageName) return "";

                                     return `<span> - ${pageName}</span>`;
                                   })}
                                 </b>
                                    </div>
                                  
                                <div style="margin-top: 5px">
                                Please remove this header from the following pages before deleting it.
                                </div>
                                `}
                                  onConfirm={() => {
                                    TemplateAPI.deleteTemplate(
                                      header.template_id
                                    ).then((x) => {
                                      console.log(x);
                                      loadWebpageComponents(pages.active!);
                                    });
                                  }}
                                  showActionButton={false}
                                />
                              ) : null}

                              {foundTemplate &&
                                foundTemplate.webpages?.map((x) => {
                                  const pageName = pages.list.find(
                                    (xe) => xe.id === x
                                  )?.name;

                                  if (pageName) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                })?.length <= 0 && (
                                  <DeleteConfirmModal
                                    title={`Are you sure you want to delete ${header.name}? 
                                <br /> 
                                <div style="margin-top: 5px">
                                Deleting this will remove the header from templates. This action cannot be undone.
                                </div>
                                `}
                                    onConfirm={() => {
                                      TemplateAPI.deleteTemplate(
                                        header.template_id
                                      ).then((x) => {
                                        console.log(x);
                                        loadWebpageComponents(pages.active!);
                                      });
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        </div>

                        {selectedReusableHeaderOption === header.id && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "start",
                              alignContent: "center",
                              width: "100%",
                            }}
                          >
                            {selectedReusableHeaderOption === header.id &&
                              header.language_id !==
                                languages.activeLanguages.find(
                                  (x) => x.language.shortname === locale
                                ).id && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    alignItems: "start",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    New header name{" "}
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                        position: "relative",
                                      }}
                                    >
                                      <InfoIcon
                                        onMouseEnter={(e) => {
                                          e.stopPropagation();
                                          setInfoVisible(true);
                                        }}
                                        onMouseLeave={(e) => {
                                          e.stopPropagation();
                                          setInfoVisible(false);
                                        }}
                                      />

                                      {infoVisible && (
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "20px",
                                            fontSize: "14px",
                                            backgroundColor: "#333",
                                            width: "250px",
                                            padding: "15px",
                                            borderRadius: "3px",
                                            boxShadow:
                                              "0px 0.5px 1px rgba(0, 0, 0, 0.8), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12)",
                                            textTransform: "none",
                                            maxWidth: "300px",
                                            textAlign: "left",
                                            color: "#fcfcfc",
                                          }}
                                        >
                                          You are trying to add another language
                                          header to your page.
                                          <br /> NB! New header will get the
                                          current page language.
                                        </span>
                                      )}
                                    </div>
                                  </span>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <img
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                          objectFit: "cover",
                                        }}
                                        src={
                                          languages.activeLanguages.find(
                                            (x) =>
                                              x.language.shortname === locale
                                          ).language.icon_url
                                        }
                                      />
                                    </div>
                                    <input
                                      type="text"
                                      id="headerNameInput"
                                      style={{
                                        width: "100%",
                                        fontSize: "15px",
                                      }}
                                      value={headerNameValue}
                                      className="form-input builder-input"
                                      placeholder="Write header name ..."
                                      onChange={(e) => {
                                        setHeaderNameValue(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <button
                                    style={{ marginTop: "5px" }}
                                    className="sidebar-save-btn"
                                    onClick={() => {
                                      const component = templates.find(
                                        (x) => x.component?.id === header.id
                                      );

                                      const webpageComponent = {
                                        ...component?.webpage_component,
                                        childWebpageComponentRelations:
                                          component?.webpage_component
                                            ?.child_webpage_component_relations,
                                      };

                                      if (
                                        component &&
                                        component.webpage_component &&
                                        webpageComponent
                                      ) {
                                        // add component
                                        // Add await to this

                                        StructureAPI.addExistingComponent(
                                          pages.active!,
                                          {
                                            id: header.id,
                                            parent_id: null,
                                            sort_order: -1,
                                          }
                                        )
                                          .then((x) => {
                                            loadWebpageComponents(
                                              pages.active!
                                            ).then((xe) => {
                                              setStructure(xe);

                                              StructureAPI.duplicateComponent(
                                                pages.active!,
                                                xe.components[header.id]
                                              ).then((x) => {
                                                // Map over the x.payload[]

                                                let headerComponent;

                                                Object.entries(
                                                  x.payload
                                                ).forEach(([key, value]) => {
                                                  if (value.type === "header") {
                                                    headerComponent = value;
                                                  }
                                                });

                                                StructureAPI.deleteComponent(
                                                  pages.active!,
                                                  xe.components[header.id]
                                                ).then(() => {
                                                  // StructureAPI.deleteComponent(())

                                                  addHeader(
                                                    headerComponent.id,
                                                    headerNameValue,
                                                    locale,
                                                    true
                                                  );
                                                });
                                              });
                                            });
                                          })
                                          .then(() => {});
                                      }
                                    }}
                                  >
                                    Add header
                                  </button>{" "}
                                </div>
                              )}

                            {selectedReusableHeaderOption === header.id &&
                              header.language_id ===
                                languages.activeLanguages.find(
                                  (x) => x.language.shortname === locale
                                ).id && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    style={{ marginTop: "0px" }}
                                    className="sidebar-save-btn"
                                    onClick={() => {
                                      addHeader(selectedReusableHeaderOption);
                                    }}
                                  >
                                    Add header
                                  </button>
                                </div>
                              )}
                          </div>
                        )}

                        {showCopyConfirmation === header.id && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              alignItems: "start",
                              justifyContent: "start",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              New header name{" "}
                              <div
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  position: "relative",
                                }}
                              >
                                <InfoIcon
                                  onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    setInfoVisible(true);
                                  }}
                                  onMouseLeave={(e) => {
                                    e.stopPropagation();
                                    setInfoVisible(false);
                                  }}
                                />

                                {infoVisible && (
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "20px",
                                      fontSize: "14px",
                                      backgroundColor: "#333",
                                      width: "250px",
                                      padding: "15px",
                                      borderRadius: "3px",
                                      boxShadow:
                                        "0px 0.5px 1px rgba(0, 0, 0, 0.8), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12)",
                                      textTransform: "none",
                                      maxWidth: "300px",
                                      textAlign: "left",
                                      color: "#fcfcfc",
                                    }}
                                  >
                                    Creating a copy will duplicate the selected
                                    header.
                                    <br /> NB! New header will get the current
                                    page language.
                                  </span>
                                )}
                              </div>
                            </span>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <img
                                  style={{
                                    width: "20px",
                                    height: "auto",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    languages.activeLanguages.find(
                                      (x) => x.language.shortname === locale
                                    ).language.icon_url
                                  }
                                />
                              </div>
                              <input
                                type="text"
                                id="headerNameInput"
                                style={{ width: "100%", fontSize: "15px" }}
                                value={headerNameValue}
                                className="form-input builder-input"
                                placeholder="Write header name ..."
                                onChange={(e) => {
                                  setHeaderNameValue(e.target.value);
                                }}
                              />
                            </div>
                            <button
                              style={{ marginTop: "5px" }}
                              className="sidebar-save-btn"
                              onClick={() => {
                                const component = templates.find(
                                  (x) => x.component?.id === header.id
                                );

                                const webpageComponent = {
                                  ...component?.webpage_component,
                                  childWebpageComponentRelations:
                                    component?.webpage_component
                                      ?.child_webpage_component_relations,
                                };

                                if (
                                  component &&
                                  component.webpage_component &&
                                  webpageComponent
                                ) {
                                  // add component
                                  // Add await to this

                                  StructureAPI.addExistingComponent(
                                    pages.active!,
                                    {
                                      id: header.id,
                                      parent_id: null,
                                      sort_order: -1,
                                    }
                                  )
                                    .then((x) => {
                                      loadWebpageComponents(pages.active!).then(
                                        (xe) => {
                                          setStructure(xe);

                                          StructureAPI.duplicateComponent(
                                            pages.active!,
                                            xe.components[header.id]
                                          ).then((x) => {
                                            // Map over the x.payload[]

                                            let headerComponent;

                                            Object.entries(x.payload).forEach(
                                              ([key, value]) => {
                                                if (value.type === "header") {
                                                  headerComponent = value;
                                                }
                                              }
                                            );

                                            StructureAPI.deleteComponent(
                                              pages.active!,
                                              xe.components[header.id]
                                            ).then(() => {
                                              // StructureAPI.deleteComponent(())

                                              addHeader(
                                                headerComponent.id,
                                                headerNameValue,
                                                locale,
                                                true
                                              );
                                            });
                                          });
                                        }
                                      );
                                    })
                                    .then(() => {});
                                }
                              }}
                            >
                              Create a copy
                            </button>{" "}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
