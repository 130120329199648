import { FontOption } from './font_optionsApi'
import { Set } from 'immutable'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Set<FontOption> = Set([])

export const font_optionsSlice = createSlice({
  name: 'font_options',
  initialState,
  reducers: {
    SET_FONT_OPTIONS: (state: any, action: PayloadAction<FontOption[]>) => {
        state = Set(action.payload)
        return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { SET_FONT_OPTIONS } = font_optionsSlice.actions

export default font_optionsSlice.reducer
