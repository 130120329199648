import { fetchApiGet, fetchApi, postFormHeaders, postHeaders } from '../../../../api/api'
import {
    setDocumentsTree,
} from './documentsActions'

export interface Document {
  id: number
  parent_id: null | number
  editable: boolean
  originalname: string
  extension: string
  path: string
  size: number
  type: 'directory' | 'files' | 'reference'
  url: string
  visibility: 'private' | 'public'
  childElements: Array<Document>
  created_at: string
  updated_at: string
  deleted_at: string | null
}

const DocumentsAPI = {
    loadDocumentsTree,
    uploadDocuments,
    addReferenceFile,
}
export default DocumentsAPI


export function loadDocumentsTree() {
    return fetchApiGet<{ documents: Document[] }>(`/documents`, {}).then((res) => setDocumentsTree(res.documents))
}

export function addReferenceFile(payload: {}) {
  let promise = fetchApi<{ documents: Document[], addedCustomFiles: any }>(`/documents`, postHeaders({...payload, type: 'ref'}));
  promise.then((res) =>
    setDocumentsTree(res.documents)
  )
  return promise
}
  
export function uploadDocuments(payload: File[]) {
    const data = new FormData()
    for (let i = 0; i < payload.length; i++) {
      data.append('files[]', payload[i])
    }
    let promise = fetchApi<{ documents: Document[], addedCustomFiles: any }>(`/documents`, postFormHeaders(data));
    promise.then((res) =>
      setDocumentsTree(res.documents)
    )
    return promise
}
  