import store from "../../../store"
import { ReusableComponent } from './reusableComponentsApi'
import {
    UPDATE_REUSABLE_COMPONENTS,
} from './reusableComponentsReducer'

export function updateReusabeComponents(payload: ReusableComponent) {
    return store.dispatch(
      UPDATE_REUSABLE_COMPONENTS(payload)
    )
}