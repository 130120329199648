import { inClass } from "../../../../../utils/Helpers";

export const handleDragOverPos = (e, ref, isText) => {
  const builderElement = inClass("builder-component--active", ref.current!);
  e.preventDefault();

  if (isText) {
    return;
  }

  let targetElement = e.target;
  const rect = targetElement.getBoundingClientRect();

  // Get the bounding rectangle of the target element

  ////////////////////
  // TODO: NEEDS REFACTOR

  // The top, middle, and bottom positions as percentages (drop locations)
  // Can be adjusted to change the drop position (33.3, 33.3, 33.3) might work also.
  // NB! For some elements, these values are changed in corresponding if block
  const topPosition = 0; // Not needed (always 0)
  const middlePosition = 0.25 * rect.height; // 25% down from the top
  const bottomPosition = 0.75 * rect.height; // 75% down from the top

  // Get the current Y position of the mouse pointer relative to the target element
  const mouseY = e.clientY - rect.top;

  if (!builderElement) {
    return;
  }

  // 1) If component is a section
  if (builderElement.getAttribute("data-component-type") === "section") {
    if (builderElement.getAttribute("data-drag-type") === "main") {
      // Dragging a section element, that is top-level parent element
      // Need to check if the target is a section && is also top-level parent element

      if (
        targetElement.getAttribute("data-component-type") === "section" &&
        e.target.offsetParent &&
        !e.target.offsetParent.classList.contains("builder-component")
      ) {
        if (mouseY < middlePosition) {
          // Set the position to the builderElement

          targetElement.style.webkitBoxShadow =
            "inset 0px 10px 0px -2px #00d9ff";
          targetElement.style.mozBoxShadow = "inset 0px 10px 0px -2px #00d9ff";
          targetElement.style.boxShadow = "inset 0px 10px 0px -2px #00d9ff";
          targetElement.style.transition = `box-shadow 200ms ease-in-out`;

          builderElement?.setAttribute("data-position", "top");
          // e.target.style.borderBottom = `0px solid blue`;
        } else if (mouseY >= middlePosition && mouseY < bottomPosition) {
          targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 0px #00d9ff";
          targetElement.style.mozBoxShadow = "inset 0px 0px 0px 0px #00d9ff";
          targetElement.style.boxShadow = "inset 0px 0px 0px 0px #00d9ff";
          targetElement.style.transition = `none`;

          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "middle");
        } else {
          targetElement.style.webkitBoxShadow =
            "inset 0px -10px 0px -2px #00d9ff";
          targetElement.style.mozBoxShadow = "inset 0px -10px 0px -2px #00d9ff";
          targetElement.style.boxShadow = "inset 0px -10px 0px -2px #00d9ff";
          targetElement.style.transition = `box-shadow 200ms ease-in-out`;

          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "bottom");
        }

        return;

        // Dragging a section element, that is top-level parent element
        // Need to check if the target is a section && is also top-level parent element
      }
    } else if (
      builderElement?.getAttribute("data-drag-type") !== "main" &&
      builderElement.getAttribute("data-component-type") !== "inner-section"
    ) {
      if (
        targetElement.getAttribute("data-component-type") === "section" &&
        e.target.offsetParent.classList.contains("builder-component") &&
        (e.target.offsetParent.classList.contains("row") ||
          e.target.offsetParent.classList.contains("inner-section") ||
          e.target.offsetParent.classList.contains("builder-footer")) &&
        builderElement.id !== e.target.id &&
        e.target.offsetParent.offsetParent?.id !== builderElement.id
      ) {
        const topPosition = 0; // Not needed (always 0)
        const bottomPosition = 0.5 * rect.height; // 25% down from the top

        // Get the current Y position of the mouse pointer relative to the target element
        const mouseY = e.clientY - rect.top;

        if (mouseY < bottomPosition) {
          // Set the position to the builderElement

          targetElement.style.webkitBoxShadow =
            "inset 0px 10px 0px -2px #00d9ff";
          targetElement.style.mozBoxShadow = "inset 0px 10px 0px -2px #00d9ff";
          targetElement.style.boxShadow = "inset 0px 10px 0px -2px #00d9ff";
          targetElement.style.transition = `box-shadow 200ms ease-in-out`;

          builderElement?.setAttribute("data-position", "top");
        } else {
          targetElement.style.webkitBoxShadow =
            "inset 0px -10px 0px -2px #00d9ff";
          targetElement.style.mozBoxShadow = "inset 0px -10px 0px -2px #00d9ff";
          targetElement.style.boxShadow = "inset 0px -10px 0px -2px #00d9ff";
          targetElement.style.transition = `box-shadow 200ms ease-in-out`;

          builderElement?.setAttribute("data-position", "bottom");
        }

        return;
      } else if (
        targetElement.getAttribute("data-component-type") === "section" &&
        e.target.classList.contains("builder-component") &&
        e.target.classList.contains("row") &&
        !e.target.offsetParent.classList.contains("builder-component") &&
        !builderElement.offsetParent?.classList.contains("col") &&
        !e.target.offsetParent.classList.contains("inner-section") &&
        builderElement?.offsetParent?.id !== targetElement.id &&
        builderElement.id !== targetElement.id &&
        builderElement.getAttribute("data-component-type") !== "inner-section"
      ) {
        targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.boxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        // Set the position to the builderElement
        builderElement?.setAttribute("data-position", "middle");

        return;
      } else if (
        targetElement.getAttribute("data-component-type") === "inner-section" &&
        e.target.classList.contains("builder-component") &&
        e.target.offsetParent.classList.contains("col") &&
        builderElement?.offsetParent?.id !== targetElement.id &&
        builderElement.id !== targetElement.id
      ) {
        targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.boxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        // Set the position to the builderElement
        builderElement?.setAttribute("data-position", "middle");
      } else if (
        targetElement.getAttribute("data-component-type") === "footer" &&
        e.target.classList.contains("builder-component") &&
        !e.target.offsetParent.classList.contains("builder-component") &&
        !builderElement.offsetParent?.classList.contains("col") &&
        !e.target.offsetParent.classList.contains("inner-section") &&
        builderElement?.offsetParent?.id !== targetElement.id &&
        builderElement.id !== targetElement.id &&
        builderElement.getAttribute("data-component-type") !== "inner-section"
      ) {
        targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.boxShadow = "inset 0px 0px 0px 8px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        // Set the position to the builderElement
        builderElement?.setAttribute("data-position", "middle");
      }
      //else if (
      //   targetElement.getAttribute("data-component-type") !== "section" &&
      //   inClass("builder-component", e.target) &&
      //   inClass("col", e.target) &&
      //   e.target.classList.contains("builder-component") &&
      //   builderElement.id !== e.target.id &&
      //   !e.target.classList.contains("global-editor") &&
      //   targetElement.offsetParent.id !== builderElement.id
      // ) {
      //   targetElement.offsetParent.style.webkitBoxShadow =
      //     "inset 0px 0px 0px 8px #00d9ff";
      //   targetElement.offsetParent.style.mozBoxShadow =
      //     "inset 0px 0px 0px 8px #00d9ff";
      //   targetElement.offsetParent.style.boxShadow =
      //     "inset 0px 0px 0px 8px #00d9ff";

      //   // Set the position to the builderElement
      //   builderElement?.setAttribute("data-position", "middle");

      //   return;
      // }

      // Dragging a section element, that is not top-level parent element
      // Need to check if the target is a section && is not top-level parent element
    }
  }

  if (builderElement?.getAttribute("data-component-type") === "nav-item") {
    if (targetElement.getAttribute("data-component-type") === "nav") {
      targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.mozBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.boxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.transition = `box-shadow 200ms ease-in-out`;

      builderElement?.setAttribute("data-position", "middle");
    } else if (
      targetElement.getAttribute("data-component-type") === "nav-item"
    ) {
      const topPosition = 0; // Not needed (always 0)
      const bottomPosition = 0.5 * rect.height; // 25% down from the top

      // Get the current Y position of the mouse pointer relative to the target element
      const mouseY = e.clientY - rect.top;

      if (mouseY < bottomPosition) {
        // Set the position to the builderElement

        targetElement.style.webkitBoxShadow = "inset 0px 7px 0px -2px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px 7px 0px -2px #00d9ff";
        targetElement.style.boxShadow = "inset 0px 7px 0px -2px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        builderElement?.setAttribute("data-position", "top");
      } else {
        targetElement.style.webkitBoxShadow = "inset 0px -7px 0px -2px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px -7px 0px -2px #00d9ff";
        targetElement.style.boxShadow = "inset 0px -7px 0px -2px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        builderElement?.setAttribute("data-position", "bottom");
      }
    }
  }

  if (builderElement?.getAttribute("data-component-type") === "inner-section") {
    if (
      targetElement.getAttribute("data-component-type") === "section" &&
      e.target.offsetParent?.classList.contains("builder-component") &&
      !e.target.offsetParent?.classList.contains("inner-section") &&
      e.target !== builderElement.offsetParent
    ) {
      targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.mozBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.boxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.transition = `box-shadow 200ms ease-in-out`;

      builderElement?.setAttribute("data-position", "middle");
    } else if (
      e.target.offsetParent?.classList.contains("builder-component") &&
      !e.target.offsetParent?.classList.contains("inner-section") &&
      e.target.offsetParent?.classList.contains("col") &&
      !e.target.offsetParent?.offsetParent?.classList.contains("inner-section")
    ) {
      const topPosition = 0; // Not needed (always 0)
      const bottomPosition = 0.5 * rect.height; // 25% down from the top

      // Get the current Y position of the mouse pointer relative to the target element
      const mouseY = e.clientY - rect.top;

      if (mouseY < bottomPosition) {
        // Set the position to the builderElement

        targetElement.style.webkitBoxShadow = "inset 0px 10px 0px -2px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px 10px 0px -2px #00d9ff";
        targetElement.style.boxShadow = "inset 0px 10px 0px -2px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        builderElement?.setAttribute("data-position", "top");
      } else {
        targetElement.style.webkitBoxShadow =
          "inset 0px -10px 0px -2px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px -10px 0px -2px #00d9ff";
        targetElement.style.boxShadow = "inset 0px -10px 0px -2px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        builderElement?.setAttribute("data-position", "bottom");
      }
    }
  }

  // If component we are dragging is not a section
  if (
    builderElement?.getAttribute("data-component-type") !== "section" &&
    builderElement?.getAttribute("data-component-type") !== "inner-section" &&
    builderElement?.getAttribute("data-component-type") !== "nav-item"
  ) {
    if (
      targetElement.getAttribute("data-component-type") === "section" &&
      e.target.offsetParent.classList.contains("builder-component")
    ) {
      targetElement.style.webkitBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.mozBoxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.boxShadow = "inset 0px 0px 0px 8px #00d9ff";
      targetElement.style.transition = `box-shadow 200ms ease-in-out`;

      builderElement?.setAttribute("data-position", "middle");
    } else if (
      targetElement.getAttribute("data-component-type") !== "section" &&
      e.target.offsetParent?.classList.contains("builder-component") &&
      !e.target.classList.contains("global-editor") &&
      targetElement.offsetParent.id !== builderElement.id &&
      targetElement.id !== builderElement.id
    ) {
      // The top, middle, and bottom positions as percentages (drop locations)
      // Can be adjusted to change the drop position (33.3, 33.3, 33.3) might work also.
      const topPosition = 0; // Not needed (always 0)
      const bottomPosition = 0.5 * rect.height; // 25% down from the top
      // const bottomPosition = 0.75 * rect.height; // 75% down from the top

      // Get the current Y position of the mouse pointer relative to the target element
      const mouseY = e.clientY - rect.top;

      if (mouseY < bottomPosition) {
        // Set the position to the builderElement

        targetElement.style.webkitBoxShadow = "inset 0px 7px 0px -2px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px 7px 0px -2px #00d9ff";
        targetElement.style.boxShadow = "inset 0px 7px 0px -2px #00d9ff";

        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        if (
          targetElement.getAttribute("data-component-type") === "image" &&
          targetElement.firstChild &&
          targetElement.firstChild.classList.contains("builder-image--loaded")
        ) {
          targetElement.firstChild.style.webkitBoxShadow =
            "inset 0px 7px 0px -2px #00d9ff";
          targetElement.firstChild.style.mozBoxShadow =
            "inset 0px 7px 0px -2px #00d9ff";
          targetElement.firstChild.style.boxShadow =
            "inset 0px 7px 0px -2px #00d9ff";

          targetElement.firstChild.style.transition = `box-shadow 200ms ease-in-out`;
        }

        builderElement?.setAttribute("data-position", "top");

        // e.target.style.borderBottom = `0px solid blue`;
      } else {
        targetElement.style.webkitBoxShadow = "inset 0px -7px 0px -2px #00d9ff";
        targetElement.style.mozBoxShadow = "inset 0px -7px 0px -2px #00d9ff";
        targetElement.style.boxShadow = "inset 0px -7px 0px -2px #00d9ff";
        targetElement.style.transition = `box-shadow 200ms ease-in-out`;

        if (
          targetElement.getAttribute("data-component-type") === "image" &&
          targetElement.firstChild &&
          targetElement.firstChild.classList.contains("builder-image--loaded")
        ) {
          targetElement.firstChild.style.webkitBoxShadow =
            "inset 0px -7px 0px -2px #00d9ff";
          targetElement.firstChild.style.mozBoxShadow =
            "inset 0px -7px 0px -2px #00d9ff";
          targetElement.firstChild.style.boxShadow =
            "inset 0px -7px 0px -2px #00d9ff";

          targetElement.firstChild.style.transition = `box-shadow 200ms ease-in-out`;
        }

        // Set the position to the builderElement
        builderElement?.setAttribute("data-position", "bottom");
      }

      return;
    }
  }
};
