import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import Select from "react-select";
import {
  TextEditorSelectStyle,
  DefaultReactSelectStyle,
} from "../../../ReactSelectStyle/ReactSelectStyle";
import Immutable, { Set, Map } from "immutable";
import { Template } from "../../../../../store/reducers/builderReducers/templates/templateApi";
import {
  addChildToParent,
  deleteComponentById,
  removeChildFromParent,
  setStructure,
  updateStructure,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import StructureAPI from "../../../../../store/reducers/builderReducers/structure/structureApi";
import { AccordionComponent } from "../../../../_default/interfaces/base";
import { updateActiveEditor } from "../../../../../store/reducers/builderReducers/editor/editorActions";
import { Spinner } from "react-bootstrap";
import { Edit } from "@mui/icons-material";

export default function AccordionMenu({ id, triggerResize }) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as AccordionComponent;

  const components = useAppSelector(
    (state) => state.builder.structure.components
  );

  const [selectedTemplateId, setSelectedTemplateId] = useState(
    component.attributes.template_id
  );

  const editor = useAppSelector((state) => state.builder.editor);
  const [templateLoading, setTemplateLoading] = useState(false);
  const webpage_id = useAppSelector((state) => state.builder.pages.active);
  const pages = useAppSelector((state) => state.builder.pages);
  const structure = useAppSelector((state) => state.builder.structure);

  useEffect(() => {
    setSelectedTemplateId(component.attributes.template_id);
  }, [component]);

  const [isLoading, setIsLoading] = useState(false);

  const activePage = useAppSelector((state) => state.builder.pages.active);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const allTemplates = useAppSelector((state) => state.builder.templates);

  const templates: Set<Map<string, any>> = Immutable.Set(
    useAppSelector((state) => state.builder.templates).map(
      (template: Template) => Immutable.Map(template)
    )
  );

  const templateOptions = [
    ...templates.map((template) => ({
      value: template.get("component"),
      label: template.get("name"),
      id: template.get("id"),
    })),
  ].sort((a, b) => a.label.localeCompare(b.label));

  const iconPositionOptions = [
    { value: "left", label: "Left" },
    { value: "right", label: "Right" },
  ];

  const contentAlginOptions = [
    { value: "start", label: "Left" },
    { value: "center", label: "Center" },
    { value: "end", label: "Right" },
  ];

  const widthTypes = [{ value: "px", label: "px" }];

  let columnIds;

  if (component.childWebpageComponentRelations && components) {
    columnIds = component.childWebpageComponentRelations.filter((x) => {
      if (components[x.child_id]) {
        return components[x.child_id].type === "section";
      }
    });
  }

  const handleComponentChange = (e) => {
    if (e.value === null || e.id === null || e.value.id === selectedTemplateId)
      return;
    setIsLoading(true);
    updateStructureComponent(id, "attributes.template_id", selectedTemplateId);

    updateStructureComponent(id, "childWebpageComponentRelations", [
      ...component.childWebpageComponentRelations.filter(
        (x) => x.sort !== 2 && x.id !== e.value.id
      ),
    ]);

    // StructureAPI.updateWebpageComponents(activePage!, structure).then((res) => {

    //   StructureAPI.addExistingComponent(activePage!, {
    //     id: e.value.id,
    //     parent_id: id,
    //     sort_order: 2,
    //   })
    //     .then(() => {
    //       StructureAPI.updateWebpageComponents(activePage!, structure).then(
    //         () => {
    //           setIsLoading(false);
    //           StructureAPI.loadWebpageComponents(activePage!).then((x) => {
    //             setStructure(x);
    //             setIsLoading(false);
    //           });
    //         }
    //       );
    //     })
    //     .finally(() => {});
    // });

    // StructureAPI.addExistingComponent(activePage!, {
    //   id: selectedTemplateId,
    //   parent_id: id,
    //   sort_order: 2,
    // })
    //   .then(() => {
    //     StructureAPI.updateWebpageComponents(activePage!, structure).then(
    //       () => {
    //         StructureAPI.loadWebpageComponents(activePage!).then((x) => {
    //           setStructure(x);
    //           setIsLoading(false);
    //         });
    //       }
    //     );
    //   })
    //   .finally(() => {});

    StructureAPI.updateWebpageComponents(activePage!, structure).then((res) => {
      StructureAPI.addExistingComponent(activePage!, {
        id: e.value.id,
        parent_id: id,
        sort_order: 2,
      })
        .then((response) => {
          setStructure(response.payload);
        })
        .finally(() => {
          updateStructureComponent(id, "attributes.template_id", e!.id);
          setIsLoading(false);
        });
    });

    // prevComponentId.forEach((x) => {
    //   removeChildFromParent(id, x.child_id);
    // });
  };

  async function setTemplateActive(e: any, template_: Template) {
    e.stopPropagation();
    setTemplateLoading(true);
    // Set the editor.component_id to the template_.component.id

    if (
      editor.template &&
      editor.template.id !== template_.id &&
      editor.template.sort_order === -44
    ) {
      // Delete the component
      StructureAPI.deleteComponent(
        editor.template.webpage_id,
        editor.template
      ).then((x) => {
        setStructure(x.payload);
      });
    }

    console.log(template_, "setTemplateActive");

    const templateComponent = components[template_.webpage_component_id!];

    if (!templateComponent) {
      StructureAPI.updateWebpageComponents(webpage_id, structure).then(() => {
        StructureAPI.addExistingComponent(pages.active!, {
          id: template_.webpage_component_id,
          parent_id: null,
          sort_order: -44,
        }).then((x) => {
 

          const createdComponent =
            x.payload.components[template_.webpage_component_id];

    

          const updatedEditor = {
            ...editor,
            component_id: template_.component!.id,
            template: createdComponent,
          };

          setStructure(x.payload);
          setTemplateLoading(false);
          updateActiveEditor(updatedEditor);
        });
      });
    }
    if (templateComponent) {
 

      if (editor.hasEdited === true) {
        console.log("THIS RAN", editor);

        const updatedEditor = {
          ...editor,
          component_id: template_.component!.id,
          template: templateComponent,
        };

        StructureAPI.updateWebpageComponents(webpage_id, structure).then(() => {
          updateActiveEditor(updatedEditor);
          setTemplateLoading(false);
        });
      } else if (editor.hasEdited === false || !editor.hasEdited) {
        const updatedEditor = {
          ...editor,
          component_id: template_.component!.id,
          template: templateComponent,
        };
        setTemplateLoading(false);
        updateActiveEditor(updatedEditor);
      }
    }
  }

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div className="" style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          gap: "3px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="b-mx-1">Content template:</div>

        {isLoading && (
          <div className="spinner-border spinner-border-sm" role="status">
            {/* Add loading icon */}
            <span className="sr-only"></span>
          </div>
        )}

        <Select
          onChange={(e) => {
            setSelectedTemplateId(e!.id);
            updateStructureComponent(id, "attributes.template_id", e!.id);

            handleComponentChange(e);
          }}
          isSearchable={false}
          className="m-0 link-menu_input"
          styles={{
            ...TextEditorSelectStyle,
            container: (base) => ({
              ...base,
              display: "flex",

              width: "120px",
              minWidth: "60px",
            }),
            option: (styles, data) => ({
              ...DefaultReactSelectStyle.option!(styles, data),
              display: "flex",
              justifyContent: "space-between",
            }),

            control: (base) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }),
          }}
          noOptionsMessage={({}) => ""}
          value={
            templateOptions.find((x) => x.id === selectedTemplateId) ?? null
          }
          options={[...templateOptions]}
          placeholder="Select..."
        ></Select>

        {selectedTemplateId && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "uppercase",
            }}
            onClick={(e) => {
              if (selectedTemplateId) {
                const templateComponent = allTemplates.find(
                  (x) => x.id === selectedTemplateId
                );

                if (!templateComponent) return;

                setTemplateActive(e, templateComponent);
              }
            }}
          >
            {templateLoading ? (
              <Spinner style={{ fontSize: "14px" }} />
            ) : (
              <Edit style={{ fontSize: "14px" }} />
            )}
          </div>
        )}

        <div className="b-mx-1">Default expanded:</div>

        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              component.attributes?.default_expanded
                ? component.attributes.default_expanded
                : false
            }
            onChange={(e) => {
              updateStructureComponent(
                id,
                "attributes.default_expanded",
                e.target.checked
              );

              // updateStructureComponent(
              //   id,
              //   "attributes.backgroundVideo.autoplay",
              //   e.target.checked
              // );
            }}
          />
          <span className="slider round"></span>
        </label>

        <div className="b-mx-1">Align content:</div>

        <Select
          onChange={(e) => {
            updateStructureComponent(id, "attributes.align_content", e!.value);
          }}
          isSearchable={false}
          className="m-0 link-menu_input"
          styles={{
            ...TextEditorSelectStyle,
            container: (base) => ({
              ...base,
              display: "flex",

              width: "auto",
              minWidth: "30px",
            }),
            option: (styles, data) => ({
              ...DefaultReactSelectStyle.option!(styles, data),
              display: "flex",
              justifyContent: "space-between",
            }),

            control: (base) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }),
          }}
          noOptionsMessage={({}) => ""}
          value={
            contentAlginOptions.find(
              (pos) => pos.value === component.attributes?.align_content
            )
              ? contentAlginOptions.find(
                  (pos) => pos.value === component.attributes?.align_content
                )
              : contentAlginOptions[0]
          }
          options={[...contentAlginOptions]}
          placeholder=""
        ></Select>

        <div className="b-mx-1">Icon pos.:</div>

        <Select
          onChange={(e) => {
            updateStructureComponent(id, "attributes.icon_position", e!.value);

            if (e!.value === "right") {
              updateStructureComponent(
                columnIds[0].child_id,
                "attributes.styles.marginLeft",
                "0px"
              );

              updateStructureComponent(
                columnIds[0].child_id,
                "attributes.styles.marginRight",
                "20px"
              );
            } else {
              updateStructureComponent(
                columnIds[0].child_id,
                "attributes.styles.marginRight",
                "0px"
              );

              updateStructureComponent(
                columnIds[0].child_id,
                "attributes.styles.marginLeft",
                "20px"
              );
            }
          }}
          isSearchable={false}
          className="m-0 link-menu_input"
          styles={{
            ...TextEditorSelectStyle,
            container: (base) => ({
              ...base,
              display: "flex",

              width: "auto",
              minWidth: "30px",
            }),
            option: (styles, data) => ({
              ...DefaultReactSelectStyle.option!(styles, data),
              display: "flex",
              justifyContent: "space-between",
            }),

            control: (base) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }),
          }}
          noOptionsMessage={({}) => ""}
          value={
            iconPositionOptions.find(
              (pos) => pos.value === component.attributes?.icon_position
            )
              ? iconPositionOptions.find(
                  (pos) => pos.value === component.attributes?.icon_position
                )
              : iconPositionOptions[1]
          }
          options={[...iconPositionOptions]}
          placeholder=""
        ></Select>

        <div className="b-mx-1">Icon size:</div>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            type="number"
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={
              component.attributes?.icon_size
                ? component.attributes.icon_size
                : 18
            }
            onChange={(ev) => {
              if (ev.target.value === "0") return;

              updateStructureComponent(
                id,
                "attributes.icon_size",
                ev.target.value
              );
            }}
          />

          <Select
            menuPosition="fixed"
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={widthTypes.find((x) => x.value === "px")}
            onChange={(val) => {
              // setSelectedWidth(val.value);
              // updateComponentStyle(id, {
              //   width: component.attributes.styles.width
              //     ? component.attributes.styles.width.replace(
              //         `${selectedWidth}`,
              //         val.value
              //       )
              //     : "",
              // });
            }}
            options={widthTypes}
            placeholder=""
          ></Select>
        </div>

        <div className="b-mx-1">Icon color:</div>

        <div>
          <FormatColorTextIcon
            style={{
              cursor: "pointer",
              fontSize: "18px",
              color: component.attributes.icon_color
                ? component.attributes.icon_color
                : "#fff",
            }}
            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
          />

          {isColorPickerOpen && (
            <ColorPicker
              title="Expand icon color"
              onChange={(val) => {
                updateStructureComponent(id, "attributes.icon_color", val);

                // sfdcksjdfklj
              }}
              value={
                component.attributes.icon_color
                  ? component.attributes.icon_color
                  : "#000"
              }
              setColorPickerOpen={setIsColorPickerOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
}
