import { LanguageSetting } from './languageSettingsApi'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: LanguageSetting | any = {}

export const languageSettingsSlice = createSlice({
  name: 'languageSettings',
  initialState,
  reducers: {
    UPDATE_LANGUAGE_SETTINGS: (state: any, action: PayloadAction<LanguageSetting>) => {
        state = action.payload
        return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { UPDATE_LANGUAGE_SETTINGS } = languageSettingsSlice.actions

export default languageSettingsSlice.reducer