import React, { useEffect, useRef, useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import Select from "react-select";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import { MoreHorizOutlined } from "@mui/icons-material";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import Dropdown from "../../../Dropdown/Dropdown";
import { useAppSelector } from "../../../../../store/hooks";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { CartComponent } from "../../../../_default/interfaces/base";
import "./CartFontMenu.scss";

interface Props {
  id: number;
  onMouseUp?: MouseEvent;
  keyDownEvent: KeyboardEvent;
  triggerResize: () => void;
  setRange: (val: Range | undefined) => void;
}

export default function CartFontMenu({
  id,
  onMouseUp,
  keyDownEvent,
  triggerResize,
}: Props) {
  const [showMoreMenus, setShowMoreMenus] = useState(false);
  React.useEffect(() => {
    TypographiesAPI.loadTypographies();
  }, []);
  console.log("CartFontMenu");
  const typographies = useAppSelector((state) => state.builder.typographies);
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as CartComponent;

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isHoverColorPickerOpen, setIsHoverColorPickerOpen] = useState(false);

  const [toolbar, setToolbar] = useState({
    fontSize: 0,
    bold: false,
    italic: false,
    underline: false,
    align: "left",
    typo: component.attributes.typography_id ?? null,
    blockquote: false,
    lineHeight: component.attributes.styles.lineHeight ?? 1,
    lineHeightUnit: "px",
  });

  const fontSizeUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
  ];

  useEffect(() => {
    if (
      keyDownEvent?.keyCode === 8 &&
      window.getSelection()?.anchorOffset === 0
    ) {
      document.execCommand("outdent", false, "true");
    }
  }, [keyDownEvent]);

  function getCurrentFontSize() {
    if (!window.getSelection()?.anchorNode) return 12;

    let fontSize = window
      .getComputedStyle(window.getSelection()?.anchorNode!.parentElement!, null)
      .getPropertyValue("font-size");
    if (
      window.getSelection()?.anchorNode?.parentElement?.style.fontSize &&
      window.getSelection()?.anchorNode!.parentElement!.style.fontSize !=
        "var(--bs-body-font-size)"
    ) {
      fontSize =
        window.getSelection()!.anchorNode!.parentElement!.style.fontSize;
    }

    return Math.round(parseInt(fontSize.replace("px", "")) ?? 12);
  }

  function getCurrentFontWeight() {
    if (!window.getSelection()?.anchorNode) return "400";

    return (
      window
        .getComputedStyle(
          window.getSelection()?.anchorNode?.parentElement!,
          null
        )
        .getPropertyValue("font-weight") ?? "400"
    );
  }

  function getCurrentFontStyle() {
    if (!window.getSelection()?.anchorNode) return "normal";

    return (
      window
        .getComputedStyle(
          window.getSelection()?.anchorNode?.parentElement!,
          null
        )
        .getPropertyValue("font-style") ?? "normal"
    );
  }

  function getCurrentTextDecoration() {
    if (!window.getSelection()?.anchorNode) return false;

    return (
      window
        .getComputedStyle(
          window.getSelection()?.anchorNode?.parentElement!,
          null
        )
        .getPropertyValue("text-decoration")
        .includes("underline") ?? false
    );
  }

  function getCurrentTag(): string {
    if (!window.getSelection()?.anchorNode) return "text";

    let tag = window
      .getSelection()!
      .anchorNode!.parentElement?.nodeName.toLowerCase();

    if (
      typographies?.find((x: any) =>
        [
          window
            .getSelection()
            ?.anchorNode?.parentElement?.nodeName.toLowerCase(),
          window
            .getSelection()!
            .anchorNode!.parentElement?.parentElement!.nodeName.toLowerCase(),
        ].includes(x.name.toLowerCase())
      )
    ) {
      return tag!;
    }
    return "text";
  }

  function getCurrentAlignment() {
    if (!window.getSelection()?.anchorNode) return "left";

    let curr = window
      .getComputedStyle(window.getSelection()?.anchorNode!.parentElement!, null)
      .getPropertyValue("text-align");
    return curr === "start" ? "left" : curr;
  }

  function increaseFontSize() {
    let fontSize = getCurrentFontSize() + 1 + "px";
    document.execCommand("fontSize", false, "7");
    replaceFontSize(fontSize);
    updateToolbar();
  }

  function decreaseFontSize() {
    let fontSize = getCurrentFontSize() - 1 + "px";
    document.execCommand("fontSize", false, "7");
    replaceFontSize(fontSize);
    updateToolbar();
  }

  function setFontSize(val: string) {
    document.execCommand("fontSize", false, "7");
    replaceFontSize(val);
    updateToolbar();
  }

  function handleBold() {
    document.execCommand("bold");
    updateToolbar();
  }

  function replaceFontSize(fontSize: string) {
    let fontTags = document
      .getElementById(id.toString())!
      .getElementsByTagName("font") as any;
    for (let i = 0; i < fontTags.length; i++) {
      if (fontTags[i].size == "7") {
        fontTags[i].removeAttribute("size");
        fontTags[i].style.fontSize = fontSize;
      }
    }
  }

  useEffect(() => {
    updateToolbar();
  }, [onMouseUp]);

  function updateToolbar() {
    setToolbar({
      ...toolbar,
      fontSize: getCurrentFontSize(),
      bold: getCurrentFontWeight() == "700" ? true : false,
      italic: getCurrentFontStyle() == "italic" ? true : false,
      underline: getCurrentTextDecoration(),
      align: getCurrentAlignment(),
      typo: component.attributes.typography_id,
      blockquote: getBlockQuote(),
      lineHeight: Number(
        (component.attributes.styles.lineHeight ?? 1)
          .toString()
          .match(/\-?\d*\.?\d+/)![0]
      ),
      lineHeightUnit: (component.attributes.styles?.lineHeight ?? 1)
        .toString()
        .replace(
          (component.attributes.styles.lineHeight ?? 1)
            .toString()
            .match(/\-?\d*\.?\d+/)![0],
          ""
        ),
    });
  }

  function onTypoChange(val: any) {
    updateStructureComponent(id, "attributes.typography_id", val.id);
  }

  function getBlockQuote() {
    if (!window.getSelection()?.anchorNode) return false;

    return elDescendantOf(
      window.getSelection()!.anchorNode!.parentElement!,
      "blockquote"
    );
  }

  function elDescendantOf(elNode: Element, parentTag: string): boolean {
    if (elNode.localName === parentTag) {
      return true;
    } else if (
      elNode.parentElement &&
      !elNode.classList.contains("builder-component")
    ) {
      return elDescendantOf(elNode.parentElement, parentTag);
    }
    return false;
  }

  function unformat() {
    document.execCommand("removeFormat", false, "true");
    // remove bulleted list and indent
    while (
      elDescendantOf(
        window.getSelection()?.anchorNode?.parentNode! as Element,
        "li"
      ) ||
      elDescendantOf(
        window.getSelection()?.anchorNode!.parentNode! as Element,
        "blockquote"
      )
    ) {
      document.execCommand("outdent", false, "true");
    }
    document.execCommand("unlink", false, "true");
  }

  return (
    <>
      <div className="d-flex">
        <div className="d-flex flex-wrap text-editor-options col-10">
          <Select
            menuPortalTarget={document.getElementById("react-select-container")}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(val) => onTypoChange(val)}
            className="m-0 cart-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={typographies
              ?.map((x: any) => {
                return { ...x, label: x.name };
              })
              .find((x: any) => {
                return x.id == toolbar.typo;
              })}
            options={typographies?.map((x: any) => {
              return { ...x, label: x.name };
            })}
            placeholder=""
          ></Select>
          <div className="d-inline cart-menu_input">
            <span onClick={() => decreaseFontSize()}>- </span>
            <input
              className="global-editor__input builder-input"
              size={1}
              value={toolbar.fontSize}
              onChange={(ev) => {
                setFontSize(ev.target.value);
              }}
            />
            <span onClick={() => increaseFontSize()}> +</span>
          </div>
          <Select
            isSearchable={false}
            className="m-0 cart-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={fontSizeUnits.find((x) => x.value === "px")}
            options={fontSizeUnits}
            placeholder=""
          ></Select>
          <span className="d-flex position-relative dropup">
            Color:
            <FormatColorTextIcon
              onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
            />
            {isColorPickerOpen && (
              <ColorPicker
                returnType="hex"
                title="Text"
                top={18}
                onChange={(val) => {
                  console.log("value change", val);

                  updateStructureComponent(id, "attributes.color", val);
                }}
                value={
                  component.attributes.color
                    ? component.attributes.color
                    : "#000000"
                }
                setColorPickerOpen={setIsColorPickerOpen}
              />
            )}
          </span>

          <span className="d-flex position-relative dropup">
            Hover color:
            <FormatColorTextIcon
              onClick={() => setIsHoverColorPickerOpen(!isHoverColorPickerOpen)}
            />
            {isHoverColorPickerOpen && (
              <ColorPicker
                returnType="hex"
                align="left"
                title="Text"
                top={18}
                onChange={(val) => {
                  updateStructureComponent(id, "attributes.hoverColor", val);
                }}
                value={
                  component.attributes.hoverColor
                    ? component.attributes.hoverColor
                    : "#000000"
                }
                setColorPickerOpen={setIsColorPickerOpen}
              />
            )}
          </span>

          {showMoreMenus && (
            <>
              <UndoIcon onClick={() => document.execCommand("undo")} />
              <RedoIcon onClick={() => document.execCommand("redo")} />
              <FormatBoldIcon
                className={toolbar.bold ? "active" : ""}
                onClick={() => handleBold()}
              />
              <FormatClearIcon onClick={() => unformat()} />
            </>
          )}
        </div>
        <div className="col-2 text-end flex-grow-1">
          <MoreHorizOutlined
            style={{ fontSize: "18px" }}
            onClick={() => {
              setShowMoreMenus(!showMoreMenus);
              triggerResize();
            }}
          />
        </div>
      </div>
    </>
  );
}
