import { inClass } from "../../../../../utils/Helpers";

export const handleMouseDown = (e, ref, isText) => {
  const builderElement = inClass("builder-component--active", ref.current!);
  builderElement?.setAttribute("draggable", "true");

  if (e.target.nodeName === "#text") {
    isText = true;
    return;
  }
};
