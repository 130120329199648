import React from "react";
import { useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";

import DocumentsAPI from "../../../../../../store/reducers/builderReducers/documents/documentsApi";
import DragAndDropUpload from "../../../../DragAndDropUpload/DragAndDropUpload";
import { useAppSelector } from "../../../../../../store/hooks";
import { Document } from "../../../../../../store/reducers/builderReducers/documents/documentsApi";

import { YouTube } from "@mui/icons-material";

export default function FileExplorer({
  setFileExplorerOpen,
  onSelect,
  id,
  type,
  page,
}: {
  setFileExplorerOpen: any;
  onSelect: (item: Document) => void;
  id: number;
  type?: string;
  page?: string;
}) {
  console.log("FileExplorer");
  const [link, setLink] = useState("");
  const [view, setView] = useState("upload");
  React.useEffect(() => {
    DocumentsAPI.loadDocumentsTree();
  }, []);
  const docStruc = useAppSelector((state) => state.builder.documents);
  const [preview, setPreview] = useState<null | Document>(null);
  const [activeNavId, setActiveNavId] = useState<number | null>(null);
  const [filename, setFilename] = useState<string>("");

  let component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;

  const styles = component.attributes.styles;
  const website = useAppSelector((state) => state.builder.website);

  function handleItemClick(item: Document) {
    const link = item.path;

    let updatedFile: any = { ...item };

    if (link.includes("vimeo")) {
      let url;

      var regex = /(?:vimeo\.com\/)(\d+)/;
      var match = item.path.match(regex);
      if (match && match.length > 1) {
        url = match[1];
      } else {
        url = null;
      }

      updatedFile = {
        ...item,
        url: url ? `https://player.vimeo.com/video/${url}` : null,
        extension: "vimeo",
      };
    } else if (link.includes("youtube")) {
      let url;

      var regex =
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
      var match = item.path.match(regex);
      if (match && match.length > 1) {
        url = match[1];
      } else {
        url = null;
      }

      updatedFile = {
        ...item,
        url: url ? `https://www.youtube.com/embed/${url}` : null,
        extension: "youtube",
      };
    }

    // setPreview(updatedFile);
    // setView("preview");

    if (item.type === "directory") {
      setActiveNavId(item.id);
    } else if (
      styles.backgroundImage?.includes(item.url) ||
      component.attributes.backgroundVideo?.url === updatedFile.url
    ) {
      return;
    } else {
      if (styles.backgroundImage?.split(",")?.length === 15) {
        return;
      }
      setPreview(updatedFile);
      setView("preview");
    }
  }

  function handleBreadcrumbClick(id: number) {
    setActiveNavId(id);
  }

  function findFolderRecursive(
    struc: Document[],
    id: number | null
  ): Document[] | null {
    if (!id) return struc; // When no folder is selected

    let folders = struc.filter((x) => x.type === "directory");
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === id) {
        return folders[i].childElements;
      } else if (folders[i].type === "directory") {
        let item = findFolderRecursive(folders[i].childElements, id);
        if (item) {
          return item;
        }
      }
    }

    return null;
  }

  function getFolderNameRecursive(
    struc: Document[],
    id: number | null,
    breadcrumb_: Array<{ name: string; id?: number }> = [
      { name: website!.name },
    ]
  ): Array<{ name: string; id?: number }> | null {
    if (!id) return breadcrumb_; // When no folder is selected

    let folders = struc.filter((x) => x.type === "directory");
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === id)
        return [
          ...breadcrumb_,
          { id: folders[i].id, name: folders[i].originalname },
        ];
      else if (folders[i].childElements.length) {
        let fold = getFolderNameRecursive(folders[i].childElements, id, [
          ...breadcrumb_,
          { id: folders[i].id, name: folders[i].originalname },
        ]);
        if (fold) return fold;
      }
    }
    return null;
  }

  function uploadFileFromUrl() {
    DocumentsAPI.addReferenceFile({ path: link, originalname: filename }).then(
      (data) => {
        if (data.addedCustomFiles.length) {
          const file = data.addedCustomFiles[0];

          let updatedFile = file;

          if (link.includes("vimeo")) {
            let url;

            var regex = /(?:vimeo\.com\/)(\d+)/;
            var match = file.path.match(regex);
            if (match && match.length > 1) {
              url = match[1];
            } else {
              url = null;
            }

            updatedFile = {
              ...file,
              url: url ? `https://player.vimeo.com/video/${url}` : null,
              extension: "vimeo",
            };
          } else if (link.includes("youtube")) {
            let url;

            var regex =
              /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
            var match = file.path.match(regex);
            if (match && match.length > 1) {
              url = match[1];
            } else {
              url = null;
            }

            updatedFile = {
              ...file,
              url: url ? `https://www.youtube.com/embed/${url}` : null,
              extension: "youtube",
            };
          }

          setPreview(updatedFile);
          setView("preview");
        }
      }
    );
  }

  function uploadFile(e: any) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      DocumentsAPI.uploadDocuments(e.target.files).then((data) => {
        if (data.addedCustomFiles.length) {
          setPreview(data.addedCustomFiles[0]);
          setView("preview");
        }
      });
    } else if (e.dataTransfer.files) {
      DocumentsAPI.uploadDocuments(e.dataTransfer.files).then((data) => {
        if (data.addedCustomFiles.length) {
          setPreview(data.addedCustomFiles[0]);
          setView("preview");
        }
      });
    }
  }

  return (
    <div className="file-explorer file-explorer__container">
      <div className="b-row">
        {view !== "preview" && (
          <div className="col-12 b-mb-3 file-locations">
            {type === "video" && (
              <>
                <div
                  className={
                    "file-locations file-locations__item " +
                    (view === "vimeo" ? "file-locations__item--active" : "")
                  }
                  onClick={() => setView("vimeo")}
                >
                  Vimeo
                </div>

                <div
                  className={
                    "file-locations file-locations__item " +
                    (view === "youtube" ? "file-locations__item--active" : "")
                  }
                  onClick={() => setView("youtube")}
                >
                  Youtube
                </div>
              </>
            )}

            <div
              className={
                "file-locations file-locations__item " +
                (view === "upload" ? "file-locations__item--active" : "")
              }
              onClick={() => setView("upload")}
            >
              + New file
            </div>
            <div
              className={
                "file-locations file-locations__item " +
                (view === "directory" ? "file-locations__item--active" : "")
              }
              onClick={() => setView("directory")}
            >
              Select from folder
            </div>
          </div>
        )}

        {/* {view === "vimeo" && (
          <>
            <div className="col-12 b-mb-3">
              <div className="fs-small">Link to Vimeo file</div>
              <input
                className="form-input builder-input"
                value={link}
                onChange={(ev) => setLink(ev.target.value)}
              />
            </div>
            {link != "" && (
              <div className="col-12 b-mb-3">
                <div className="fs-small">Name</div>
                <input
                  className="form-input builder-input"
                  value={filename}
                  onChange={(e) => setFilename(e.target.value)}
                />
              </div>
            )}
            <div className="col-12">
              {link != "" && (
                <button
                  className="b-btn btn-primary w-100"
                  onClick={() => uploadFileFromUrl()}
                >
                  Save
                </button>
              )}
              {link == "" && (
                <button
                  className="b-btn btn-primary w-100"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid white",
                  }}
                  onClick={() => setFileExplorerOpen(false)}
                >
                  Back
                </button>
              )}
            </div>
          </>
        )} */}

        {/* {view === "youtube" && (
          <>
            <button
              className="b-btn btn-primary w-100"
              onClick={() => {
                setFileExplorerOpen(false);
              }}
            >
              Back
            </button>
          </>
        )} */}

        {view === "upload" && (
          <>
            <div className="col-12 b-mb-3">
              <DragAndDropUpload icon="cloud" onChange={uploadFile} />
            </div>
            <div className="col-12 b-mb-3">
              <div className="fs-small">Link to file</div>
              <input
                className="form-input builder-input"
                value={link}
                onChange={(ev) => setLink(ev.target.value)}
              />
            </div>
            {link != "" && (
              <div className="col-12 b-mb-3">
                <div className="fs-small">Name</div>
                <input
                  className="form-input builder-input"
                  value={filename}
                  onChange={(e) => setFilename(e.target.value)}
                />
              </div>
            )}
            <div className="col-12">
              {link != "" && (
                <button
                  className="b-btn btn-primary w-100"
                  onClick={() => uploadFileFromUrl()}
                >
                  Save
                </button>
              )}
              {link == "" && (
                <button
                  className="b-btn btn-primary w-100"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    background: "#444",
                  }}
                  onClick={() => setFileExplorerOpen(false)}
                >
                  Back
                </button>
              )}
            </div>
          </>
        )}

        {view === "directory" && (
          <>
            <div className="col-12 b-mb-2 b-fs-5">
              {getFolderNameRecursive(docStruc.toArray(), activeNavId)?.map(
                (x, i) => (
                  <>
                    {i != 0 && " > "}
                    <span
                      className="file-explorer file-explorer__breadcrumb"
                      onClick={() => handleBreadcrumbClick(x.id!)}
                    >
                      {x.name}
                    </span>
                  </>
                )
              )}
            </div>
            <div className="file-explorer file-explorer__items">
              {findFolderRecursive(docStruc.toArray(), activeNavId)
                ?.sort((a, b) => {
                  return a.originalname.toLocaleLowerCase() >
                    b.originalname.toLocaleLowerCase()
                    ? 1
                    : -1;
                })

                .map((x) => {
                  return (
                    <div
                      key={x.id}
                      className="col-12 b-py-2 d-flex file-explorer__items__item"
                      onClick={() => handleItemClick(x)}
                    >
                      <div className="file-explorer__items__item__icon">
                        {x.type === "directory" && <FolderIcon />}
                        {["files", "reference"].includes(x.type) && (
                          <div
                            style={{
                              width: "40px",
                              height: "40px",
                              overflow: "hidden",
                              backgroundColor: "white",
                              borderRadius: "5px",
                            }}
                          >
                            {x.path && x.path.includes("youtube") && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "40px",
                                  width: "40px",
                                  zIndex: 99999,
                                  color: "red",
                                }}
                              >
                                <YouTube style={{ fontSize: "32px" }} />
                              </div>
                            )}

                            {x.url && x.path.includes(".gif") && (
                              <img
                                src={x.path}
                                width={40}
                                height={40}
                                alt={x.originalname}
                                style={{ objectFit: "cover" }}
                              />
                            )}

                            {x.url && x.path.includes(".svg") && (
                              <img
                                src={x.path}
                                width={40}
                                height={40}
                                alt={x.originalname}
                                style={{ objectFit: "cover" }}
                              />
                            )}

                            {x.path && x.path.includes("vimeo") && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "40px",
                                  width: "40px",
                                  zIndex: 99999,
                                  color: "black",
                                  fontSize: "10px",
                                  fontWeight: "600",
                                }}
                              >
                                Vimeo
                              </div>
                            )}

                            {x.url && x.path.includes(".svg") ? (
                              <img
                                src={x.path}
                                width={40}
                                height={40}
                                alt={x.originalname}
                                style={{ objectFit: "cover" }}
                              />
                            ) : (
                              <img
                                src={x.url}
                                width={40}
                                height={40}
                                alt={x.originalname}
                                style={{ objectFit: "cover", color: "white" }}
                              />
                            )}
                          </div>
                        )}
                        {/* TODO: add image preview */}
                      </div>
                      <div className="file-explorer__items__item__name">
                        {x.originalname}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="col-12 b-pt-3">
              <button
                className="b-btn btn-primary w-100"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  background: "#444",
                }}
                onClick={() => {
                  setFileExplorerOpen(false);
                }}
              >
                Back
              </button>
            </div>
          </>
        )}

        {view === "preview" && preview && (
          <>
            <div className="col-12 b-mb-2">
              {preview.extension !== "mp4" &&
                preview.extension !== "ref" &&
                preview.extension !== "vimeo" &&
                preview.extension !== "youtube" && (
                  <img
                    src={
                      preview.type == "reference" ? preview.path : preview.url
                    }
                    className="file-explorer file-explorer__picture-preview"
                  />
                )}
              {preview.extension === "vimeo" && (
                <>
                  <iframe
                    src={preview.url}
                    width="100%"
                    height="auto"
                    frameBorder={0}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                  {/* <video
                    autoPlay
                    loop
                    controls
                    className="file-explorer file-explorer__picture-preview"
                  >
                    <source src={preview.url} type="video/mp4" />
                  </video> */}
                </>
              )}

              {preview.extension === "mp4" && (
                <>
                  <video
                    autoPlay
                    loop
                    controls
                    className="file-explorer file-explorer__picture-preview"
                  >
                    <source src={preview.url} type="video/mp4" />
                  </video>
                </>
              )}

              {preview.extension === "youtube" && (
                <>
                  <iframe
                    src={preview.url}
                    width="100%"
                    height="auto"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </>
              )}

              <div className="text-center b-mt-1">{preview.originalname}</div>
            </div>
            <div className="col-6 b-pe-1">
              <button
                className="b-btn btn-primary w-100"
                style={{
                  backgroundColor: "transparent",
                  background: "#444",
                  border: "none",
                }}
                onClick={() => {
                  setView("directory");
                  setPreview(null);
                }}
              >
                Back
              </button>
            </div>
            <div className="col-6 b-ps-1">
              <button
                className="b-btn btn-primary w-100"
                onClick={() => {
                  onSelect(preview);
                  setFileExplorerOpen(false);
                }}
              >
                Select
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
