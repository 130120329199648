import { fetchApiGet, fetchApiPost, fetchApiPut } from '../../../../api/api'

export interface Color {
  id: number
  name: string
  color: string
  website_id: number
  sort_order: number
  created_at: string
  updated_at: string
  deleted_at: string
}

import {
  updateColors,
  addNewColor,
} from './colorActions'
const ColorAPI = {
    loadColors,
    addColor,
    saveColors,
}
export default ColorAPI

export function loadColors() {
    return fetchApiGet<Array<Color>>(`/colors`, {}).then((res) => 
      updateColors(res)
    )
}
  
export function addColor(data: { color: string; name: string }) {
    return fetchApiPost<Color>(`/colors`, data).then((d) => 
      addNewColor(d)
    )
    
}
  
// Color object might or might not have ID
export function saveColors(data: Omit<Array<Color>, 'id'>) {
    return fetchApiPut<Array<Color>>(`/colors/`, data).then((d) => updateColors(d))
}