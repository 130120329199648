import React from 'react'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
// import userReducer from './reducers/userReducer'
import builderReducer from './reducers/builderReducers/builderReducer'
import textEditorReducer from './reducers/textEditor/textEditorReducer'

const store = configureStore({
  reducer: {
    // @ts-ignore
    // user: userReducer, // Still in development
    builder: builderReducer,
    // @ts-ignore
    textEditor: textEditorReducer, // Shouldnt this be in builderReducer?

  },
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disabled for ImmutableJS
    }),
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
