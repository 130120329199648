import { Editor } from "./editorApi";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: Editor = {
  component_id: null,
  component_parent_id: null,
  hasEdited: false,
  editor: null,
  template: null,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    UPDATE_EDITOR: (state: any, action: PayloadAction<Editor>) => {
      state = action.payload;

      if (!state) {
        return state;
      }

      state.component_id = action.payload.component_id!;
      state.component_parent_id = action.payload.component_parent_id!;

      if (!state.editor) {
        state.editor = null;
      }

      // Only update component_id and component_parent_id, but leave editor as it is

      //   state.component_id = action.payload.id!;
      //   state.component_parent_id = action.payload.parent_id!;

      return state;
    },

    UPDATE_EDITOR_STATE: (
      state: any,
      action: PayloadAction<{
        hasEdited: boolean;
      }>
    ) => {
      state.hasEdited = action.payload.hasEdited;
      return state;
    },

    UPDATE_ACTIVE_EDITOR: (state: any, action: PayloadAction<Editor>) => {
      state = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { UPDATE_EDITOR, UPDATE_ACTIVE_EDITOR, UPDATE_EDITOR_STATE } =
  editorSlice.actions;

export default editorSlice.reducer;
