import Select from "react-select";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import { HeaderComponent } from "../../../../_default/interfaces/base";
import { useEffect } from "react";
import React from "react";

export default function CartMenu({
  id,
  triggerResize,
}: {
  id: number;
  triggerResize: () => void;
}) {
  console.log("CartMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as HeaderComponent;
  const menuType = [
    { value: "cart", label: "Cart" },
    { value: "none", label: "None" },
  ];

  const alignments = [
    { value: "left", label: "Left" },
    { value: "center", label: "Center" },
    { value: "right", label: "Right" },
  ];

  function onAlignmentChange(val: any) {
    updateStructureComponent(
      id,
      "attributes.components.cart.alignment",
      val.value
    );
  }

  function onDisplayChange(val: any) {
    updateStructureComponent(
      id,
      "attributes.components.cart.display",
      val.value === "none" ? false : true
    );
  }

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div className="d-flex" style={{ gap: "10px" }}>
      <Select
        onChange={onDisplayChange}
        isSearchable={false}
        className="m-0"
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={
          component.attributes.components.cart.display
            ? menuType[0]
            : menuType[1]
        }
        options={menuType}
        placeholder=""
      ></Select>
      <Select
        onChange={onAlignmentChange}
        isSearchable={false}
        className="m-0"
        styles={{
          ...TextEditorSelectStyle,
          container: (base) => ({
            ...base,
            width: "50px",
          }),
        }}
        noOptionsMessage={({}) => ""}
        value={alignments.find(
          (x) => x.value === component.attributes.components.cart.alignment
        )}
        options={alignments}
        placeholder=""
      ></Select>
    </div>
  );
}
