import { fetchApiGet, fetchApiPost, fetchApiPut } from "../../../../api/api";
import { updateLanguageSettings } from "./languageSettingsActions";

export interface Language {
  id: number;
  locale: string;
  manager_state: number;
  visitor_state: number;
  name: string;
  icon: string;
  order: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  website_id: number;
  language: {
    id: number;
    name: string;
    shortname: string;
    icon: string;
    icon_url: string;
  };
}

export interface LanguageSetting {
  useBrowserLanguage: boolean;
  activeLanguages: Array<Language>;
}

const LanguageSettingsAPI = {
  saveLanguageSettings,
  loadLanguageSettings,
};
export default LanguageSettingsAPI;

export function loadLanguageSettings() {
  return fetchApiGet<LanguageSetting>(`/languageSettings`, {}).then((res) => {
    updateLanguageSettings(res);
  });
}

export function saveLanguageSettings(data: LanguageSetting) {
  console.log(data, "THIS IS THE DATA");

  return fetchApiPut(`/languageSettings/`, data)
    .then((data2) => {
      updateLanguageSettings(data);
    })
    .catch((e) => console.log(e, "this is error"));
}
