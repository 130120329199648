import { combineReducers } from '@reduxjs/toolkit'
import pagesReducer from './pages/pagesReducer'
import colorReducer from './color/colorReducer'
import templateReducer from './templates/templateReducer'
import editorReducer from './editor/editorReducer'
import typographiesReducer from './typographies/typographiesReducer'
import reusableComponentsReducer from './reusableComponents/reusableComponentsReducer'
import languageSettingsReducer from './languageSettings/languageSettingsReducer'
import websiteReducer from './website/websiteReducer'
import structureReducer from './structure/structureReducer'
import documentsReducer from './documents/documentsReducer'
import api_clientsReducer from './api_clients/api_clientsReducer'
import font_optionsReducer from './font_options/font_optionsReducer'
import font_urlReducer from './font_url/font_urlReducer'

const builderReducer = combineReducers({
  pages: pagesReducer,
  colors: colorReducer,
  templates: templateReducer,
  editor: editorReducer,
  typographies: typographiesReducer,
  reusableComponents: reusableComponentsReducer,
  languageSettings: languageSettingsReducer,
  website: websiteReducer,
  structure: structureReducer,
  documents: documentsReducer,
  api_clients: api_clientsReducer,
  font_options: font_optionsReducer,
  font_url: font_urlReducer,
});

export default builderReducer

