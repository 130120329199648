import store from "../../../store"
import { Template } from './templateApi'
import {
    UPDATE_TEMPLATES,
    ADD_TEMPLATES,
    DELETE_TEMPLATE_BY_ID,
} from './templateReducer'

export function updateTemplates(payload: Template[]) {
    return store.dispatch(
        UPDATE_TEMPLATES(payload)
    )
}

export function addNewTemplate(payload: Template) {
    return store.dispatch(
        ADD_TEMPLATES(payload)
    )
}

export function deleteTemplateById(id: number) {
    return store.dispatch(
        DELETE_TEMPLATE_BY_ID({
            id
        })
    )
}