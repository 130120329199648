import StructureAPI from "../../../../../store/reducers/builderReducers/structure/structureApi";
import { setStructure } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { Template } from "../../../../../store/reducers/builderReducers/templates/templateApi";
import { useAppSelector } from "../../../../../store/hooks";
import { AllComponents } from "../../../../_default/interfaces/base";
import { getComponentType } from "../../../../../utils/Helpers";
import { useState } from "react";
import React from "react";

interface Props {
  component: AllComponents | undefined;
  sort_order: number;
}

export default function SelectTemplate({ component, sort_order }: Props) {
  console.log("SelectTemplate");
  const templates = useAppSelector((state) => state.builder.templates);
  const pages = useAppSelector((state) => state.builder.pages);
  const componentType = component ? getComponentType(component) : null;
  const [isLoading, setIsLoading] = useState(0);
  const webpage_id = useAppSelector((state) => state.builder.pages.active);
  const structure = useAppSelector((state) => state.builder.structure);

  let allowedComponentTypes: Array<String> = [];
  switch (componentType) {
    case "row":
      allowedComponentTypes = ["col"];
      break;
    case "col":
      allowedComponentTypes = [
        "text",
        "ProductsComponent",
        "ProductDetailComponent",
        "Checkout",
        "Button",
        "form",
        "inner_section",
        "image",
      ];
      break;
    case null:
      allowedComponentTypes = ["row"];
      break;

    default:
      allowedComponentTypes = [];
      break;
  }

  function onTemplateClick(template: Template) {
    if (pages.active) {
      setIsLoading(template.id);

      StructureAPI.updateWebpageComponents(webpage_id, structure)
        .then(() => {
          StructureAPI.addExistingComponent(pages.active!, {
            id: template.webpage_component_id,
            parent_id: component?.id ?? null,
            sort_order: sort_order + 1,
          }).then((x) => {
            const createdComponent =
              x.payload.components[template.webpage_component_id];

            StructureAPI.duplicateComponent(webpage_id!, createdComponent)
              .then(() => {})
              .then(() => {
                StructureAPI.deleteComponent(
                  webpage_id!,
                  createdComponent
                ).then(() => {
                  StructureAPI.loadWebpageComponents(webpage_id!).then((x) => {
                    setStructure(x);
                    setIsLoading(0);
                  });
                });
              });
          });
        })

        .finally(() => {
          setIsLoading(0);
        });
    }
  }

  return (
    <ul className="m-0 p-0">
      <div className="select-element-type__container">
        <div className="select-element-type__list">
          {templates
            .filter((template) => {
              return (
                template.component &&
                allowedComponentTypes.includes(
                  getComponentType(template.component)
                )
              );
            })
            .sort((a, b) => {
              return a.name
                .toLocaleLowerCase()
                .localeCompare(b.name.toLocaleLowerCase());
            })

            .map((template) => (
              <div
                key={template.id}
                style={{
                  cursor: isLoading !== 0 ? "not-allowed" : "pointer",
                }}
                className="select-element-type__list__item "
                onClick={() => {
                  if (isLoading) {
                    return;
                  }
                  onTemplateClick(template);
                }}
              >
                {template.name}

                {isLoading === template.id && (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </ul>
  );
}
