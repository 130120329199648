import { fetchApiGet, fetchApiPost, fetchApiPut } from "../../../../api/api";
import {
  CookiesComponent,
  FooterComponent,
  HeaderComponent,
} from "../../../../components/_default/interfaces/base";
import { updateReusabeComponents } from "./reusableComponentsActions";

export interface ReusableComponent {
  footers: FooterComponent[];
  headers: HeaderComponent[];
  cookies: CookiesComponent[];
}

const ReusableComponentsAPI = {
  loadReusableComponents,
};
export default ReusableComponentsAPI;

export function loadReusableComponents() {
  return fetchApiGet<ReusableComponent>(`/loadReusableComponents`, {}).then(
    (res) => updateReusabeComponents(res)
  );
}
