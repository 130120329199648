import Select from "react-select";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import { HeaderComponent } from "../../../../_default/interfaces/base";
import { useEffect } from "react";
import React from "react";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function LogoMenu({ id, triggerResize }: Props) {
  console.log("LogoMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as HeaderComponent;

  const logoTypes = [
    { value: "logo", label: "Logo" },
    /*{ value: "text", label: "Text" },*/
    { value: "none", label: "None" },
  ];

  const alignments = [
    { value: "left", label: "Left" },
    { value: "center", label: "Center" },
    { value: "right", label: "Right" },
  ];

  function onAlignmentChange(val: any) {
    updateStructureComponent(
      id,
      "attributes.components.logo.alignment",
      val.value
    );
  }

  function onDisplayChange(val: any) {
    updateStructureComponent(id, "attributes.components.logo.type", val.value);
  }
  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div className="d-flex" style={{ gap: "10px" }}>
      <Select
        onChange={onDisplayChange}
        isSearchable={false}
        className="m-0"
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={logoTypes.find(
          (x) => x.value === component.attributes.components.logo.type
        )}
        options={logoTypes}
        placeholder=""
      ></Select>
      <Select
        onChange={onAlignmentChange}
        isSearchable={false}
        className="m-0"
        styles={{
          ...TextEditorSelectStyle,
          container: (base) => ({
            ...base,
            width: "50px",
          }),
        }}
        noOptionsMessage={({}) => ""}
        value={alignments.find(
          (x) => x.value === component.attributes.components.logo.alignment
        )}
        options={alignments}
        placeholder=""
      ></Select>
    </div>
  );
}
