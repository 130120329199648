import {
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
  fetchApiDelete,
} from "../../../../api/api";
import { Language } from "../languageSettings/languageSettingsApi";
import {
  updateWebpageById,
  updateWebpages,
  deleteWebpageById,
  addNewWebpage,
} from "./pagesActions";

export interface TranslationWebpage {
  id: number;
  webpage_id: number;
  // selectedLanguage: Language;
  language_id: number;
  target_webpage_id: number;
}

export interface Page {
  id: number;
  name: string;
  page_type: string;
  language: any;
  homepage: boolean;
  language_webpages: Page[];
  page: string;
  seo_description: string | null;
  seo_keyword: string | null;
  seo_no_index: boolean;
  seo_title: string | null;
  parent_webpage_id: number | null;
  seo_og_title: string | null;
  seo_og_description: string | null;
  seo_og_url: string | null;
  seo_og_image_url: string | null;
  seo_og_type: string | null;
  seo_twitter_card: string | null;

  translation_webpages: TranslationWebpage[] | null;

  status: string;
  created_at: string;
  url: string;
  updated_at: string;
  website_id: number;
  website_language_id: number;
}

export interface Pages {
  active: number | null;
  list: Page[];
}

const PagesAPI = {
  loadWebpages,
  saveWebpageById,
  addWebpage,
  deleteWebpage,
  duplicateWebpage,
};
export default PagesAPI;

export function loadWebpages() {
  return fetchApiGet<Pages["list"]>(`/webpages`, {}).then((res) =>
    updateWebpages(res)
  );
}

function deleteWebpage(webpage_id: number) {
  return fetchApiDelete(`/webpages/${webpage_id}`, {}).then(() => {
    deleteWebpageById(webpage_id);
  });
}

function saveWebpageById(id: number, data: Page) {
  return fetchApiPut<Page>(`/webpages/${id}`, data)
    .then((x) => {
      updateWebpageById({ id: id, data: data });
    })
    .catch((e) => console.log(e));
}

function duplicateWebpage(id: number) {
  return fetchApiPost<Page>(`/webpages/${id}/duplicate`).then((res) => {
    addNewWebpage(res);
    return res;
  });
}

function addWebpage(data: { name: string; url: string }) {
  return fetchApiPost<Page>(`/webpages`, data).then((res) => {
    addNewWebpage(res);
    return res;
  });
}
