import { fetchApiGet, fetchApiPost, fetchApiPut } from '../../../../api/api'
import {
    setFontOptions,
} from './font_optionsActions'

export interface FontOption {
  name: string
  options: Array<{
    'font-style': string
    'font-weights': string[]
  }>
}

const FontOptionsAPI = {
    getFontOptions,
}
export default FontOptionsAPI

export function getFontOptions() {
  return fetchApiGet<FontOption[]>(`/fontOptions`, {}).then((res) => setFontOptions(res))
}