import React from "react";

import { useState } from "react";

// @ts-ignore

// @ts-ignore
import { colord } from "colord";

import { useAppSelector } from "../../../../../../store/hooks";
import { RgbaStringColorPicker } from "react-colorful";

export default function ColorPicker({
  value = "#fff",
  title = "untitled",
  onChange,
  setColorPickerOpen,
  returnType = "rgb",
}: {
  value: string;
  title: string;
  onChange: (e: any) => void;
  setColorPickerOpen: (val: boolean) => void;
  returnType?: "rgb" | "hex";
}) {
  const isBuilder = document.getElementById("BuilderContent");

  if (!isBuilder) {
    return <></>;
  }

  const colors = useAppSelector((state) => state.builder.colors);

  const [selectedColorId, setSelectedColorId] = useState<null | number>(null);

  const formats = [
    { value: "hex", label: "Hex" },
    { value: "rgb", label: "RGB" },
    { value: "hsl", label: "HSL" },
    { value: "hwb", label: "HWB" },
  ];
  const [format, setFormat] = useState<(typeof formats)[0] | null>(formats[0]);

  return (
    <div
      style={{
        color: "#fff",
        position: "absolute",
        zIndex: "99999",
      }}
      className="color-picker-position"
    >
      <div style={{ fontSize: "14px", fontWeight: "normal" }}>{title}</div>
      <div
        className="d-flex align-items-center color-picker color-picker__swatches-container b-mb-2"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {colors?.map((x: any) => (
          <div
            className="b-m-1 color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative"
            key={x.id}
            style={{ display: "flex" }}
          >
            <div
              key={x.id}
              onClick={() => {
                setSelectedColorId(x.id);
                onChange(x.color);
                // setColorPickerOpen(false);
              }}
              className={
                "color-picker color-picker__swatch-item" +
                (selectedColorId === x.id
                  ? " color-picker__swatch-item--active"
                  : "")
              }
              style={{ background: x.color }}
            ></div>
          </div>
        ))}
      </div>
      <RgbaStringColorPicker
        color={colord(value).toRgbString()}
        onChange={(val) => {
          if (returnType === "rgb") {
            onChange(colord(val).toRgbString());
          } else {
            onChange(colord(val).toHex());
          }
        }}
      />
      <div
        className={
          "d-flex b-gap-1 b-mt-2" + (format?.value == "rgb" ? " b-mb-2" : "")
        }
        style={{ fontSize: "12px" }}
      >
        {format?.value === "hex" && (
          <input
            className="color-picker color-picker__input builder-input text-center"
            style={{ width: "75px", height: "19.2px" }}
            onChange={(evt) => {
              if (returnType === "rgb") {
                onChange(colord(evt.target.value).toRgbString());
              } else {
                onChange(colord(evt.target.value).toHex());
              }
            }}
            value={colord(value).toHex()}
          />
        )}
        {format?.value === "rgb" && (
          <>
            <div className="position-relative">
              <input
                maxLength={3}
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                value={colord(value).toRgb().r}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), r: evt.target.value })
                }
              />
              <label className="color-picker color-picker__letter">R</label>
            </div>
            <div className="position-relative">
              <input
                maxLength={3}
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                value={colord(value).toRgb().g}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), g: evt.target.value })
                }
              />
              <label className="color-picker color-picker__letter">G</label>
            </div>
            <div className="position-relative">
              <input
                maxLength={3}
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), b: evt.target.value })
                }
                value={colord(value).toRgb().b}
              />
              <label className="color-picker color-picker__letter">B</label>
            </div>
            <div className="position-relative" style={{ minWidth: "40px" }}>
              <input
                max="1"
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), a: evt.target.value })
                }
                value={colord(value).toRgb().a}
              />
              <label className="color-picker color-picker__letter">A</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
