import React, { Suspense } from "react";

// const CartFontMenu = React.lazy(
//   () => import("../../Menus/CartFontMenu/CartFontMenu")
// );
// const FontMenu = React.lazy(() => import("../../Menus/FontMenu/FontMenu"));
// const ColMenu = React.lazy(() => import("../../Menus/ColMenu/ColMenu"));
// const LinkMenu = React.lazy(() => import("../../Menus/LinkMenu/LinkMenu"));
// const MenuMenu = React.lazy(() => import("../../Menus/MenuMenu/MenuMenu"));
// const CartMenu = React.lazy(() => import("../../Menus/CartMenu/CartMenu"));
// const ProductsMenu = React.lazy(
//   () => import("../../Menus/ProductsMenu/ProductsMenu")
// );

// const SingleProductMenu = React.lazy(
//   () => import("../../Menus/SingleProductMenu/SingleProductMenu")
// );

// const LogoMenu = React.lazy(() => import("../../Menus/LogoMenu/LogoMenu"));
// const LanguageMenu = React.lazy(
//   () => import("../../Menus/LanguageMenu/LanguageMenu")
// );
// const ButtonMenu = React.lazy(
//   () => import("../../Menus/ButtonMenu/ButtonMenu")
// );
// const BorderMenu = React.lazy(
//   () => import("../../Menus/BorderMenu/BorderMenu")
// );
// const LogoBgMenu = React.lazy(
//   () => import("../../Menus/LogoBgMenu/LogoBgMenu")
// );
// // const SpacingMenu = React.lazy(
// //   () => import("../../Menus/SpacingMenu/SpacingMenu")
// // );

// const FontEditorMenu = React.lazy(
//   () => import("../../Menus/FontEditorMenu/FontEditorMenu")
// );
// const BackgroundMenu = React.lazy(
//   () => import("../../Menus/BackgroundMenu/BackgroundMenu")
// );
// const TextShadowMenu = React.lazy(
//   () => import("../../Menus/TextShadowMenu/TextShadowMenu")
// );
// const ProductSliderMenu = React.lazy(
//   () => import("../../Menus/ProductSliderMenu/ProductSliderMenu")
// );
// const ContentWidthMenu = React.lazy(
//   () => import("../../Menus/ContentWidthMenu/ContentWidthMenu")
// );
// const ImageMenu = React.lazy(() => import("../../Menus/ImageMenu/ImageMenu"));
// const AccordionMenu = React.lazy(
//   () => import("../../Menus/AccordionMenu/AccordionMenu")
// );
// const LanguageFontMenu = React.lazy(
//   () => import("../../Menus/LanguageFontMenu/LanguageFontMenu")
// );
// const HeaderMenu = React.lazy(
//   () => import("../../Menus/HeaderMenu/HeaderMenu")
// );
// const AnchorMenu = React.lazy(
//   () => import("../../Menus/AnchorMenu/AnchorMenu")
// );

// const ProductsSliderMenu = React.lazy(
//   () => import("../../Menus/ProductsSliderMenu/ProductsSliderMenu")
// );

import SpacingMenu from "../../Menus/SpacingMenu/SpacingMenu";
import CartMenu from "../../Menus/CartMenu/CartMenu";
import SingleProductMenu from "../../Menus/SingleProductMenu/SingleProductMenu";
import ProductsMenu from "../../Menus/ProductsMenu/ProductsMenu";
import LanguageMenu from "../../Menus/LanguageMenu/LanguageMenu";
import LinkMenu from "../../Menus/LinkMenu/LinkMenu";
import MenuMenu from "../../Menus/MenuMenu/MenuMenu";
import ColMenu from "../../Menus/ColMenu/ColMenu";
import FontMenu from "../../Menus/FontMenu/FontMenu";
import CartFontMenu from "../../Menus/CartFontMenu/CartFontMenu";
import AnchorMenu from "../../Menus/AnchorMenu/AnchorMenu";
import ProductsSliderMenu from "../../Menus/ProductsSliderMenu/ProductsSliderMenu";
import LogoMenu from "../../Menus/LogoMenu/LogoMenu";
import LanguageFontMenu from "../../Menus/LanguageFontMenu/LanguageFontMenu";
import ButtonMenu from "../../Menus/ButtonMenu/ButtonMenu";
import BorderMenu from "../../Menus/BorderMenu/BorderMenu";
import LogoBgMenu from "../../Menus/LogoBgMenu/LogoBgMenu";
import ContentWidthMenu from "../../Menus/ContentWidthMenu/ContentWidthMenu";
import TextShadowMenu from "../../Menus/TextShadowMenu/TextShadowMenu";
import ImageMenu from "../../Menus/ImageMenu/ImageMenu";
import AccordionMenu from "../../Menus/AccordionMenu/AccordionMenu";
import HeaderMenu from "../../Menus/HeaderMenu/HeaderMenu";
import FontEditorMenu from "../../Menus/FontEditorMenu/FontEditorMenu";
import BackgroundMenu from "../../Menus/BackgroundMenu/BackgroundMenu";
import ProductSliderMenu from "../../Menus/ProductSliderMenu/ProductSliderMenu";
import CheckoutMenu from "../../Menus/CheckoutMenu/CheckoutMenu";

const BottomMenu = ({
  activeMenu,
  id,
  triggerResize,
  onMouseUp,
  setRange,
  keyDownEvent,
}) => {
  const fallBack = (
    <div className="builder-border-menu__container">
      <div
        className="spinner-border spinner-border-sm"
        role="status"
        style={{ margin: "0px 15px" }}
      ></div>
    </div>
  );

  switch (activeMenu) {
    case "cartFontMenu":
      return (
        <Suspense fallback={fallBack}>
          <CartFontMenu
            id={id}
            triggerResize={triggerResize}
            onMouseUp={onMouseUp}
            setRange={setRange}
            keyDownEvent={keyDownEvent}
          />
        </Suspense>
      );

    case "productsSliderMenu":
      return (
        <Suspense fallback={fallBack}>
          <ProductsSliderMenu id={id} triggerResize={triggerResize} />
        </Suspense>
      );

    case "checkoutMenu":
      return (
        <Suspense fallback={fallBack}>
          <CheckoutMenu id={id} triggerResize={triggerResize} />
        </Suspense>
      );

    case "singleProductMenu":
      return (
        <Suspense fallback={fallBack}>
          <SingleProductMenu id={id} triggerResize={triggerResize} />
        </Suspense>
      );

    case "fontMenu":
      return (
        <Suspense fallback={fallBack}>
          <FontMenu
            id={id}
            triggerResize={triggerResize}
            onMouseUp={onMouseUp}
            setRange={setRange}
            keyDownEvent={keyDownEvent}
          />
        </Suspense>
      );
    case "colMenu":
      return (
        <Suspense fallback={fallBack}>
          <ColMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "linkMenu":
      return (
        <Suspense fallback={fallBack}>
          <LinkMenu key="linkMenu" id={id} triggerResize={triggerResize} />
        </Suspense>
      );
    case "menuMenu":
      return (
        <Suspense fallback={fallBack}>
          <MenuMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "cartMenu":
      return (
        <Suspense fallback={fallBack}>
          {" "}
          <CartMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );

    case "productsMenu":
      return (
        <Suspense fallback={fallBack}>
          <ProductsMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );

    case "logoMenu":
      return (
        <Suspense fallback={fallBack}>
          <LogoMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "languageMenu":
      return (
        <Suspense fallback={fallBack}>
          <LanguageMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "buttonMenu":
      return (
        <Suspense fallback={fallBack}>
          <ButtonMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "borderMenu":
      return (
        <Suspense fallback={fallBack}>
          <BorderMenu triggerResize={triggerResize} id={id} />
        </Suspense>
      );
    case "logoBgMenu":
      return (
        <Suspense fallback={fallBack}>
          <LogoBgMenu triggerResize={triggerResize} id={id} />{" "}
        </Suspense>
      );
    case "spacingMenu":
      return (
        <SpacingMenu triggerResize={triggerResize} id={id} />
        // <Suspense fallback={fallBack}>
        // </Suspense>
      );

    case "fontEditorMenu":
      return (
        <Suspense fallback={fallBack}>
          <FontEditorMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );

    case "backgroundMenu":
      return (
        <Suspense fallback={fallBack}>
          <BackgroundMenu triggerResize={triggerResize} id={id} />{" "}
        </Suspense>
      );
    case "textShadowMenu":
      return (
        <Suspense fallback={fallBack}>
          <TextShadowMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "contentWidthMenu":
      return (
        <Suspense fallback={fallBack}>
          <ContentWidthMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
    case "imageMenu":
      return (
        <Suspense fallback={fallBack}>
          <ImageMenu triggerResize={triggerResize} id={id} />{" "}
        </Suspense>
      );
    case "productSliderMenu":
      return (
        <Suspense fallback={fallBack}>
          <ProductSliderMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );

    case "accordionMenu":
      return (
        <Suspense fallback={fallBack}>
          <AccordionMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );

    case "languageFontMenu":
      return (
        <Suspense fallback={fallBack}>
          <LanguageFontMenu
            id={id}
            triggerResize={triggerResize}
            onMouseUp={onMouseUp}
            setRange={setRange}
            keyDownEvent={keyDownEvent}
          />
        </Suspense>
      );

    case "headerMenu":
      return (
        <Suspense fallback={fallBack}>
          <HeaderMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );

    case "anchorMenu":
      return (
        <Suspense fallback={fallBack}>
          <AnchorMenu id={id} triggerResize={triggerResize} />{" "}
        </Suspense>
      );
  }

  return <>asdf</>;
};
export default BottomMenu;
