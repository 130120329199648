import { Pages, Page } from './pagesApi'
import { Record } from 'immutable'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const defaultValues: Pages = {
    // pages: {
      active: null,
      list: [],
    // },
}

class State extends Record(defaultValues) {
  constructor(params: Pages) {
    super(params)
  }
}

const initialState: State = new State(defaultValues)


export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    UPDATE_WEBPAGES: (state, action: PayloadAction<Pages['list']>) => {
        return state.setIn(['list'], action.payload)
    },
    UPDATE_WEBPAGE_BY_ID: (state: any, action: PayloadAction<{
      id: number
      data: Page
    }>) => {
      let index = state!.list.findIndex((x: any) => x.id === action.payload.id)
      return state.setIn(['list', index], action.payload.data)
    },
    DELETE_WEBPAGE_BY_ID: (state: any, action: PayloadAction<{ id: number }>) => {
      let index = state!.list.findIndex((x: any) => x.id === action.payload.id)
      return state.removeIn(['list', index])
    },
    ADD_WEBPAGE: (state: any, action: PayloadAction<Page>) => {
      let index = state!.list.findIndex((x: any) => x.id === 0)
      return state.setIn(['list', index], action.payload)
    },
    SET_WEBPAGE_ACTIVE: (state: any, action: PayloadAction<Pages['active']>) => {
        return state.setIn(['active'], action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  UPDATE_WEBPAGES,
  UPDATE_WEBPAGE_BY_ID,
  DELETE_WEBPAGE_BY_ID,
  ADD_WEBPAGE,
  SET_WEBPAGE_ACTIVE,
} = pagesSlice.actions

export default pagesSlice.reducer
