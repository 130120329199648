import store from "../../../store"
import { Document } from './documentsApi'
import {
    SET_DOCUMENTS_TREE,
} from './documentsReducer'

export function setDocumentsTree(payload: Document[]) {
    return store.dispatch(
      SET_DOCUMENTS_TREE(payload)
    )
}