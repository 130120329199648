import { fetchApiGet, fetchApiPost, fetchApiPut } from '../../../../api/api'
import {
    setFontUrl,
} from './font_urlActions'

export interface FontUrl {
  url: string
}

const FontUrlAPI = {
    getFontUrl,
}
export default FontUrlAPI

export function getFontUrl() {
  return fetchApiGet<FontUrl>(`/fontUrl`, {}).then((res) => setFontUrl(res.url))
}