import { useEffect, useState } from "react";
import ContentRenderer from "../../_default/ContentRenderer";
import { useAppSelector } from "../../../store/hooks";
import AddHeaderOrFooter from "./AddHeaderOrFooter";
import React from "react";

export default function BuilderContent() {
  const website = useAppSelector((state) => state.builder.website);
  const colors = useAppSelector((state) => state.builder.colors);
  const pages = useAppSelector((state) => state.builder.pages);

  const [headerSettingsVisibility, setHeaderSettingsVisibility] =
    useState(false);

  const [footerSettingsVisibility, setFooterSettingsVisibility] =
    useState(false);

  const structure = useAppSelector((state) => state.builder.structure);

  useEffect(() => {
    document.body.style.fontSize = website.base_font_size;

    colors.forEach((color) => {
      if (color.name === "GLOBALBACKGROUNDCOLOR") {
        document.body.style.backgroundColor = color.color;
      }
    });

    document.getElementsByTagName("html")[0].style.fontSize =
      website.base_font_size;
  }, [website.base_font_size, colors]);

  useEffect(() => {
    console.log(structure, "THIS IS THE STURCUTRE");

    const hasFooter = structure.webpageComponents.some((x) => {
      return structure.components[x]?.type === "footer";
    });

    if (hasFooter && footerSettingsVisibility === false) {
      setHeaderSettingsVisibility(false);
    } else if (!hasFooter && footerSettingsVisibility === true) {
      setHeaderSettingsVisibility(true);
    } else if (hasFooter && footerSettingsVisibility === true) {
      setHeaderSettingsVisibility(false);
    } else if (!hasFooter && footerSettingsVisibility === false) {
      setHeaderSettingsVisibility(true);
    }

    // if (pages.active) {
    //   StructureAPI.loadWebpageComponents(pages.active).then((x) => {
    //     console.log(x, "THIS IS THE STURCUTRE");
    //   });
    // }
  }, [structure, pages.active]);

  useEffect(() => {
    console.log(structure, "THIS IS THE STURCUTRE");

    const hasHeader = structure.webpageComponents.some((x) => {
      return structure.components[x]?.type === "header";
    });

    if (hasHeader && headerSettingsVisibility === false) {
      setHeaderSettingsVisibility(false);
    } else if (!hasHeader && headerSettingsVisibility === true) {
      setHeaderSettingsVisibility(true);
    } else if (hasHeader && headerSettingsVisibility === true) {
      setHeaderSettingsVisibility(false);
    } else if (!hasHeader && headerSettingsVisibility === false) {
      setHeaderSettingsVisibility(true);
    }

    console.log(hasHeader, "HAS HEADER");

    // if (pages.active) {
    //   StructureAPI.loadWebpageComponents(pages.active).then((x) => {
    //     console.log(x, "THIS IS THE STURCUTRE");
    //   });
    // }
  }, [structure, pages.active]);

  return (
    <div id="BuilderContent" className="builder__content-container">
      {headerSettingsVisibility && <AddHeaderOrFooter typeName="header" />}
      <ContentRenderer />
      {footerSettingsVisibility && <AddHeaderOrFooter typeName="footer" />}
    </div>
  );
}
