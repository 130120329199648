import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { SectionComponent } from "../../../../_default/interfaces/base";

export default function AnchorMenu({ id }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as SectionComponent;

  return (
    <div className="d-flex flex-wrap align-items-center gap-2">
      <span>Anchor:</span>
      <div className="background-menu_input" style={{ maxWidth: "100px" }}>
        <input
          type="text"
          className="global-editor__input builder-input"
          value={component.attributes.anchor_id || ""}
          onChange={(e) => {
            let value = e.target.value;

            if (e.target.value.startsWith("#")) {
              value = e.target.value.split("#")[1];

              updateStructureComponent(id, "attributes.anchor_id", value);
            } else {
              updateStructureComponent(id, "attributes.anchor_id", value);
            }
          }}
        />
      </div>
    </div>
  );
}
