import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useShoppingCart } from "../../../../_default/context/ShoppingCartContext";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../../store/hooks";
import { SingleProductComponent } from "../../../../_default/interfaces/base";
export const TextEditorSelectStyle: StylesConfig<any, false> = {
  ...DefaultReactSelectStyle,
  menuList: (styles, data) => {
    return {
      ...styles,
      overflowX: "hidden",
    };
  },
  container: (styles, data) => {
    return {
      ...styles,
    };
  },
  singleValue: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.singleValue!(styles, data),
      overflow: "visible",
      color: "white",
      padding: "0px 5px",
    };
  },
  option: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.option!(styles, data),
      padding: "1px 7px",
      textAlign: "left",
    };
  },
  control: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.control!(styles, data),
      minHeight: "inherit",
      flexWrap: "nowrap",
    };
  },
  valueContainer: (styles, data) => {
    return {
      ...styles,
      padding: "0px",
      minWidth: "inherit",
      alignSelf: "flex-start",
      overflow: "hidden",
      color: "white",
    };
  },

  input: (styles) => {
    return {
      ...styles,
      color: "white",
    };
  },

  menu: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.menu!(styles, data),
      marginTop: "2px",
    };
  },
  dropdownIndicator: (styles, data) => {
    return {
      ...styles,
      height: "100%",
      padding: 0,
      alignSelf: "flex-start",
      alignItems: "center",
      width: "12px",
      color: "#fff",
      svg: {
        height: "12px",
      },
    };
  },
};

export default function SingleProductMenu({ id, triggerResize }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id] as SingleProductComponent
  );
  const { locationProducts } = useShoppingCart();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (locationProducts) {
      const locationProductFound = locationProductOptions.find(
        (x) => x.id === component.attributes.productId
      );

      if (locationProductFound) {
        setSearchQuery(locationProductFound.label);
      }
    }
  }, [component.attributes.productId]);

  const locationProductOptions = locationProducts
    .map((locationProduct) => {
      return {
        value: locationProduct.id,
        label: locationProduct.product.name,
        ...locationProduct,
      };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  return (
    <div className="border-menu_input">
      <Select
        menuPosition="fixed"
        isSearchable={true}
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={locationProductOptions.find((x) => x.label === searchQuery)}
        onChange={(val) => {
          console.log(val, "THIS IS THE VALUE");
          setSearchQuery(val.label);

          updateStructureComponent(id, "attributes.productId", val.value);
        }}
        options={locationProductOptions}
        placeholder="Search product by name"
      ></Select>
    </div>
  );
}
