// import Website from './Website/Website'
// import Pages from './Pages/Pages'
// import Colors from './Colors/Colors'
// import Languages from './Languages/Languages'
// import Templates from './Templates/Templates'
// import Typography from './Typography/Typography'
import { inClass } from "../../../../utils/Helpers";
import { SidebarMenus } from "../Sidebar";

import React, { Suspense, useEffect } from "react";

const Website = React.lazy(() => import("./Website/Website"));
const Pages = React.lazy(() => import("./Pages/Pages"));
const Colors = React.lazy(() => import("./Colors/Colors"));
const Languages = React.lazy(() => import("./Languages/Languages"));
const Templates = React.lazy(() => import("./Templates/Templates"));
const Typography = React.lazy(() => import("./Typography/Typography"));

export default function SubSidebar({
  selectedMenu = "",
  setSelectedMenu,
}: {
  selectedMenu: SidebarMenus;
  setSelectedMenu: React.Dispatch<React.SetStateAction<SidebarMenus>>;
}) {
  function getMenu() {
    let Menu;
    if (selectedMenu == "pages") {
      Menu = (
        <Suspense
          fallback={<div style={{ fontWeight: "bold" }}>Website: Pages</div>}
        >
          {" "}
          <Pages setSelectedMenu={setSelectedMenu} />{" "}
        </Suspense>
      );
    } else if (selectedMenu == "templates") {
      Menu = (
        <Suspense
          fallback={
            <div style={{ fontWeight: "bold" }}>Website: Templates</div>
          }
        >
          <Templates />
        </Suspense>
      );
    } else if (selectedMenu == "color") {
      Menu = (
        <Suspense
          fallback={<div style={{ fontWeight: "bold" }}>Website: Colors</div>}
        >
          <Colors />
        </Suspense>
      );
    } else if (selectedMenu == "font") {
      Menu = (
        <Suspense
          fallback={
            <div style={{ fontWeight: "bold" }}>Website: Typographies</div>
          }
        >
          <Typography />
        </Suspense>
      );
    } else if (selectedMenu == "flag") {
      Menu = (
        <Suspense
          fallback={
            <div style={{ fontWeight: "bold" }}>Website: Languages</div>
          }
        >
          <Languages />
        </Suspense>
      );
    } else if (selectedMenu == "settings") {
      Menu = (
        <Suspense fallback={<div style={{ fontWeight: "bold" }}>Website:</div>}>
          <Website />
        </Suspense>
      );
    }
    return Menu;
  }

  // Add eventListener to close the sidebar, if clicked outside of the sidebar

  // Check if the sidebar is open

  useEffect(() => {
    if (selectedMenu == "") {
      return;
    } else {
      const app = document.getElementsByClassName("app-content")[0];

      app.addEventListener("click", (e) => {
        let target = e.target as HTMLElement;
        let parent = target.parentElement;

        while (parent) {
          if (parent.classList.contains("app-content")) {
            setSelectedMenu("");
            return;
          }
          parent = parent.parentElement;
        }

        setSelectedMenu("");
      });

      return () => {
        app.removeEventListener("click", (e) => {
          let target = e.target as HTMLElement;
          let parent = target.parentElement;

          while (parent) {
            if (parent.classList.contains("app-content")) {
              setSelectedMenu("");
              return;
            }
            parent = parent.parentElement;
          }

          setSelectedMenu("");
        });
      };
    }
  }, [selectedMenu]);

  useEffect(() => {
    const templateOverlay = document.getElementById("template_edit_over_lay");

    if (templateOverlay) {
      if (selectedMenu == "") {
        templateOverlay.style.width = `calc(100% - 40px)`;
      } else if (selectedMenu) {
        templateOverlay.style.width = `calc(100% - 440px)`;
      }
    }
  }, [selectedMenu]);

  return (
    <div
      id="subsidebar"
      className={"subsidebar" + (selectedMenu == "" ? " d-none" : "")}
    >
      {getMenu() ? getMenu() : ""}
    </div>
  );
}
