import store from "../../../store"
import { FontOption } from './font_optionsApi'
import {
    SET_FONT_OPTIONS,
} from './font_optionsReducer'

export function setFontOptions(payload: FontOption[]) {
    return store.dispatch(
      SET_FONT_OPTIONS(payload)
    )
  }