import "./EditorModal.scss";
import React from "react";
import TemplateDetailsGeneralEditForm from "../../Sidebar/SubSidebar/Templates/TemplateDetailsGeneralEditForm";
interface Props {
  createComponentTemplate: any;
  setCreateComponentTemplate: any;
  template: any;
}
export default function CreateTemplateModal({
  createComponentTemplate,
  setCreateComponentTemplate,
  template,
}: Props) {
  const newTemplate = {
    id: 0,
    website_id: 0,
    name: "",
    use: "",
    webpage_component_id: createComponentTemplate.id,
    language_id: null,
    type: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    component: null,
  };

  return (
    <div className="editor-modal editor-modal__container">
      <div className="editor-modal editor-modal__overlay"></div>
      <div className="editor-modal editor-modal__form">
        <div
          className="container p-0"
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            fontFamily: "sans-serif",
          }}
        >
          <TemplateDetailsGeneralEditForm
            setGeneralEdit={setCreateComponentTemplate}
            template={template ?? newTemplate}
            setEditTemplate={() => void 0}
          />
        </div>
      </div>
    </div>
  );
}
