import { Document } from './documentsApi'
import { Set } from 'immutable'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Set<Document> = Set([])

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    SET_DOCUMENTS_TREE: (state: any, action: PayloadAction<Document[]>) => {
        state = Set(action.payload)
        return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { SET_DOCUMENTS_TREE } = documentsSlice.actions

export default documentsSlice.reducer
