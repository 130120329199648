import { useAppSelector } from "../../../../../store/hooks";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { ProductsComponent } from "../../../../_default/interfaces/base";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import React from "react";
export default function ProductsMenu({ id }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as ProductsComponent;

  const defaultSortOptions = [
    {
      value: "name_asc",
      label: "By name: A-Z",
    },
    {
      value: "name_desc",
      label: "By name: Z-A",
    },
    {
      value: "price_asc",
      label: "Price asc",
    },
    {
      value: "price_desc",
      label: "Price desc",
    },
  ];

  const depthLevelOptions = [
    {
      value: 0,
      label: "All closed",
    },
    {
      value: 1,
      label: "1 level open",
    },
    {
      value: 2,
      label: "2 levels open",
    },
    {
      value: 3,
      label: "3 levels open",
    },
  ];

  const showCategoriesOptions = [
    {
      value: true,
      label: "Visible",
    },
    {
      value: false,
      label: "Hidden",
    },
  ];

  const categoriesPosition = [
    {
      value: "sticky",
      label: "Sticky",
    },
    {
      value: "block",
      label: "Block",
    },
  ];

  const topPositionUnits = [
    {
      value: "px",
      label: "px",
    },
  ];

  return (
    <div className="d-flex flex-wrap align-items-center gap-2">
      <span>Sort:</span>

      <div className="border-menu_input">
        <Select
          menuPosition="fixed"
          isSearchable={false}
          styles={TextEditorSelectStyle}
          noOptionsMessage={({}) => ""}
          value={
            defaultSortOptions.find(
              (x) => x.value === component.attributes.default_sort
            )
              ? defaultSortOptions.find(
                  (x) => x.value === component.attributes.default_sort
                )
              : defaultSortOptions[0]
          }
          onChange={(val) => {
            updateStructureComponent(id, "attributes.default_sort", val.value);
          }}
          options={defaultSortOptions}
          placeholder=""
        ></Select>
      </div>

      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <span style={{ width: "auto" }}>Show categories:</span>

        <div className="border-menu_input">
          <Select
            menuPosition="fixed"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              showCategoriesOptions.find(
                (x) => x.value === component.attributes.category_visible
              )
                ? showCategoriesOptions.find(
                    (x) => x.value === component.attributes.category_visible
                  )
                : showCategoriesOptions.find((x) => x.value === true)
            }
            onChange={(val) => {
              updateStructureComponent(
                id,
                "attributes.category_visible",
                val.value
              );
            }}
            options={showCategoriesOptions}
            placeholder=""
          ></Select>
        </div>
      </div>

      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <span style={{ width: "auto" }}>Categories position:</span>

        <div className="border-menu_input">
          <Select
            menuPosition="fixed"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              categoriesPosition.find(
                (x) => x.value === component.attributes.category_position
              )
                ? categoriesPosition.find(
                    (x) => x.value === component.attributes.category_position
                  )
                : categoriesPosition.find((x) => x.value === "block")
            }
            onChange={(val) => {
              updateStructureComponent(
                id,
                "attributes.category_position",
                val.value
              );
            }}
            options={categoriesPosition}
            placeholder=""
          ></Select>
        </div>
      </div>

      {component.attributes.category_position === "sticky" && (
        <>
          <div>Top sticky pos.:</div>
          <div className="background-menu_input">
            <input
              className="global-editor__input builder-input"
              size={2}
              type="number"
              onChange={(e) => {
                console.log(e, e.target.value);

                if (e.target.value) {
                  updateStructureComponent(
                    id,
                    "attributes.category_sticky_top",
                    e.target.value
                  );
                }
              }}
              value={
                component.attributes.category_sticky_top
                  ? component.attributes.category_sticky_top
                  : 0
              }
            ></input>
            <span style={{ marginLeft: "5px", marginRight: "5px" }}>px</span>
          </div>
        </>
      )}

      {(component.attributes.category_visible === true ||
        component.attributes.category_visible === 1 ||
        component.attributes.category_visible === undefined) && (
        <>
          <span>Categories:</span>

          <div className="border-menu_input">
            <Select
              menuPosition="fixed"
              isSearchable={false}
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={
                depthLevelOptions.find(
                  (x) => x.value === component.attributes.depth_level
                )
                  ? depthLevelOptions.find(
                      (x) => x.value === component.attributes.depth_level
                    )
                  : depthLevelOptions[0]
              }
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.depth_level",
                  val.value
                );
              }}
              options={depthLevelOptions}
              placeholder=""
            ></Select>
          </div>
        </>
      )}
    </div>
  );
}
