import React, { useEffect, useState } from "react";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";

import "./FontEditorMenu.scss";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";

export default function FontEditorMenu({ id, triggerResize }) {
  const typographies = useAppSelector((state) => state.builder.typographies);

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  );

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <span>Typography: </span>
      <Select
        menuPortalTarget={document.getElementById("react-select-container")}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={(val) => {
          updateStructureComponent(id, "attributes.typography_id", val.value);
        }}
        className="m-0 font-menu_input"
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={typographies
          .map((x: any) => {
            return { ...x, label: x.name, value: x.id };
          })
          .find((x) => x.id === component.attributes.typography_id)}
        options={typographies
          ?.map((x: any) => {
            return { ...x, label: x.name, value: x.id };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label))}
        placeholder=""
      ></Select>
    </div>
  );
}
