import { useState } from "react";
import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Sauce https://www.codemzy.com/blog/react-drag-drop-file-upload

// interface DragAndDropUploadProps {
//   icon: string;
//   onChange: (e: any) => void;
//   setEditing: (v: boolean) => void;
// }

export default function DragAndDropUpload({
  icon = "upload",
  onChange,
  setEditing,
}: // DragAndDropUploadProps
{
  icon?: string;
  onChange: (e: any) => void;
  setEditing?: (v: boolean) => void;
}) {
  const [dragActive, setDragActive] = useState(false);
  let uploadElement: any;

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // Triggers when file is dropped
  const handleDrop = function (e: any) {
    onChange(e);
    setDragActive(false);

    // if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    //   WebsiteAPI.setWebsiteFavicon(e.dataTransfer.files[0]).then(() => {
    //     if (setEditing) {
    //       setEditing(false);
    //     }
    //   });
    // }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      onChange(e);
    }
  };

  return (
    <div
      className={
        "builder-upload builder-upload-drag" +
        (dragActive ? " drag-active" : "")
      }
    >
      <div
        className="builder-upload builder-upload-btn"
        role="button"
        onClick={() => uploadElement.click()}
        onDragEndCapture={handleDrag}
        onDropCapture={(e) => {
          console.log(e);

          e.stopPropagation();
          e.preventDefault();
          handleDrop(e);
        }}
        onDragLeaveCapture={handleDrag}
        onDragOverCapture={handleDrag}
      >
        <input
          type="file"
          onChange={handleChange}
          ref={(input) => (uploadElement = input)}
          style={{ display: "none" }}
          multiple
        />
        <div className="builder-upload-drag-container">
          <div className="builer-upload-drag-icon text-center">
            {icon == "upload" && <FileUploadIcon />}
            {icon == "cloud" && <CloudUploadIcon />}
          </div>
          <p className="builder-upload-text b-mb-2 text-center">
            Click or drag file to this area to upload
          </p>
        </div>
      </div>
    </div>
  );
}
