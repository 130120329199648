import { setIn, getIn, removeIn } from "immutable";
import { createSlice, current, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  AllComponents,
  WebpageComponentRelation,
} from "../../../../components/_default/interfaces/base";
import { CSSProperties } from "react";
import { Editor } from "../editor/editorApi";

// const initialState: Set<AllComponents[]> = Set([]) // Was unable to use immutable Set. Too much problems
// const initialState: AllComponents[] = [] // Was unable to use immutable Set. Too much problems

export interface StructureMap {
  webpageComponents: Array<any>;
  components: {
    [key: string]: AllComponents;
  };
}
const initialState: StructureMap = {
  webpageComponents: [],
  components: {},
};

export const structureSlice = createSlice({
  name: "structure",
  initialState,
  reducers: {
    UPDATE_STRUCTURE: (state, action: PayloadAction<StructureMap>) => {
      var updatedComponents = {
        ...state.components,
        ...action.payload.components,
      };
      // action.payload.components.map((component) => {
      //   if (updatedComponents.find((c) => c.id === component.id) == null) {
      //     updatedComponents = [...updatedComponents, component]
      //   } else {
      //     updatedComponents = updatedComponents.map((c) => {
      //       if (c.id === component.id) {
      //         return { ...c, ...component }
      //       } else {
      //         return c
      //       }
      //     })
      //   }
      // })

      var updatedWebpageComponents = [...state.webpageComponents];
      action.payload.webpageComponents.map((webpageComponentId) => {
        if (
          updatedWebpageComponents.find((c) => c === webpageComponentId) == null
        ) {
          updatedWebpageComponents = [
            ...updatedWebpageComponents,
            webpageComponentId,
          ];
        }
        // else {
        //   updatedWebpageComponents = updatedWebpageComponents.map((c) => {
        //     if (c.id === component.id) {
        //       return { ...c, ...component }
        //     } else {
        //       return c
        //     }
        //   })
        // }
      });
      updatedWebpageComponents = updatedWebpageComponents.sort((a, b) => {
        const aComponent = updatedComponents[a];
        const bComponent = updatedComponents[b];
        if (!aComponent || !bComponent) {
          return 0;
        }
        if (aComponent.sort_order > bComponent.sort_order) {
          return 1;
        }
        if (aComponent.sort_order < bComponent.sort_order) {
          return -1;
        }
        return 0;
      });

      return {
        ...state,
        components: {
          ...updatedComponents,
        },
        webpageComponents: [...updatedWebpageComponents],
      };
    },
    SET_STRUCTURE: (state, action: PayloadAction<StructureMap>) => {
      state = action.payload;
      return state;
    },

    UPDATE_STRUCTURE_WEBPAGECOMPONENTS_ORDER: (
      state,
      action: PayloadAction<{
        draggedComponentId: number;
        destinationId: number;
        position: string;
      }>
    ) => {
      const { draggedComponentId, destinationId, position } = action.payload;
      const webpageComponents = [...state.webpageComponents];

      const draggedIndex = webpageComponents.indexOf(
        Number(draggedComponentId)
      );
      const destinationIndex = webpageComponents.indexOf(Number(destinationId));

      if (position === "top") {
        // Remove the dragged component from its original position
        webpageComponents.splice(draggedIndex, 1);

        // Insert the dragged component one place ahead of the destination index
        if (draggedIndex < destinationIndex) {
          // If the dragged index is before the destination index, adjust the destination index accordingly
          webpageComponents.splice(
            destinationIndex - 1,
            0,
            Number(draggedComponentId)
          );
        } else {
          webpageComponents.splice(
            destinationIndex,
            0,
            Number(draggedComponentId)
          );
        }
      } else if (position === "bottom") {
        // Remove the dragged component from its original position
        webpageComponents.splice(draggedIndex, 1);

        // Insert the dragged component after the destination index
        if (draggedIndex > destinationIndex) {
          // If the dragged index is greater than the destination index, adjust the destination index accordingly
          webpageComponents.splice(
            destinationIndex + 1,
            0,
            Number(draggedComponentId)
          );
        } else {
          webpageComponents.splice(
            destinationIndex,
            0,
            Number(draggedComponentId)
          );
        }
      }

      return {
        ...state,
        webpageComponents,
      };
    },

    UPDATE_STRUCTURE_COMPONENT_ORDER: (
      state,
      action: PayloadAction<{
        parent_id: number;
        draggedComponentId: number;
        destinationId: number;
        position: string;
        section: boolean;
      }>
    ) => {
      const {
        parent_id,
        draggedComponentId,
        destinationId,
        position,
        section,
      } = action.payload;

      const parentComponent = { ...state.components[parent_id] };

      const destinationComponent = { ...state.components[destinationId] };

      const draggedComponent = {
        ...state.components[draggedComponentId],
      };

      const draggedIndex = JSON.parse(
        JSON.stringify(state.components[parent_id])
      ).childWebpageComponentRelations.findIndex(
        (c) => c.child_id === Number(draggedComponentId)
      );

      const destinationIndex = JSON.parse(
        JSON.stringify(state.components[parent_id])
      ).childWebpageComponentRelations.findIndex(
        (c) => c.child_id === Number(destinationId)
      );

      // Check if they are sharing the same parent
      if (
        draggedComponent.parent_id === destinationComponent.parent_id &&
        section
      ) {
        if (position === "top") {
          // Remove the dragged component from its original position and move it on top of the destination component

          const updatedArray =
            parentComponent.childWebpageComponentRelations.slice(
              draggedIndex,
              draggedIndex + 1
            );

          parentComponent.childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          parentComponent.childWebpageComponentRelations.splice(
            destinationIndex,
            0,
            ...updatedArray
          );
        } else if (position === "bottom") {
          const updatedArray =
            parentComponent.childWebpageComponentRelations.slice(
              draggedIndex,
              draggedIndex + 1
            );

          // Remove the dragged component from its original position and move it on top of the destination component
          parentComponent.childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );
          parentComponent.childWebpageComponentRelations.splice(
            destinationIndex,
            0,
            ...updatedArray
          );
        }
        parentComponent.childWebpageComponentRelations.forEach(
          (component, index) => {
            component.sort = index + 1;
          }
        );
      } else if (
        draggedComponent.parent_id !== destinationComponent.parent_id &&
        section
      ) {
        if (destinationComponent.parent_id === null) {
          // Get the draggedComponent

          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          // 1) Chagne the draggedComponent parent id to the destinationComponent Id
          state.components[draggedComponentId].parent_id =
            destinationComponent.id;

          // 3) Add the draggedComponent to the new parent

          state.components[
            destinationComponent.id
          ].childWebpageComponentRelations.push(...updatedArray);

          state.components[
            destinationComponent.id
          ].childWebpageComponentRelations.forEach((component, index) => {
            component.sort = index + 1;
          });

          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );

          // 4) Update the old parent component childWebPageComponent sort order
        } else if (
          destinationComponent.parent_id !== null &&
          state.components[destinationComponent.parent_id].parent_id === null
        ) {
          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          // 1) Chagne the draggedComponent parent id to the destinationComponent Id
          state.components[draggedComponentId].parent_id =
            destinationComponent.parent_id;

          // 2) Update the
          if (position === "top") {
            const destinationIndex = JSON.parse(
              JSON.stringify(state.components[destinationComponent.parent_id])
            ).childWebpageComponentRelations.findIndex(
              (c) => c.child_id === Number(destinationId)
            );

            state.components[
              destinationComponent.parent_id
            ].childWebpageComponentRelations.splice(
              destinationIndex,
              0,
              ...updatedArray
            );
          } else if (position === "bottom") {
            const destinationIndex = JSON.parse(
              JSON.stringify(state.components[destinationComponent.parent_id])
            ).childWebpageComponentRelations.findIndex(
              (c) => c.child_id === Number(destinationId)
            );

            state.components[
              destinationComponent.parent_id
            ].childWebpageComponentRelations.splice(
              destinationIndex + 1,
              0,
              ...updatedArray
            );
          }

          state.components[
            destinationComponent.parent_id
          ].childWebpageComponentRelations.forEach((component, index) => {
            component.sort = index + 1;
          });

          // Remove the dragged component from its original position and move it on top of the destination component
          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );
        } else if (
          destinationComponent.parent_id !== null &&
          state.components[destinationComponent.parent_id].type ===
            "inner_section"
        ) {
          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          // 1) Chagne the draggedComponent parent id to the destinationComponent Id
          state.components[draggedComponentId].parent_id =
            destinationComponent.parent_id;

          // 2) Update the
          if (position === "top") {
            const destinationIndex = JSON.parse(
              JSON.stringify(state.components[destinationComponent.parent_id])
            ).childWebpageComponentRelations.findIndex(
              (c) => c.child_id === Number(destinationId)
            );

            state.components[
              destinationComponent.parent_id
            ].childWebpageComponentRelations.splice(
              destinationIndex,
              0,
              ...updatedArray
            );
          } else if (position === "bottom") {
            const destinationIndex = JSON.parse(
              JSON.stringify(state.components[destinationComponent.parent_id])
            ).childWebpageComponentRelations.findIndex(
              (c) => c.child_id === Number(destinationId)
            );

            state.components[
              destinationComponent.parent_id
            ].childWebpageComponentRelations.splice(
              destinationIndex + 1,
              0,
              ...updatedArray
            );
          }

          state.components[
            destinationComponent.parent_id
          ].childWebpageComponentRelations.forEach((component, index) => {
            component.sort = index + 1;
          });

          // Remove the dragged component from its original position and move it on top of the destination component
          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );
        } else if (
          destinationComponent.parent_id !== null &&
          state.components[destinationId].type === "inner_section"
        ) {
          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          // 1) Chagne the draggedComponent parent id to the destinationComponent Id
          state.components[draggedComponentId].parent_id =
            destinationComponent.id;

          // 3) Add the draggedComponent to the new parent

          state.components[
            destinationComponent.id
          ].childWebpageComponentRelations.push(...updatedArray);

          state.components[
            destinationComponent.id
          ].childWebpageComponentRelations.forEach((component, index) => {
            component.sort = index + 1;
          });

          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );
        }

        // // 1) Chagne the draggedComponent parent id to the destinationComponent.PARENT_Id
        // state.components[draggedComponentId].parent_id =
        //   destinationComponent.parent_id;

        // 2) Add the draggedComponent to the new parent
      }

      if (
        draggedComponent.parent_id === destinationComponent.id &&
        section === false
      ) {
      } else if (
        draggedComponent.parent_id === destinationComponent.parent_id &&
        section === false
      ) {
        if (position === "top") {
          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[parent_id].childWebpageComponentRelations.splice(
            destinationIndex,
            0,
            ...updatedArray
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );
        } else if (position === "bottom") {
          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[parent_id].childWebpageComponentRelations.splice(
            destinationIndex + 1,
            0,
            ...updatedArray
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );
        }
      } else if (
        draggedComponent.parent_id !== destinationComponent.id &&
        section === false &&
        destinationComponent.type === "section"
      ) {
        state.components[draggedComponentId].parent_id =
          destinationComponent.id;

        const updatedArray = state.components[
          parent_id
        ].childWebpageComponentRelations.slice(draggedIndex, draggedIndex + 1);
        state.components[draggedComponentId].parent_id =
          destinationComponent.id;

        state.components[
          destinationComponent.id
        ].childWebpageComponentRelations.push(...updatedArray);

        state.components[
          destinationComponent.id
        ].childWebpageComponentRelations.forEach((component, index) => {
          component.sort = index + 1;
        });

        state.components[parent_id].childWebpageComponentRelations.splice(
          draggedIndex,
          1
        );

        state.components[parent_id].childWebpageComponentRelations.forEach(
          (component, index) => {
            component.sort = index + 1;
          }
        );
      } else if (
        draggedComponent.parent_id !== destinationComponent.parent_id &&
        section === false &&
        destinationComponent.type !== "section"
      ) {
        state.components[draggedComponentId].parent_id =
          destinationComponent.parent_id;

        if (position === "top") {
          const destinationIndex = JSON.parse(
            JSON.stringify(state.components[destinationComponent.parent_id!])
          ).childWebpageComponentRelations.findIndex(
            (c) => c.child_id === Number(destinationId)
          );

          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[
            destinationComponent.parent_id!
          ].childWebpageComponentRelations.splice(
            destinationIndex,
            0,
            ...updatedArray
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );

          state.components[
            destinationComponent.parent_id!
          ].childWebpageComponentRelations.forEach((component, index) => {
            component.sort = index + 1;
          });
        } else if (position === "bottom") {
          const destinationIndex = JSON.parse(
            JSON.stringify(state.components[destinationComponent.parent_id!])
          ).childWebpageComponentRelations.findIndex(
            (c) => c.child_id === Number(destinationId)
          );

          const updatedArray = state.components[
            parent_id
          ].childWebpageComponentRelations.slice(
            draggedIndex,
            draggedIndex + 1
          );

          state.components[parent_id].childWebpageComponentRelations.splice(
            draggedIndex,
            1
          );

          state.components[
            destinationComponent.parent_id!
          ].childWebpageComponentRelations.splice(
            destinationIndex + 1,
            0,
            ...updatedArray
          );

          state.components[parent_id].childWebpageComponentRelations.forEach(
            (component, index) => {
              component.sort = index + 1;
            }
          );

          state.components[
            destinationComponent.parent_id!
          ].childWebpageComponentRelations.forEach((component, index) => {
            component.sort = index + 1;
          });
        }
      }
    },

    UPDATE_STRUCTURE_COMPONENT: (
      state,
      action: PayloadAction<{
        id: number;
        key: any;
        value: any;
      }>
    ) => {
      let updateComponent = state.components[action.payload.id];

      let fullPath = action.payload.key.split(".");

      var oldComponentValue = getIn(updateComponent, fullPath) as {};
      var payloadValue = action.payload.value;
      var newValue = payloadValue;
      while (oldComponentValue === undefined) {
        // work out until find existing object key in state
        let pop = fullPath.pop();
        let tmp: any = {};
        tmp[pop] = payloadValue;
        payloadValue = tmp;

        oldComponentValue = getIn(updateComponent, fullPath) as {};
        var newValue = {
          ...oldComponentValue,
          ...payloadValue,
        };
      }
      // Return object with changes
      updateComponent = setIn(updateComponent, fullPath, newValue);

      return {
        ...state,
        components: {
          ...state.components,
          [updateComponent.id]: updateComponent,
        },
      };
    },
    ADD_NEW_COMPONENTS: (
      state,
      action: PayloadAction<{ [key: string]: AllComponents }>
    ) => {
      let updatedParentComponents: any = {};
      let updatedWebpageComponents: number[] = [];
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value.parent_id) {
          let updateParentComponent = state.components[value.parent_id];
          if (updateParentComponent) {
            updatedParentComponents[value.parent_id] = {
              ...updateParentComponent,
              childWebpageComponentRelations: [
                ...updateParentComponent.childWebpageComponentRelations,
                {
                  id: 0,
                  parent_id: value.parent_id,
                  child_id: value.id,
                  sort: value.sort_order,
                  created_at: null,
                  updated_at: null,
                } as WebpageComponentRelation,
              ],
            };
          }
        } else updatedWebpageComponents.push(value.id);
      });
      return {
        ...state,
        components: {
          ...state.components,
          ...updatedParentComponents,
          ...action.payload,
        },
        webpageComponents: [
          ...state.webpageComponents,
          ...updatedWebpageComponents,
        ],
      };
    },
    ADD_NEW_COMPONENT: (state, action: PayloadAction<AllComponents>) => {
    
      if (action.payload.parent_id) {
        let updateParentComponent = state.components[action.payload.parent_id];
        updateParentComponent = {
          ...updateParentComponent,
          childWebpageComponentRelations: [
            ...updateParentComponent.childWebpageComponentRelations,
            {
              id: 0,
              parent_id: action.payload.parent_id,
              child_id: action.payload.id,
              sort: action.payload.sort_order,
              created_at: null,
              updated_at: null,
            } as WebpageComponentRelation,
          ],
        };
        // let updatedComponents = state.components.map(c => {
        //   if (c.id === action.payload.parent_id) {
        //     // Create a *new* object with changes
        //     return {
        //       ...c, childWebpageComponentRelations: [
        //         ...c.childWebpageComponentRelations,
        //         {
        //           id: 0,
        //           parent_id: action.payload.parent_id,
        //           child_id: action.payload.id,
        //           sort: action.payload.sort_order,
        //           created_at: null,
        //           updated_at: null,
        //         } as WebpageComponentRelation
        //       ]
        //     };
        //   } else {
        //     // No changes
        //     return c;
        //   }
        // })
        return {
          ...state,
          components: {
            ...state.components,
            [action.payload.id]: action.payload,
            [updateParentComponent.id]: updateParentComponent,
          },
        };
      } else {
        const updatedComponents: StructureMap["components"] = {
          ...state.components,
          [action.payload.id]: action.payload,
        };
        return {
          ...state,
          components: updatedComponents,
          webpageComponents: [
            ...state.webpageComponents,
            action.payload.id,
          ].sort((a, b) => {
            const aComponent = updatedComponents[a];
            const bComponent = updatedComponents[b];
            if (!aComponent || !bComponent) {
              return 0;
            }
            if (aComponent.sort_order > bComponent.sort_order) {
              return 1;
            }
            if (aComponent.sort_order < bComponent.sort_order) {
              return -1;
            }
            return 0;
          }),
        };
      }
    },
    ADD_CHILD_TO_PARENT: (
      state,
      action: PayloadAction<{
        parent_id: number;
        child_id: number;
        after: number;
      }>
    ) => {
  
      if (action.payload.parent_id) {
        let updateParentComponent = state.components[action.payload.parent_id];
        updateParentComponent = {
          ...updateParentComponent,
          childWebpageComponentRelations: [
            ...updateParentComponent.childWebpageComponentRelations,
            {
              id: 0,
              parent_id: action.payload.parent_id,
              child_id: action.payload.child_id,
              sort: action.payload.after,
              created_at: null,
              updated_at: null,
            } as WebpageComponentRelation,
          ],
        };

        return {
          ...state,
          components: {
            ...state.components,
            [updateParentComponent.id]: updateParentComponent,
          },
        };
      }
    },
    REMOVE_CHILD_FROM_PARENT: (
      state,
      action: PayloadAction<{ parent_id: number; child_id: number }>
    ) => {
   
      if (action.payload.parent_id) {
        let updateParentComponent = state.components[action.payload.parent_id];
        const index =
          updateParentComponent.childWebpageComponentRelations.findIndex(
            (childWebpageComponentRelation) =>
              childWebpageComponentRelation.child_id == action.payload.child_id
          );
        if (index !== -1) {
          updateParentComponent = {
            ...updateParentComponent,
            childWebpageComponentRelations: [
              ...updateParentComponent.childWebpageComponentRelations.slice(
                0,
                index
              ),
              ...updateParentComponent.childWebpageComponentRelations.slice(
                index + 1
              ),
            ],
          };
        }

        return {
          ...state,
          components: {
            ...state.components,
            [updateParentComponent.id]: updateParentComponent,
          },
        };
      }
    },
    DELETE_COMPONENT: (
      state,
      action: PayloadAction<{
        id: number;
        editor: Editor | null;
      }>
    ) => {
      const component_parent_id = action.payload.editor?.component_parent_id;
      // Remove Component. And remove from childWebpageComponentRelations
      var updatedComponents = { ...state.components };
      if (!action.payload.editor) {
        delete updatedComponents[action.payload.id];
        // updatedComponents = updatedComponents.filter(c => c.id !== action.payload.id) // remove from list.
      }

      Object.keys(updatedComponents).map((key) => {
        let c: AllComponents = updatedComponents[key];
        if (component_parent_id) {
          if (c.id == component_parent_id) {
            if (!c.childWebpageComponentRelations) {
              return c;
            }
            const childWebpageComponentRelations =
              c.childWebpageComponentRelations.filter(
                (cr) => cr.child_id !== action.payload.id
              );
            updatedComponents[key] = {
              ...c,
              childWebpageComponentRelations: childWebpageComponentRelations,
            };
            return {
              ...c,
              childWebpageComponentRelations: childWebpageComponentRelations,
            };
          }
          return c;
        } else {
          if (!c.childWebpageComponentRelations) {
            return c;
          }
          const childWebpageComponentRelations =
            c.childWebpageComponentRelations.filter(
              (cr) => cr.child_id !== action.payload.id
            );
          updatedComponents[key] = {
            ...c,
            childWebpageComponentRelations: childWebpageComponentRelations,
          };
          return {
            ...c,
            childWebpageComponentRelations: childWebpageComponentRelations,
          };
        }
      });
      var updatedWebpageComponents = [...state.webpageComponents];
      if (!component_parent_id) {
        updatedWebpageComponents = updatedWebpageComponents.filter(
          (webpageComponentId) => {
            return webpageComponentId !== action.payload.id;
          }
        );
      }
      return {
        ...state,
        components: {
          ...updatedComponents,
        },
        webpageComponents: [...updatedWebpageComponents],
      };
    },
    DELETE_COMPONENT_STYLE: (
      state,
      action: PayloadAction<{
        id: number;
        styles: string[];
      }>
    ) => {
      return setIn(
        state,
        ["components", action.payload.id, "attributes", "styles"],
        Object.fromEntries(
          Object.entries(
            getIn(state, [
              "components",
              action.payload.id,
              "attributes",
              "styles",
            ]) as {}
          ).filter(([key]) => !action.payload.styles.includes(key))
        )
      );
    },
    UPDATE_COMPONENT_STYLE: (
      state,
      action: PayloadAction<{
        id: number;
        style: CSSProperties;
      }>
    ) => {
  

      let updateComponent = { ...state.components[action.payload.id] };

      const fullPath = ["attributes", "styles"];

      const oldStyle = getIn(updateComponent, fullPath) as {};
      const newStyle = {
        ...oldStyle,
        ...action.payload.style,
      };
      // updateComponent = setIn(updateComponent, fullPath, newStyle)
      return setIn(
        state,
        ["components", updateComponent.id, ...fullPath],
        newStyle
      );
      // return {
      //   ...state,
      //   components: {
      //     ...state.components,
      //     [updateComponent.id]: updateComponent
      //   }
      // }
    },

    UPDATE_COMPONENT_TEXT: (
      state,
      action: PayloadAction<{
        id: number;
        text: string;
      }>
    ) => {
      let component = { ...state.components[action.payload.id] };

      component.attributes = {
        ...component.attributes,
        text: action.payload.text,
      };

      return {
        ...state,
        components: {
          ...state.components,
          [component.id]: component,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  SET_STRUCTURE,
  UPDATE_STRUCTURE,
  UPDATE_COMPONENT_TEXT,
  UPDATE_STRUCTURE_COMPONENT,
  UPDATE_COMPONENT_STYLE,
  UPDATE_STRUCTURE_COMPONENT_ORDER,
  UPDATE_STRUCTURE_WEBPAGECOMPONENTS_ORDER,
  ADD_NEW_COMPONENT,
  ADD_NEW_COMPONENTS,
  REMOVE_CHILD_FROM_PARENT,
  DELETE_COMPONENT,
  DELETE_COMPONENT_STYLE,
  ADD_CHILD_TO_PARENT,
} = structureSlice.actions;

export default structureSlice.reducer;
