import { Extension, RawCommands } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import FontSize from "tiptap-extension-font-size";
import TextStyle from "@tiptap/extension-text-style";
import Paragraph from "@tiptap/extension-paragraph";
import FontFamily from "@tiptap/extension-font-family";

import Underline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Highlight from "@tiptap/extension-highlight";

/**
 * FontSize - Custom Extension
 * editor.commands.setFontSize(e.target.value) :set the font-size.
 */

// --------- add this block ---- vvvvvv ----------
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the font size
       */
      setFontSize: (size: string) => ReturnType;
      /**
       * Unset the font size
       */
      unsetFontSize: () => ReturnType;
    };
  }
}
// ---------------- add this block ----- ^^^^ --------------

// export const FontSize = Extension.create({
//   name: "fontSize",
//   addOptions() {
//     return {
//       types: ["textStyle"],
//     };
//   },
//   addGlobalAttributes() {
//     return [
//       {
//         types: this.options.types,
//         attributes: {
//           fontSize: {
//             default: null,
//             parseHTML: (element) =>
//               element.style.fontSize.replace(/['"]+/g, ""),
//             renderHTML: (attributes) => {
//               if (!attributes.fontSize) {
//                 return {};
//               }
//               return {
//                 style: `font-size: ${attributes.fontSize}`,
//               };
//             },
//           },
//         },
//       },
//     ];
//   },
//   addCommands() {
//     return {
//       setFontSize:
//         (fontSize) =>
//         ({ chain }) => {
//           return chain()
//             .setMark("textStyle", { fontSize: fontSize + "px" })
//             .run();
//         },
//       unsetFontSize:
//         () =>
//         ({ chain }) => {
//           return chain()
//             .setMark("textStyle", { fontSize: null })

//             .run();
//         },
//     };
//   },
// });

// import { MarkSpec } from "prosemirror-model";

// const FontWeightMarkSpec: MarkSpec = {
//   attrs: {
//     fontWeight: {
//       default: "normal", // Default font weight
//     },
//   },
//   parseDOM: [
//     {
//       style: "font-weight",
//       getAttrs: (value) => {
//         return value === "bold" ? null : { fontWeight: value };
//       },
//     },
//   ],
//   toDOM(node) {
//     return ["span", { style: `font-weight: ${node.attrs.fontWeight}` }, 0];
//   },
// };

// export default FontWeightMarkSpec;

export const extensions = [
  StarterKit,
  FontSize,
  TextStyle,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  FontFamily,
  Underline,
  Color,
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  Subscript,
  BulletList,
  OrderedList,
  Highlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: "highlight",
    },
  }),
  ListItem,
  Superscript,
];
