import { useAppSelector } from "../../../../../store/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dropdown from "../../../Dropdown/Dropdown";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useEffect, useState } from "react";
import { SectionComponent } from "../../../../_default/interfaces/base";
import React from "react";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function ContentWidthMenu({ id, triggerResize }: Props) {
  console.log("ContentWidthMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as SectionComponent;

  const options = ["%", "px"];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isFooter = false;

  useEffect(() => {
    triggerResize();
  }, []);

  function renderFooterWidthOption() {
    if (
      !component.attributes.content_width_size &&
      !component.attributes.content_width_unit
    ) {
      return (
        <input
          type="number"
          style={{ width: "30px" }}
          className="form-input builder-input"
          onChange={(e) => changeProp("content_width_size", e.target.value)}
          value={
            !component.attributes.content_width_size
              ? 100
              : component.attributes.content_width_size
          }
        />
      );
    }

    if (component.attributes.content_width_size) {
      return (
        <input
          type="number"
          style={{ width: "30px" }}
          className="form-input builder-input"
          onChange={(e) => changeProp("content_width_size", e.target.value)}
          value={component.attributes.content_width_size}
        />
      );
    }

    if (component.attributes.content_width_unit === "px") {
      return (
        <input
          type="number"
          style={{ width: "35px" }}
          className="form-input builder-input"
          onChange={(e) => changeProp("content_width_size", e.target.value)}
          value={component.attributes.content_width_size}
        />
      );
    }
  }

  function renderWidthOption() {
    switch (component.attributes.content_width_unit) {
      case "%":
        return (
          <input
            type="number"
            style={{ width: "30px" }}
            className="form-input builder-input "
            onChange={(e) => changeProp("content_width_size", e.target.value)}
            value={
              component.attributes.content_width_size > 100
                ? 100
                : component.attributes.content_width_size
            }
          />
        );
      case "px":
        return (
          <input
            type="number"
            style={{ width: "35px" }}
            className="form-input builder-input"
            onChange={(e) => changeProp("content_width_size", e.target.value)}
            value={component.attributes.content_width_size}
          />
        );
    }
  }

  useEffect(() => {
    renderWidthOption();
  }, [component.attributes]);

  function changeProp(key: string, val: any) {
    updateStructureComponent(id, "attributes." + key, val);
  }

  return (
    <div className="d-flex flex-wrap align-items-center">
      <div className="b-mx-1 content-width-menu_input">
        <span>
          {isFooter ? renderFooterWidthOption() : renderWidthOption()}
        </span>
      </div>
      <div className="dropup">
        {!isFooter && (
          <span onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {component.attributes.content_width_unit}
            <ArrowDropDownIcon
              viewBox="6 6 12 12"
              style={{ fontSize: "11px" }}
            />
          </span>
        )}

        {isFooter && !component.attributes.content_width_unit && (
          <span onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {/* {component.attributes.content_width_unit} */}
            <ArrowDropDownIcon
              viewBox="6 6 12 12"
              style={{ fontSize: "11px" }}
            />
          </span>
        )}

        {isFooter && component.attributes.content_width_unit && (
          <span onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {component.attributes.content_width_unit}
            <ArrowDropDownIcon
              viewBox="6 6 12 12"
              style={{ fontSize: "11px" }}
            />
          </span>
        )}

        {isDropdownOpen && (
          <div>
            {options.map((x) => (
              <div
                key={x}
                className={
                  "dropdown-item builder-dropdown__item" +
                  (component.attributes.content_width_unit === x
                    ? " builder-dropdown__item--active"
                    : "")
                }
                onClick={() => changeProp("content_width_unit", x)}
              >
                {x}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
