import store from "../../../store";
import { CSSProperties } from "react";
import { AllComponents } from "../../../../components/_default/interfaces/base";
import { Editor } from "../editor/editorApi";
import {
  StructureMap,
  UPDATE_STRUCTURE_COMPONENT,
  UPDATE_COMPONENT_STYLE,
  UPDATE_STRUCTURE,
  SET_STRUCTURE,
  DELETE_COMPONENT,
  DELETE_COMPONENT_STYLE,
  REMOVE_CHILD_FROM_PARENT,
  ADD_CHILD_TO_PARENT,
  ADD_NEW_COMPONENTS,
  ADD_NEW_COMPONENT,
  UPDATE_STRUCTURE_COMPONENT_ORDER,
  UPDATE_STRUCTURE_WEBPAGECOMPONENTS_ORDER,
  UPDATE_COMPONENT_TEXT,
} from "./structureReducer";
import { UPDATE_EDITOR_STATE } from "../editor/editorReducer";

export function updateStructure(payload: StructureMap) {
  // Change the editorRecuder hasEdited value to true

  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));

  return store.dispatch(UPDATE_STRUCTURE(payload));
}
export function setStructure(payload: StructureMap) {
  return store.dispatch(SET_STRUCTURE(payload));
}

export function updateStructureComponent(
  id: number,
  key: any,
  value: any,
  isEditor?: boolean
) {
  if (!isEditor) {
    store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  }

  return store.dispatch(
    UPDATE_STRUCTURE_COMPONENT({
      id,
      key,
      value,
    })
  );
}

export function updateComponentStyle(id: number, style: CSSProperties) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));

  return store.dispatch(
    UPDATE_COMPONENT_STYLE({
      id: id,
      style: style,
    })
  );
}

export function deleteComponentStyles(id: number, styles: string[]) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  return store.dispatch(
    DELETE_COMPONENT_STYLE({
      id: id,
      styles: styles,
    })
  );
}

export function addNewComponent(payload: AllComponents) {
  if (payload.type !== "editor") {
    store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  }

  return store.dispatch(ADD_NEW_COMPONENT(payload));
}
export function addNewComponents(payload: StructureMap["components"]) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  return store.dispatch(ADD_NEW_COMPONENTS(payload));
}

export function deleteComponentById(id: number, editor: Editor | null = null) {
  return store.dispatch(
    DELETE_COMPONENT({
      id,
      editor,
    })
  );
}

export function addChildToParent(
  parent_id: number,
  child_id: number,
  after: number = 0
) {
  return store.dispatch(
    ADD_CHILD_TO_PARENT({
      parent_id,
      child_id,
      after,
    })
  );
}

export function updateComponentStructureOrder(
  parent_id,
  draggedComponentId,
  destinationId,
  position,
  section
) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  return store.dispatch(
    UPDATE_STRUCTURE_COMPONENT_ORDER({
      parent_id,
      draggedComponentId,
      destinationId,
      position,
      section,
    })
  );
}

export function updateStructureWebpageComponentsOrder(
  draggedComponentId,
  destinationId,
  position
) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  return store.dispatch(
    UPDATE_STRUCTURE_WEBPAGECOMPONENTS_ORDER({
      draggedComponentId,
      destinationId,
      position,
    })
  );
}

export function removeChildFromParent(parent_id: number, child_id: number) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  return store.dispatch(
    REMOVE_CHILD_FROM_PARENT({
      parent_id,
      child_id,
    })
  );
}

export function updateComponentText(id: number, text: string) {
  store.dispatch(UPDATE_EDITOR_STATE({ hasEdited: true }));
  return store.dispatch(
    UPDATE_COMPONENT_TEXT({
      id,
      text,
    })
  );
}
