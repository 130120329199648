import React from "react";
import { useState, useEffect, useRef } from "react";
import Header from "../components/BuilderComponents/Header/Header";
import Sidebar from "../components/BuilderComponents/Sidebar/Sidebar";
import BuilderContent from "../components/BuilderComponents/BuilderContent/BuilderContent";
import { setActivePage } from "../store/reducers/builderReducers/pages/pagesActions";
import WebsiteAPI from "../store/reducers/builderReducers/website/websiteApi";
import TypographiesAPI from "../store/reducers/builderReducers/typographies/typographiesApi";

import ReusableComponentsAPI from "../store/reducers/builderReducers/reusableComponents/reusableComponentsApi";
import PagesAPI from "../store/reducers/builderReducers/pages/pagesApi";
import LanguageSettingsAPI from "../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import FontOptionsAPI from "../store/reducers/builderReducers/font_options/font_optionsApi";
import FontUrlAPI from "../store/reducers/builderReducers/font_url/font_urlApi";
import ColorAPI from "../store/reducers/builderReducers/color/colorApi";
import { updateEditor } from "../store/reducers/builderReducers/editor/editorActions";
import { useAppSelector } from "../store/hooks";
import { Pages } from "../store/reducers/builderReducers/pages/pagesApi";
import { inClass } from "../utils/Helpers";
import {
  addNewComponent,
  deleteComponentById,
  updateStructureComponent,
} from "../store/reducers/builderReducers/structure/structureActions";
import { setEditableComponentId } from "../store/reducers/textEditor/textEditorActions";
import TemplateApi from "../store/reducers/builderReducers/templates/templateApi";
import axios from "axios";
import { useEditor } from "@tiptap/react";
import { extensions } from "../components/_default/utilities/customContentTags";
import SaveConfirmation from "../components/BuilderComponents/Editors/Confirmation/SaveConfirmation";
import TemplateEditOverlay from "../components/BuilderComponents/Editors/TemplateEditOverlay/TemplateEditOverlay";

export const editorId = -11;

export default function Builder() {
  const [sidebarActive, toggleSidebar] = useState(true);
  const components = useAppSelector(
    (state) => state.builder.structure.components
  ) as any;
  const editorComponent = components[editorId]!;
  // const components = structure.components;
  const pages = useAppSelector((state) => state.builder.pages.list);
  const activePageId = useAppSelector(
    (state) => state.builder.pages.active as Pages["active"]
  );

  const builderEditor = useAppSelector((state) => state.builder.editor as any);

  const colors = useAppSelector((state) => state.builder.colors);
  const typographies = useAppSelector((state) => state.builder.typographies);
  const website = useAppSelector((state) => state.builder.website);

  // const fontUrl = useAppSelector((state) => state.builder.font_url.url)
  const prevActivePageId: any = useRef();
  const templates = useAppSelector((state) => state.builder.templates);

  const activeComponentId = useAppSelector(
    (state) => state.builder.editor.component_id
  );

  const [content, setContent] = useState("");

  const editor = useEditor({
    extensions,
    content,

    onUpdate({ editor }) {
      setContent(editor.getHTML());

      if (activeComponentId)
        updateStructureComponent(
          activeComponentId,
          "attributes.text",
          editor.getHTML(),
          true
        );
    },
  });

  // On first load
  useEffect(() => {
    PagesAPI.loadWebpages();
    TypographiesAPI.loadTypographies();
    FontUrlAPI.getFontUrl();
    FontOptionsAPI.getFontOptions();
    ColorAPI.loadColors();
    WebsiteAPI.loadWebsite();
    LanguageSettingsAPI.loadLanguageSettings();
    ReusableComponentsAPI.loadReusableComponents();
    if (!templates.size) {
      TemplateApi.loadTemplates();
    }
  }, []);

  useEffect(() => {
    if (activeComponentId) {
      if (
        components[activeComponentId]?.type === "ContentComponent" ||
        components[activeComponentId]?.type === "Button" ||
        components[activeComponentId]?.type === "nav-item" ||
        components[activeComponentId]?.type === "text"
      ) {
        const content = components[activeComponentId]?.attributes?.text;

        // Check if content contains <font> and if it does, the the attributes

        if (content) {
          const htmlString = components[activeComponentId]?.attributes?.text;
          // Create a new DOM parser
          // Create a new DOM parser
          const parser = new DOMParser();

          // Parse the HTML string
          const doc = parser.parseFromString(htmlString, "text/html");

          // Find all font elements
          const fontElements = doc.querySelectorAll("font");

          // Iterate over each font element
          fontElements.forEach((fontElement) => {
            // Create a new <p> element
            const spanElement = document.createElement("span");
            // Copy the innerHTML of the font element to the <p> element
            spanElement.innerHTML = fontElement.innerHTML;

            // Preserve style attribute of the font element
            const styleAttribute = fontElement.getAttribute("style");
            if (styleAttribute !== null) {
              spanElement.setAttribute("style", styleAttribute);
            }

            const colorAttribute = fontElement.getAttribute("color");

            if (colorAttribute) {
              let spanStyle = spanElement.getAttribute("style") || "";

              if (!spanStyle.includes("color")) {
                // Add color property to style
                spanStyle += `color: ${colorAttribute}; `;
              } else {
                // Update existing color property
                spanStyle = spanStyle.replace(
                  /color:[^;]+;/,
                  `color: ${colorAttribute};`
                );
              }
              spanElement.setAttribute("style", spanStyle);
            }

            const parentNode = fontElement.parentNode;

            if (parentNode !== null) {
              parentNode.replaceChild(spanElement, fontElement);
            }
          });

          if (components[activeComponentId]?.type === "Button") {
            const brElements = doc.querySelectorAll("br");
            brElements.forEach((brElement) => {
              brElement.parentNode?.removeChild(brElement);
            });
          }

          const modifedHtml = doc.body.innerHTML;

          setContent(modifedHtml);

          editor?.commands.setContent(modifedHtml);
        }
      }
    }
  }, [activeComponentId]);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    let clickedElement = e.target as HTMLElement;
    let clickedElementId = parseInt(clickedElement.id, 10);

    if (
      editorComponent &&
      clickedElement.classList.contains("builder-component--active")
    ) {
      deleteComponentById(editorId);
      setEditableComponentId(null);
    }

    const builderComponent = inClass("builder-component", clickedElement);

    if (builderComponent) {
      let parentComponentId = inClass(
        "builder-component",
        clickedElement.parentElement!
      );

      const builderElementComponent = components[builderComponent.id];

      if (
        builderElementComponent.type === "ContentComponent" ||
        builderElementComponent.type === "Button" ||
        builderElementComponent.type === "nav-item" ||
        builderElementComponent.type === "text"
      ) {
        const htmlString = components[builderComponent.id]?.attributes?.text;
        // Create a new DOM parser
        // Create a new DOM parser
        const parser = new DOMParser();

        // Parse the HTML string
        const doc = parser.parseFromString(htmlString, "text/html");

        // Find all font elements
        const fontElements = doc.querySelectorAll("font");

        // Iterate over each font element
        fontElements.forEach((fontElement) => {
          // Create a new <p> element
          const spanElement = document.createElement("span");
          // Copy the innerHTML of the font element to the <p> element
          spanElement.innerHTML = fontElement.innerHTML;

          // Preserve style attribute of the font element
          const styleAttribute = fontElement.getAttribute("style");
          if (styleAttribute !== null) {
            spanElement.setAttribute("style", styleAttribute);
          }

          const colorAttribute = fontElement.getAttribute("color");

          if (colorAttribute) {
            let spanStyle = spanElement.getAttribute("style") || "";

            if (!spanStyle.includes("color")) {
              // Add color property to style
              spanStyle += `color: ${colorAttribute}; `;
            } else {
              // Update existing color property
              spanStyle = spanStyle.replace(
                /color:[^;]+;/,
                `color: ${colorAttribute};`
              );
            }
            spanElement.setAttribute("style", spanStyle);
          }

          const parentNode = fontElement.parentNode;

          if (parentNode !== null) {
            parentNode.replaceChild(spanElement, fontElement);
          }
        });

        const modifedHtml = doc.body.innerHTML;

        setContent(modifedHtml);

        //editor?.commands.setContent(modifedHtml);
      }

      updateEditor({
        hasEdited: builderEditor.hasEdited,
        component_id: parseInt(builderComponent.id, 10),
        component_parent_id: builderElementComponent.parent_id
          ? builderElementComponent.parent_id
          : null,
        template:
          builderEditor && builderEditor.template
            ? builderEditor.template
            : false,
        editor: editor,
      });
    }

    if (builderComponent && !Number.isNaN(clickedElementId)) {
      let parentComponentId = inClass(
        "builder-component",
        clickedElement.parentElement!
      );

      const builderElementComponent = components[builderComponent.id];

      if (
        builderElementComponent.type === "ContentComponent" ||
        builderElementComponent.type === "Button" ||
        builderElementComponent.type === "nav-item" ||
        builderElementComponent.type === "text"
      ) {
        const htmlString = components[builderComponent.id]?.attributes?.text;
        // Create a new DOM parser
        // Create a new DOM parser
        const parser = new DOMParser();

        // Parse the HTML string
        const doc = parser.parseFromString(htmlString, "text/html");

        // Find all font elements
        const fontElements = doc.querySelectorAll("font");

        // Iterate over each font element
        fontElements.forEach((fontElement) => {
          // Create a new <p> element
          const spanElement = document.createElement("span");
          // Copy the innerHTML of the font element to the <p> element
          spanElement.innerHTML = fontElement.innerHTML;

          // Preserve style attribute of the font element
          const styleAttribute = fontElement.getAttribute("style");
          if (styleAttribute !== null) {
            spanElement.setAttribute("style", styleAttribute);
          }

          const colorAttribute = fontElement.getAttribute("color");

          if (colorAttribute) {
            let spanStyle = spanElement.getAttribute("style") || "";

            if (!spanStyle.includes("color")) {
              // Add color property to style
              spanStyle += `color: ${colorAttribute}; `;
            } else {
              // Update existing color property
              spanStyle = spanStyle.replace(
                /color:[^;]+;/,
                `color: ${colorAttribute};`
              );
            }
            spanElement.setAttribute("style", spanStyle);
          }

          const parentNode = fontElement.parentNode;

          if (parentNode !== null) {
            parentNode.replaceChild(spanElement, fontElement);
          }
        });

        const modifedHtml = doc.body.innerHTML;

        setContent(modifedHtml);

        //editor?.commands.setContent(modifedHtml);
      }

      updateEditor({
        hasEdited: builderEditor.hasEdited,
        component_id: parseInt(builderComponent.id, 10),
        component_parent_id: parentComponentId?.id
          ? parseInt(parentComponentId?.id!)
          : null,
        template:
          builderEditor && builderEditor.template
            ? builderEditor.template
            : false,
        editor: editor,
      });

      if (
        builderComponent.getAttribute("data-component-type") ===
        "ContentComponent"
      ) {
        console.log("INITIALISE EDITOR");
      }
    }

    if (
      (!inClass("global-editor", clickedElement) && // is editor component
        !inClass("builder-component--active", clickedElement)) || // is builder component and not active
      (clickedElement.classList.contains("builder-component") &&
        !clickedElement.classList.contains("builder-component--active")) // is builder component and not active
    ) {
      let parentComponentId = inClass(
        "builder-component",
        clickedElement.parentElement!
      );

      // const editorComponent = structure.components[editorId]
      if (
        (inClass("builder-component", clickedElement) && clickedElementId) ||
        (inClass("builder-component", clickedElement) &&
          Number(parentComponentId?.id) !== null)
      ) {
        // if(document.getElementsByClassName('global-editor__menu').length){
        if (editorComponent) {
          deleteComponentById(editorId);
        }

        addNewComponent({
          id: editorId,
          uuid: null,
          type: "editor",
          sort_order: 1,
          parent_id: clickedElementId
            ? clickedElementId
            : Number(parentComponentId?.id),
          childElements: [],
          childWebpageComponentRelations: [],
          version: 0,
          webpage_id: pages[0].website_id,
          attributes: {
            content_width_size: 0,
            content_width_unit: "px",
            classes: "",
            styles: {},
            typography_id: 1,
          },
        });

        setEditableComponentId(
          clickedElementId ? clickedElementId : Number(parentComponentId?.id)
        );
      } else if (
        !inClass("react-select-container", clickedElement) &&
        !inClass("builder-dropdown", clickedElement)
      ) {
        unsetEditElementRef(e);
        if (editorComponent) {
          deleteComponentById(editorId);
        }
      }
    }
  }

  function unsetEditElementRef(e: React.MouseEvent<HTMLElement>) {
    const component = inClass("builder-component", e.target as HTMLElement);
    const modal = inClass("modal", e.target as HTMLElement);
    if (!component && !modal) {
      setEditableComponentId(null);
    }
  }

  // When pages are loaded, get current pages components
  useEffect(() => {
    if (pages && pages.length) {
      // if ( activePageId && activePageId !== prevActivePageId.current) {

      //   prevActivePageId.current = activePageId
      //   StructureAPI.loadWebpageComponents(activePageId)
      // } else if (!components.length) {

      //   StructureAPI.loadWebpageComponents(pages[0].id)
      //   prevActivePageId.current = pages[0].id
      //   setActivePage(pages[0].id)
      // }
      if (!activePageId) {
        setActivePage(pages[0].id);
      }
    }
  }, [pages]);

  useEffect(() => {
    if (colors) {
      const shopThemeColor = colors.find((x) => x.name === "SHOPTHEMECOLOR");

      if (shopThemeColor?.color) {
        document.documentElement.style.setProperty(
          "--shop-theme-color",
          shopThemeColor?.color
        );
      }
    }
  }, [colors]);

  useEffect(() => {
    if (colors) {
      const shopThemeColor = colors.find(
        (x) => x.name === "SHOPTHEMECOLORHOVER"
      );

      if (shopThemeColor?.color) {
        document.documentElement.style.setProperty(
          "--shop-theme-color-hover",
          shopThemeColor?.color
        );
      }
    }
  }, [colors]);

  useEffect(() => {
    if (website) {
      document.documentElement.style.setProperty(
        "--shop-border-radius",
        website.shop_b_radius ? website.shop_b_radius : "0px"
      );
    }
  }, [website]);

  useEffect(() => {
    if (typographies) {
      const fontFamily = typographies.filter((x) => x.tag === "p")[0];

      if (fontFamily?.font_family) {
        document.documentElement.style.setProperty(
          "--shop-font-family",
          fontFamily.font_family
        );
      }
    }
  }, [typographies]);

  return (
    <>
      <div
        className="d-flex flex-column h-100 w-100"
        onMouseDown={(e) => handleClick(e)}
      >
        <Header sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
        <div className="app-layout">
          {sidebarActive && <Sidebar />}
          <div className="app-content">
            <BuilderContent></BuilderContent>
            <SaveConfirmation />
          </div>
          <TemplateEditOverlay />
        </div>
      </div>
    </>
  );
}
