import Select from "react-select";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import React from "react";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import {
  HeaderComponent,
  NavigationContainerComponent,
} from "../../../../_default/interfaces/base";
import { useEffect, useState } from "react";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import { FormatColorFill } from "@mui/icons-material";
import Immutable, { Set, Map } from "immutable";
import { Template } from "../../../../../store/reducers/builderReducers/templates/templateApi";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function MenuMenu({ id, triggerResize }: Props) {
  console.log("MenuMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as NavigationContainerComponent;

  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [templateColorPickerOpen, setTemplateColorPickerOpen] = useState(false);
  const templates: Set<Map<string, any>> = Immutable.Set(
    useAppSelector((state) => state.builder.templates).map(
      (template: Template) => Immutable.Map(template)
    )
  );

  // Filter templates based on the "use" property equal to "megaMenu"
  const mobileMenuTemplates = templates.filter((template) => {
    if (template.get("use") === "mobileMenu")
      return {
        label: template.get("name"),
        value: template.get("name"),
      };
  });

  const mobileMenuTemplatesList: { label: any; value: any; id: any }[] =
    mobileMenuTemplates
      .toJS()
      .map((x) => {
        if (x.use === "mobileMenu") {
          return { label: x.name, value: x.name, id: x.id };
        }
        // Provide a default object when the condition is not met
        return { label: "", value: "", id: null };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  // Push default object to the beginning of the array of mobileMenuTemplatesList'
  mobileMenuTemplatesList.unshift({
    label: "Default",
    value: "default",
    id: null,
  });

  const hamburgerSizeUnits = [
    { label: "px", value: "px" },
    { label: "rem", value: "rem" },
  ];

  const gapSizeUnits = [{ label: "px", value: "px" }];

  const justifyContentOptions = [
    { label: "Start", value: "start" },
    { label: "Center", value: "center" },
    { label: "End", value: "end" },
  ];

  const templateAlignments = [
    { value: "left", label: "Left" },
    { value: "center", label: "Center" },
    { value: "right", label: "Right" },
  ];

  const blurUnits = [{ value: "px", label: "px" }];

  function onAlignmentChange(val: any) {
    updateStructureComponent(
      id,
      "attributes.components.nav.alignment",
      val.value
    );
  }

  function onDisplayChange(val: any) {
    updateStructureComponent(
      id,
      "attributes.components.nav.display",
      val.value === "none" ? false : true
    );
  }

  function onHamburgerSizeChange(val) {
    updateStructureComponent(
      id,
      "attributes.components.nav.hamburgerSize",
      val.value
    );
  }

  function onStickyChange(val: any) {
    // if (val.value === "none") {
    //   updateStructureComponent(id, "attributes.styles.backdropFilter", "none");
    // }

    updateStructureComponent(
      id,
      "attributes.styles.position",
      val.value === "none" ? "none" : "sticky"
    );

    updateStructureComponent(
      id,
      "attributes.styles.top",
      val.value === "none" ? "none" : "0px"
    );

    updateStructureComponent(
      id,
      "attributes.styles.zIndex",
      val.value === "none" ? 99 : 99
    );
  }

  function onBlurChange(val: any) {
    if (val.value === "none") {
      updateStructureComponent(id, "attributes.styles.backdropFilter", "none");
    }

    // if (component.attributes.styles.position !== "sticky") {
    //   updateStructureComponent(id, "attributes.styles.backdropFilter", "none");
    // } else {
    // }

    updateStructureComponent(
      id,
      "attributes.styles.zIndex",
      val.value === "none" ? 99 : 99
    );

    updateStructureComponent(
      id,
      "attributes.styles.backdropFilter",
      `blur(${val.value})`
    );
  }

  function extractNumbers(str: string | number) {
    if (str === "") {
      return 0;
    }

    if (str === 0) {
      return 0;
    }

    if (str === "0") {
      return 0;
    }

    if (str === "none") {
      return 0;
    }

    if (str === "small") {
      return 12;
    }

    if (str === "medium") {
      return 16;
    }

    if (str === "large") {
      return 20;
    }

    let numbers;

    if (typeof str === "string" && str.includes("blur")) {
      numbers = str.match(/blur\((-?\d+)px\)/);

      if (numbers[1] === null) {
        return 0;
      } else {
        return numbers[1];
      }
    } else if (typeof str === "string") {
      numbers = str.match(/^-?\d+/);

      if (numbers === null) {
        return 0;
      } else if (numbers[0] !== null) {
        return numbers[0];
      }
    }

    if (numbers) {
      return numbers[0];
    }

    return "";
  }

  function extractUnits(str: string) {
    if (str === "small") return "px";
    if (str === "medium") return "px";
    if (str === "large") return "px";

    let numbers = extractNumbers(str);

    return str.replace(numbers.toString(), "");
  }

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div
      className="d-flex"
      style={{
        gap: "10px",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: "550px",
      }}
    >
      {/* <Select
        onChange={onDisplayChange}
        isSearchable={false}
        className="m-0"
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={
          component.attributes.display
            ? NavLinks[0]
            : NavLinks[1]
        }
        options={NavLinks}
        placeholder=""
      ></Select> */}
      {/* <Select
        onChange={onAlignmentChange}
        isSearchable={false}
        className="m-0"
        styles={{
          ...TextEditorSelectStyle,
          container: (base) => ({
            ...base,
            width: "auto",
          }),
        }}
        noOptionsMessage={({}) => ""}
        value={alignments.find(
          (x) => x.value === component.attributes.components.nav.alignment
        )}
        options={alignments}
        placeholder=""
      ></Select> */}

      <>
        {/* <Select
          onChange={onBlurChange}
          isSearchable={false}
          className="m-0"
          styles={TextEditorSelectStyle}
          noOptionsMessage={({}) => ""}
          value={
            blurValues.find((x) => {
              return x.label === component.attributes.styles.backdropFilter;
            })
              ? blurValues.find((x) => {
                  return x.label === component.attributes.styles.backdropFilter;
                })
              : blurValues[0]
          }
          options={blurValues}
          placeholder=""
        /> */}
      </>

      <div
        style={{
          display: "flex",
          alignItems: "center",

          gap: "3px",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          M.color:
        </div>

        <FormatColorTextIcon
          style={{
            fontSize: "16px",
          }}
          onClick={() => setColorPickerOpen(!colorPickerOpen)}
        />

        <div className="color-picker">
          {" "}
          {colorPickerOpen && (
            <ColorPicker
              setColorPickerOpen={() => setColorPickerOpen(false)}
              title="Text"
              value={component.attributes.mobileNavColor}
              onChange={(val) =>
                updateStructureComponent(id, "attributes.mobileNavColor", val)
              }
            />
          )}
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
        M.size:
      </div>

      <div className="background-menu_input">
        <input
          type="number"
          style={{ maxWidth: "40px" }}
          className="global-editor__input builder-input"
          size={1}
          onChange={(e) => {
            updateStructureComponent(
              id,
              "attributes.hamburgerSize",
              extractNumbers(e.target.value) +
                hamburgerSizeUnits.find(
                  (x) =>
                    x.value ===
                    extractUnits(component.attributes.hamburgerSize ?? "12px")
                )?.value
            );
          }}
          value={extractNumbers(component.attributes.hamburgerSize ?? "12px")}
        ></input>
        <Select
          isSearchable={false}
          menuPortalTarget={document.getElementById("react-select-container")}
          className="m-0"
          styles={TextEditorSelectStyle}
          value={hamburgerSizeUnits.find((x) => {
            return (
              x.value ===
              extractUnits(component.attributes.hamburgerSize ?? "12px")
            );
          })}
          onChange={(val) => {
            updateStructureComponent(
              id,
              "attributes.hamburgerSize",
              extractNumbers(component.attributes.hamburgerSize ?? "12px") +
                val.value
            );
          }}
          options={hamburgerSizeUnits}
          placeholder=""
        ></Select>
      </div>

      <span>M.break:</span>

      <div className="background-menu_input">
        <input
          className="global-editor__input builder-input"
          size={4}
          style={{ width: "30px" }}
          type="number"
          onChange={(e) => {
            updateStructureComponent(
              id,
              "attributes.mediaBreakpoint",
              e.target.value
            );
          }}
          value={
            component.attributes.mediaBreakpoint
              ? component.attributes.mediaBreakpoint
              : 1000

            //   extractNumbers(
            //   component.attributes.components.nav.hamburgerSize ?? "12px"
            // )
          }
        ></input>
        <Select
          isSearchable={false}
          menuPortalTarget={document.getElementById("react-select-container")}
          className="m-0"
          styles={TextEditorSelectStyle}
          value={hamburgerSizeUnits[0]}
          onChange={() => {}}
          options={hamburgerSizeUnits.filter((x) => x.value === "px")}
          placeholder=""
        ></Select>
      </div>

      <span>M. menu template: </span>

      <div className="background-menu_input">
        <Select
          isSearchable={false}
          menuPortalTarget={document.getElementById("react-select-container")}
          className="m-0"
          styles={TextEditorSelectStyle}
          value={
            mobileMenuTemplatesList.find(
              (x) => x.id === component.attributes.mobileMenuTemplateId
            )
              ? mobileMenuTemplatesList.find(
                  (x) => x.id === component.attributes.mobileMenuTemplateId
                )
              : mobileMenuTemplatesList.find((x) => x.value === "default")
          }
          onChange={(e) => {
            updateStructureComponent(
              id,
              "attributes.mobileMenuTemplateId",
              e.id
            );
          }}
          options={mobileMenuTemplatesList}
          placeholder=""
        ></Select>
      </div>

      {component.attributes.mobileMenuTemplateId !== null && (
        <>
          <span>Template align: </span>

          <div className="background-menu_input">
            <Select
              isSearchable={false}
              menuPortalTarget={document.getElementById(
                "react-select-container"
              )}
              className="m-0"
              styles={TextEditorSelectStyle}
              value={
                templateAlignments.find((x) => {
                  return x.value === component.attributes.templateAlignContent;
                })
                  ? templateAlignments.find((x) => {
                      return (
                        x.value === component.attributes.templateAlignContent
                      );
                    })
                  : templateAlignments[1]
              }
              onChange={(e) => {
                updateStructureComponent(
                  id,
                  "attributes.templateAlignContent",
                  e.value
                );
              }}
              options={templateAlignments}
              placeholder=""
            ></Select>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
              Template bg color:
            </div>

            <FormatColorFill
              style={{
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={() =>
                setTemplateColorPickerOpen(!templateColorPickerOpen)
              }
            />

            <div className="color-picker">
              {templateColorPickerOpen && (
                <ColorPicker
                  returnType="hex"
                  setColorPickerOpen={() => setTemplateColorPickerOpen(false)}
                  title="Template background color"
                  value={component.attributes.templateBackgroundcolor}
                  onChange={(val) =>
                    updateStructureComponent(
                      id,
                      "attributes.templateBackgroundcolor",
                      val
                    )
                  }
                />
              )}
            </div>
          </div>

          <span>Justify content: </span>

          <div className="background-menu_input">
            <Select
              isSearchable={false}
              menuPortalTarget={document.getElementById(
                "react-select-container"
              )}
              className="m-0"
              styles={TextEditorSelectStyle}
              value={
                justifyContentOptions.find((x) => {
                  return x.value === component.attributes.justifyContent;
                })
                  ? justifyContentOptions.find((x) => {
                      return x.value === component.attributes.justifyContent;
                    })
                  : justifyContentOptions[0]

                // mobileMenuTemplatesList.find(
                //   (x) => x.id === component.attributes.mobileMenuTemplateId
                // )
                //   ? mobileMenuTemplatesList.find(
                //       (x) => x.id === component.attributes.mobileMenuTemplateId
                //     )
                //   : mobileMenuTemplatesList.find((x) => x.value === "default")
              }
              onChange={(e) => {
                updateStructureComponent(
                  id,
                  "attributes.justifyContent",
                  e.value
                );
              }}
              options={justifyContentOptions}
              placeholder=""
            ></Select>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            Gap:
          </div>

          <div className="background-menu_input">
            <input
              type="number"
              style={{ maxWidth: "40px" }}
              className="global-editor__input builder-input"
              size={1}
              onChange={(e) => {
                updateStructureComponent(
                  id,
                  "attributes.gapSize",
                  extractNumbers(e.target.value) +
                    gapSizeUnits.find(
                      (x) =>
                        x.value ===
                        extractUnits(component.attributes.gapSize ?? "10px")
                    )?.value
                );
              }}
              value={extractNumbers(component.attributes.gapSize ?? "10px")}
            ></input>
            <Select
              isSearchable={false}
              menuPortalTarget={document.getElementById(
                "react-select-container"
              )}
              className="m-0"
              styles={TextEditorSelectStyle}
              value={gapSizeUnits.find((x) => {
                return (
                  x.value ===
                  extractUnits(component.attributes.gapSize ?? "12px")
                );
              })}
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.gapSize",
                  extractNumbers(component.attributes.gapSize ?? "12px") +
                    val.value
                );
              }}
              options={gapSizeUnits}
              placeholder=""
            ></Select>
          </div>
        </>
      )}
    </div>
  );
}
