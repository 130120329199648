import {
  SET_EDITABLE_COMPONENT_ID,
  UPDATE_INNERHTML,
} from './textEditorReducer'

import store from "../../store"

export function setEditableComponentId(id: number | null) {
  return store.dispatch(
    SET_EDITABLE_COMPONENT_ID(id)
  )
}
export function updateInnerHTML(innerHTML: string) {
  return store.dispatch(
    UPDATE_INNERHTML(innerHTML)
  )
}
