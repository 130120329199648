import { Template } from "./templateApi";
import { Set } from "immutable";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: Set<Template> = Set([]);

export const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    UPDATE_TEMPLATES: (state: any, action: PayloadAction<Template[]>) => {
      if (!action.payload) return state;

      // console.log('UPDATE_TEMPLATE')
      state = Set(action.payload);
      return state;
    },
    ADD_TEMPLATES: (state: any, action: PayloadAction<Template>) => {
      return state.set("", [...state.get(""), action.payload]);
    },
    DELETE_TEMPLATE_BY_ID: (
      state: any,
      action: PayloadAction<{ id: number }>
    ) => {
      let index = state!.findIndex((x: any) => x.id === action.payload.id);
      return state.removeIn(["", index]);
    },
  },
});

// Action creators are generated for each case reducer function
export const { UPDATE_TEMPLATES, ADD_TEMPLATES, DELETE_TEMPLATE_BY_ID } =
  templateSlice.actions;

export default templateSlice.reducer;
