import { Record } from 'immutable'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface TextEditor {
  id: number | null
  innerHTML: string | null
}

const defaultValues: TextEditor = {
  id: null,
  innerHTML: null,
}

class State extends Record(defaultValues) {
  constructor(params: TextEditor) {
    super(params)
  }
}

const initialState: State = new State(defaultValues)

export const textEditorSlice = createSlice({
  name: 'textEditor',
  initialState,
  reducers: {
    SET_EDITABLE_COMPONENT_ID: (state: any, action: PayloadAction<any>) => {
      return state.set('id', action.payload)
    },
    UPDATE_INNERHTML: (state: any, action: PayloadAction<any>) => {
      return state.set('innerHTML', action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { SET_EDITABLE_COMPONENT_ID, UPDATE_INNERHTML } = textEditorSlice.actions

export default textEditorSlice.reducer

