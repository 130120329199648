import { inClass } from "../../../../../utils/Helpers";

export const handleDragStart = (e, ref, isText) => {
  const builderElement = inClass("builder-component--active", ref.current!);

  if (e.target.nodeName === "#text") {
    isText = true;
    return;
  }

  if (
    !inClass("builder-component", builderElement?.parentElement!) &&
    e.target.classList.contains("row")
  ) {
    e.target.setAttribute("data-drag-type", "main");
  } else {
    // Get the dragged element parent id
    e.dataTransfer.setData(
      "id",
      inClass("builder-component", builderElement?.parentElement!)!.id
    );
  }

  e.dataTransfer.effectAllowed = "move";
};
