import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import {
  ProductCategory,
  useShoppingCart,
} from "../../../../_default/context/ShoppingCartContext";
import {
  DefaultReactSelectStyle,
  TextEditorSelectStyle,
} from "../../../ReactSelectStyle/ReactSelectStyle";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../../store/hooks";
import { AddToPhotos, Delete, Edit } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./ProductSliderMenu.scss";

export const updatedTextEditorSelectStyle: StylesConfig<any, false> = {
  ...DefaultReactSelectStyle,
  menuList: (styles, data) => {
    return {
      ...styles,
      overflowX: "hidden",
    };
  },
  container: (styles, data) => {
    return {
      ...styles,
    };
  },
  singleValue: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.singleValue!(styles, data),
      overflow: "visible",
      color: "white",
    };
  },
  option: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.option!(styles, data),
      padding: "2px 7px",
      textAlign: "left",
    };
  },
  control: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.control!(styles, data),
      minHeight: "inherit",
      flexWrap: "nowrap",
    };
  },
  valueContainer: (styles, data) => {
    return {
      ...styles,
      padding: "0px",
      minWidth: "inherit",
      alignSelf: "flex-start",
      overflow: "hidden",
      color: "white",
    };
  },

  input: (styles) => {
    return {
      ...styles,
      color: "white",
    };
  },

  menu: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.menu!(styles, data),
      marginTop: "2px",
    };
  },
  dropdownIndicator: (styles, data) => {
    return {
      ...styles,
      height: "100%",
      padding: 0,
      alignSelf: "flex-start",
      alignItems: "center",
      width: "12px",
      color: "#fff",
      svg: {
        height: "12px",
      },
    };
  },
};
declare var API: any;
export default function ProducsSliderMenu({ id, triggerResize }) {
  const { locationProducts } = useShoppingCart();

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;

  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    []
  );

  const [cachedProductId, setCachedProductId] = useState(
    component.attributes.productId ? component.attributes.productId : []
  );

  const [cachedCategoryId, setCachedCategoryId] = useState(
    component.attributes.categoryId ? component.attributes.categoryId : []
  );

  const [selectedCategorySort, setSelectedCategorySort] = useState(
    component.attributes.categorySort
      ? component.attributes.categorySort
      : "a-z"
  );

  const [selectedCachedProductId, setSelectedCachedProductId] = useState<
    number | null
  >(null);
  const [selectedCachedCategoryId, setSelectedCachedCategoryId] = useState<
    number | null
  >(null);

  const [editProducts, setEditProducts] = useState(false);

  const [selectedSlideWidth, setSelectedSlideWidth] = useState(
    component.attributes.slideWidth ? component.attributes.slideWidth : 100
  );

  const [selectedSlideWidthUnit, setSelectedSlideWidthUnit] = useState(
    component.attributes.slideWidthUnit
      ? component.attributes.slideWidthUnit
      : "%"
  );

  const [selectedProductVariable, setSelectedProductVariable] = useState(
    component.attributes.productVariable
      ? component.attributes.productVariable
      : "category"
  );

  const [selectedAutoplayInterval, setSelectedAutoplayInterval] = useState(
    component.attributes.autoplayInterval
      ? component.attributes.autoplayInterval
      : 3000
  );

  const [selectedProductCategory, setSelectedProductCategory] = useState(
    component.attributes.productCategory
      ? component.attributes.productCategory
      : null
  );
  const [selectedWidthUnit, setSelectedWidthUnit] = useState(
    component.attributes.imageWidthUnit
      ? component.attributes.imageWidthUnit
      : "px"
  );

  const productVariableOptions = [
    {
      value: "category",
      label: "Category",
    },
    {
      value: "name",
      label: "Product",
    },
  ];

  let widthTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  const locationProductOptions = locationProducts
    .map((locationProduct) => {
      return {
        value: locationProduct.id,
        label: locationProduct.product.name,
        ...locationProduct,
      };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  let alignTypes = [
    {
      value: "start",
      label: "left",
    },
    {
      value: "center",
      label: "center",
    },
    {
      value: "end",
      label: "right",
    },
  ];

  let speedTypes = [
    {
      value: 0.25,
      label: "0.25",
    },

    {
      value: 0.5,
      label: "0.5",
    },
    {
      value: 0.75,
      label: "0.75",
    },

    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
  ];

  let autoPlayTypes = [
    {
      value: "autoscroll",
      label: "Autoscroll",
    },
    {
      value: "autoplay",
      label: "Autoplay",
    },
    {
      value: "none",
      label: "None",
    },
  ];

  function onDragEndCategory(result: any) {
    if (!result.destination) {
      return;
    }

    const reorder = (list: any, startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const items = reorder(
      cachedCategoryId,
      result.source.index,
      result.destination.index
    );

    setCachedCategoryId(items);

    updateStructureComponent(id, "attributes.categoryId", items);

    // const items = reorder(
    //   productId,
    //   result.source.index,
    //   result.destination.index
    // );

    // triggerResize();
  }

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const reorder = (list: any, startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const items = reorder(
      cachedProductId,
      result.source.index,
      result.destination.index
    );

    setCachedProductId(items);

    updateStructureComponent(id, "attributes.productId", items);

    // const items = reorder(
    //   productId,
    //   result.source.index,
    //   result.destination.index
    // );

    // triggerResize();
  }

  useEffect(() => {
    if (productCategories.length > 0) return;

    if (typeof API !== "undefined") {
      window
        // @ts-ignore
        .axios("/loadProductCategories", {
          method: "GET",
          cache: "no-cache",
          headers: { "Content-Type": "application/json" },
        })
        .then((res: any) => {
          setProductCategories(res.data);
        });
    } else {
      fetch("/webstoreApi/loadProductCategories", {
        method: "GET",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          setProductCategories(res);
        });
    }
  }, []);

  let productCategoryOptions = [] as any;

  let sortOptions = [
    {
      value: "a-z",
      label: "By name: A->Z",
    },
    {
      value: "z-a",
      label: "By name: Z->A",
    },
    {
      value: "cheapest",
      label: "Cheapest first",
    },
    {
      value: "expensive",
      label: "Expensive first",
    },
  ];

  const processChildren = (children, options) => {
    children.forEach((child) => {
      options.push({
        value: child.id,
        label: child.name,
      });
    });

    if (children.cashregisterChildren) {
      processChildren(children.cashregisterChildren, options);
    }
  };

  const processCategories = (categories) => {
    const options = [] as any;

    categories.forEach((category) => {
      options.push({
        value: category.id,
        label: category.name,
      });

      if (category.cashregisterChildren) {
        processChildren(category.cashregisterChildren, options);
      }
    });
    return options;
  };

  if (productCategories) {
    productCategoryOptions = processCategories(productCategories);
  }

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",

          marginBottom: "2px",
          marginTop: "2px",
        }}
      >
        <span
          onClick={() => {
            setEditProducts((prev) => !prev);
          }}
          style={{ marginRight: "5px", marginLeft: "5px" }}
          className="border-menu_input"
        >
          <AddToPhotos style={{ fontSize: "15px", marginRight: "5px" }} />
          Select products
        </span>

        <span style={{ marginRight: "5px" }}>Loop:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              component.attributes.hasOwnProperty("loop")
                ? component.attributes.loop
                : false
            }
            onChange={(e) => {
              updateStructureComponent(id, "attributes.loop", e.target.checked);
            }}
          />
          <span className="slider round"></span>
        </label>

        <span style={{ marginRight: "5px" }}>Thumbs:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              component.attributes.hasOwnProperty("thumbs")
                ? component.attributes.thumbs
                : false
            }
            onChange={(e) => {
              updateStructureComponent(
                id,
                "attributes.thumbs",
                e.target.checked
              );
            }}
          />
          <span className="slider round"></span>
        </label>

        <span style={{ marginRight: "5px" }}>Nav. Dots:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              component.attributes.hasOwnProperty("dots")
                ? component.attributes.dots
                : false
            }
            onChange={(e) => {
              updateStructureComponent(id, "attributes.dots", e.target.checked);
            }}
          />
          <span className="slider round"></span>
        </label>

        <>
          <span
            style={{
              marginRight: "5px",
              marginLeft: "0px",
            }}
          >
            Slide width:{" "}
          </span>

          <div style={{ display: "flex", gap: "5px" }}>
            <input
              className="global-editor__input builder-input spacing-menu_input"
              size={1}
              value={selectedSlideWidth}
              onChange={(ev) => {
                // if (ev.target.value === "" || ev.target.value === "0") {
                //   setSelectedWidth("px");
                //   return;
                // }

                // if (ev.target.value === "0") return;

                setSelectedSlideWidth(ev.target.value);

                updateStructureComponent(
                  id,
                  "attributes.slideWidth",
                  ev.target.value
                );

                updateStructureComponent(
                  id,
                  "attributes.slideWidthUnit",
                  selectedSlideWidthUnit ? selectedSlideWidthUnit : "px"
                );
              }}
            />

            <Select
              menuPosition="fixed"
              className="m-0 button-menu_input"
              isSearchable={false}
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={widthTypes.filter((x) => x.value === "%")[0]}
              onChange={(val) => {
                setSelectedWidthUnit(val.value);

                updateStructureComponent(
                  id,
                  "attributes.imageWidthUnit",
                  val.value
                );
              }}
              options={widthTypes.filter((x) => x.value === "%")}
              placeholder=""
            ></Select>
          </div>
        </>
        <span style={{ marginRight: "5px", marginLeft: "5px" }}>
          Slide align:{" "}
        </span>
        <div style={{ display: "flex", gap: "5px" }}>
          <Select
            menuPosition="fixed"
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              alignTypes.find(
                (x) => x.value === component.attributes.slideAlign
              )
                ? alignTypes.find(
                    (x) => x.value === component.attributes.slideAlign
                  )
                : alignTypes[1]
            }
            onChange={(val) => {
              updateStructureComponent(id, "attributes.slideAlign", val.value);
            }}
            options={alignTypes}
            placeholder=""
          ></Select>
        </div>
        <span style={{ marginRight: "5px", marginLeft: "5px" }}>Autoplay:</span>
        <div style={{ display: "flex", gap: "5px" }}>
          <Select
            menuPosition="fixed"
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              autoPlayTypes.find(
                (x) => x.value === component.attributes.autoplay
              )
                ? autoPlayTypes.find(
                    (x) => x.value === component.attributes.autoplay
                  )
                : autoPlayTypes.find((x) => x.value === "none")
            }
            onChange={(val) => {
              updateStructureComponent(id, "attributes.autoplay", val.value);
            }}
            options={autoPlayTypes}
            placeholder=""
          ></Select>
        </div>
        {component.attributes.autoplay === "autoscroll" && (
          <>
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Autoscroll speed:{" "}
            </span>

            <Select
              menuPosition="fixed"
              className="m-0 button-menu_input"
              isSearchable={false}
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={
                speedTypes.find(
                  (x) => x.value === component.attributes.autoscrollSpeed
                )
                  ? speedTypes.find(
                      (x) => x.value === component.attributes.autoscrollSpeed
                    )
                  : speedTypes.find((x) => x.value === 1)
              }
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.autoscrollSpeed",
                  val.value
                );
              }}
              options={speedTypes}
              placeholder=""
            ></Select>
          </>
        )}
        {component.attributes.autoplay === "autoplay" && (
          <>
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Autoplay interval ms:{" "}
            </span>{" "}
            <input
              className="global-editor__input builder-input spacing-menu_input"
              size={1}
              value={selectedAutoplayInterval}
              onChange={(ev) => {
                // if (ev.target.value === "" || ev.target.value === "0") {
                //   setSelectedWidth("px");
                //   return;
                // }

                if (ev.target.value === "0") return;

                setSelectedAutoplayInterval(ev.target.value);

                updateStructureComponent(
                  id,
                  "attributes.autoplayInterval",
                  ev.target.value
                );
              }}
            />
          </>
        )}
      </div>

      {editProducts && (
        <>
          <div className="productsSliderSelectMenu">
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", gap: "5px", marginBottom: "0px" }}
                >
                  <div
                    className="border-menu_input"
                    style={{ background: "#444", height: "20px" }}
                  >
                    <Select
                      menuPosition="fixed"
                      isSearchable={false}
                      styles={TextEditorSelectStyle}
                      noOptionsMessage={({}) => ""}
                      value={productVariableOptions.find(
                        (x) => x.value === selectedProductVariable
                      )}
                      onChange={(val) => {
                        // setSearchQuery(val.label);
                        setSelectedProductVariable(val.value);

                        updateStructureComponent(
                          id,
                          "attributes.productVariable",
                          val.value
                        );

                        // updateStructureComponent(id, "attributes.productId", val.value);
                      }}
                      options={productVariableOptions}
                      placeholder="Select a type "
                    ></Select>
                  </div>
                  {selectedProductVariable === "name" && (
                    <>
                      <div className="border-menu_input">
                        <Select
                          menuPosition="fixed"
                          isSearchable={true}
                          styles={updatedTextEditorSelectStyle}
                          noOptionsMessage={({}) => ""}
                          value={locationProductOptions.find(
                            (x) => x.value === selectedCachedProductId
                          )}
                          onChange={(val) => {
                            // setSearchQuery(val.label);

                            setSelectedCachedProductId(val.value);

                            // updateStructureComponent(id, "attributes.productId", val.value);
                          }}
                          options={locationProductOptions}
                          placeholder="Search product by name"
                        ></Select>
                      </div>

                      {selectedCachedProductId !== null && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();

                            const products = [...cachedProductId] as any;

                            if (products.includes(selectedCachedProductId)) {
                              return;
                            }

                            if (
                              selectedCachedProductId !== null &&
                              !products.includes(selectedCachedProductId)
                            ) {
                              products.push(selectedCachedProductId);
                            }

                            setCachedProductId(products);

                            updateStructureComponent(
                              id,
                              "attributes.productId",
                              products
                            );
                          }}
                          className="border-menu_input"
                          style={{
                            color: "white",
                            border: "none",
                            outline: "none",
                          }}
                        >
                          {" "}
                          + Add{" "}
                        </button>
                      )}
                    </>
                  )}
                </div>

                {selectedProductVariable === "name" && (
                  <div style={{ marginTop: "10px" }}>
                    {cachedProductId.length > 0 && (
                      <div>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {cachedProductId.map((x, i) => (
                                  <Draggable
                                    key={x.toString()}
                                    draggableId={x.toString()}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="b-row"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className="col-12 b-mb-2 d-flex align-items-center">
                                          <div
                                            className="d-flex flex-column position-relative"
                                            style={{
                                              width: "25px",
                                              height: "100%",
                                            }}
                                          >
                                            <ArrowDropUpIcon
                                              className="position-absolute"
                                              style={{ top: "-5px" }}
                                            />
                                            <ArrowDropDownIcon
                                              className="position-absolute"
                                              style={{ top: "5px" }}
                                            />
                                          </div>
                                          <span>
                                            {
                                              locationProducts?.find(
                                                (y) => y.id === x
                                              )?.product.name
                                            }
                                          </span>

                                          <span
                                            onClick={() => {
                                              const products = [
                                                ...cachedProductId,
                                              ] as any;
                                              products.splice(i, 1);
                                              setCachedProductId(products);
                                              updateStructureComponent(
                                                id,
                                                "attributes.productId",
                                                products
                                              );
                                            }}
                                          >
                                            <Delete
                                              style={{
                                                fontSize: "15px",
                                                marginLeft: "5px",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  {selectedProductVariable === "category" && (
                    <div className="border-menu_input">
                      <Select
                        menuPosition="fixed"
                        isSearchable={true}
                        styles={updatedTextEditorSelectStyle}
                        noOptionsMessage={({}) => ""}
                        value={productCategoryOptions.find(
                          (x) => x.value === selectedCachedCategoryId
                        )}
                        onChange={(val) => {
                          // setSearchQuery(val.label);

                          setSelectedCachedCategoryId(val.value);

                          // setSelectedProductCategory(val.value);
                          // updateStructureComponent(
                          //   id,
                          //   "attributes.productCategory",
                          //   val.value
                          // );
                        }}
                        options={productCategoryOptions}
                        placeholder="Select product category"
                      ></Select>
                    </div>
                  )}
                  {selectedProductVariable === "category" &&
                    selectedCachedCategoryId !== null && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();

                          const products = [...cachedCategoryId] as any;

                          if (products.includes(selectedCachedCategoryId)) {
                            return;
                          }

                          if (
                            selectedCachedCategoryId !== null &&
                            !products.includes(selectedCachedCategoryId)
                          ) {
                            products.push(selectedCachedCategoryId);
                          }

                          setCachedCategoryId(products);

                          updateStructureComponent(
                            id,
                            "attributes.categoryId",
                            products
                          );
                        }}
                        className="border-menu_input"
                        style={{
                          color: "white",
                          border: "none",
                          outline: "none",
                        }}
                      >
                        {" "}
                        + Add{" "}
                      </button>
                    )}

                  {selectedProductVariable === "category" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <label>Sort by:</label>
                      <div className="border-menu_input">
                        <Select
                          menuPosition="fixed"
                          isSearchable={true}
                          styles={updatedTextEditorSelectStyle}
                          noOptionsMessage={({}) => ""}
                          value={sortOptions.find(
                            (x) => x.value === selectedCategorySort
                          )}
                          onChange={(val) => {
                            // setSearchQuery(val.label);

                            setSelectedCategorySort(val.value);

                            updateStructureComponent(
                              id,
                              "attributes.categorySort",
                              val.value
                            );

                            // setSelectedProductCategory(val.value);
                            // updateStructureComponent(
                            //   id,
                            //   "attributes.productCategory",
                            //   val.value
                            // );
                          }}
                          options={sortOptions}
                          placeholder="Select sort by"
                        ></Select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {selectedProductVariable === "category" && (
              <div style={{ marginTop: "10px", marginLeft: "5px" }}>
                {cachedCategoryId.map((x, i) => (
                  <div key={i}>
                    <div className="b-row">
                      <div className="col-12 b-mb-2 d-flex align-items-center">
                        <span>
                          {
                            productCategoryOptions.find((y) => y.value === x)
                              ?.label
                          }
                        </span>

                        <span
                          onClick={() => {
                            const products = [...cachedCategoryId] as any;
                            products.splice(i, 1);
                            setCachedCategoryId(products);
                            updateStructureComponent(
                              id,
                              "attributes.categoryId",
                              products
                            );
                          }}
                        >
                          <Delete
                            style={{
                              fontSize: "15px",
                              marginLeft: "5px",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* {selectedProductVariable === "category" && (
              <>
                {cachedCategoryId.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    <DragDropContext onDragEnd={onDragEndCategory}>
                      <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {cachedCategoryId.map((x, i) => (
                              <Draggable
                                key={x.toString()}
                                draggableId={x.toString()}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="b-row"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="col-12 b-mb-2 d-flex align-items-center">
                                      <div
                                        className="d-flex flex-column position-relative"
                                        style={{
                                          width: "25px",
                                          height: "100%",
                                        }}
                                      >
                                        <ArrowDropUpIcon
                                          className="position-absolute"
                                          style={{ top: "-5px" }}
                                        />
                                        <ArrowDropDownIcon
                                          className="position-absolute"
                                          style={{ top: "5px" }}
                                        />
                                      </div>
                                      <span>
                                        {
                                          productCategoryOptions.find(
                                            (y) => y.value === x
                                          )?.label
                                        }
                                      </span>

                                      <span
                                        onClick={() => {
                                          const products = [
                                            ...cachedCategoryId,
                                          ] as any;
                                          products.splice(i, 1);
                                          setCachedCategoryId(products);
                                          updateStructureComponent(
                                            id,
                                            "attributes.categoryId",
                                            products
                                          );
                                        }}
                                      >
                                        <Delete
                                          style={{
                                            fontSize: "15px",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </>
            )} */}
          </div>
        </>
      )}
    </div>
  );
}
