import { useEffect, useState } from "react";
import FileExplorer from "../BackgroundMenu/FileExplorer/FileExplorer";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import { Document } from "../../../../../store/reducers/builderReducers/documents/documentsApi";
import { LogoComponent } from "../../../../_default/interfaces/base";
import React from "react";

import "./LogoBgMenu.scss";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function LogoBgMenu({ id, triggerResize }: Props) {
  console.log("LogoBgMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as LogoComponent;
  const [fileExplorerOpen, setFileExplorerOpen] = useState(false);
  const [type, setType] = useState<any>(null);

  const desktopImageTypes = [
    { label: "Desktop", value: true },
    { label: "None", value: false },
  ];

  const mobileImageTypes = [
    { label: "Mobile", value: true },
    { label: "None", value: false },
  ];

  useEffect(() => {
    triggerResize();
  }, [component]);

  function extractUrl(url: string) {
    // let url = str.match(
    //   /(?:(?:http?|https|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi
    // )![0]

    if (url.startsWith("url(")) {
      url = url.substring(4, url.length - 1);
    }
    if (url.startsWith('"')) {
      return url.substring(1, url.length - 1);
    }
    if (url.startsWith("'")) {
      return url.substring(1, url.length - 1);
    }
    return url;
  }

  function getSelectedBackgroundSize(size: string) {
    if (size.includes("px") && size.includes("auto")) {
      return "px auto";
    } else if ((size.match(/px/g) || []).length == 2) {
      return "px px";
    } else if (size.includes("%") && size.includes("auto")) {
      return "% auto";
    } else if ((size.match(/%/g) || []).length == 2) {
      return "% %";
    } else {
      return size;
    }
  }

  function setPropByPath(path: any, val: any) {
    updateStructureComponent(id, path, val);
  }

  function onBgSelect(item: Document) {
    setPropByPath(
      "attributes.logo." + type + ".styles.backgroundImage",
      'url("' + item.url + '")'
    );
  }

  function renderBackgroundSizeOption(key: any, value: any, size: string) {
    const selectedSize = getSelectedBackgroundSize(value);
    if (selectedSize !== size) return size;

    if (selectedSize == "px px") {
      let pxSizes = value.match(/\-?\d*\.?\d+/g);
      if (!pxSizes || pxSizes.length != 2) {
        pxSizes = [14, 14];
      }
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <span>H:</span>
            <div className="border-menu_input">
              <span
                style={{ fontSize: "14px" }}
                onClick={() =>
                  setPropByPath(
                    "attributes.logo." + key + ".styles.backgroundSize",
                    Number(pxSizes[0] ?? 14) -
                      1 +
                      "px " +
                      Number(pxSizes[1] ?? 14) +
                      "px"
                  )
                }
              >
                −{" "}
              </span>{" "}
              <input
                className="global-editor__input builder-input"
                size={1}
                type="number"
                style={{
                  width: "40px",
                }}
                value={pxSizes[0] ?? 14}
                onChange={(ev) => {
                  if (isNaN(ev.target.value as any) || ev.target.value == "") {
                    setPropByPath(
                      "attributes.logo." + key + ".styles.backgroundSize",
                      0 + "px " + Number(pxSizes[1] ?? 14) + "px"
                    );

                    return;
                  }
                  setPropByPath(
                    "attributes.logo." + key + ".styles.backgroundSize",
                    ev.target.value + "px " + Number(pxSizes[1] ?? 14) + "px"
                  );
                }}
              />
              <span
                style={{ fontSize: "14px" }}
                onClick={() =>
                  setPropByPath(
                    "attributes.logo." + key + ".styles.backgroundSize",
                    Number(pxSizes[0] ?? 14) +
                      1 +
                      "px " +
                      Number(pxSizes[1] ?? 14) +
                      "px"
                  )
                }
              >
                {" "}
                +{" "}
              </span>
            </div>
          </div>

          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <span>W:</span>
            <div className="border-menu_input">
              <span
                style={{ fontSize: "14px" }}
                onClick={() =>
                  setPropByPath(
                    "attributes.logo." + key + ".styles.backgroundSize",
                    Number(pxSizes[0] ?? 14) +
                      "px " +
                      (Number(pxSizes[1] ?? 14) - 1) +
                      "px"
                  )
                }
              >
                {" "}
                −{" "}
              </span>{" "}
              <input
                className="global-editor__input builder-input"
                size={1}
                type="number"
                value={pxSizes[1] ?? 14}
                style={{
                  width: "40px",
                }}
                onChange={(ev) => {
                  if (isNaN(ev.target.value as any) || ev.target.value == "") {
                    setPropByPath(
                      "attributes.logo." + key + ".styles.backgroundSize",
                      Number(pxSizes[0] ?? 14) + "px " + 0 + "px"
                    );

                    return;
                  }
                  setPropByPath(
                    "attributes.logo." + key + ".styles.backgroundSize",
                    Number(pxSizes[0] ?? 14) + "px " + ev.target.value + "px"
                  );
                }}
              />
              <span
                style={{ fontSize: "14px" }}
                onClick={() =>
                  setPropByPath(
                    "attributes.logo." + key + ".styles.backgroundSize",
                    Number(pxSizes[0] ?? 14) +
                      "px " +
                      (Number(pxSizes[1] ?? 14) + 1) +
                      "px"
                  )
                }
              >
                {" "}
                +{" "}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="d-flex flex-wrap align-items-center">
        {fileExplorerOpen && (
          <FileExplorer
            setFileExplorerOpen={setFileExplorerOpen}
            onSelect={onBgSelect}
            id={id}
          />
        )}
      </div>

      {!component.attributes.logo.Desktop.active && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginTop: "3px",
            marginLeft: "3px",
          }}
        >
          <span style={{ width: "55px" }}>Desktop:</span>
          <Select
            className="logo-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              desktopImageTypes[
                component.attributes.logo.Desktop.active ? 0 : 1
              ]
            }
            onChange={(val) => {
              setPropByPath("attributes.logo.Desktop.active", val!.value);
            }}
            options={desktopImageTypes}
            placeholder=""
          ></Select>
        </div>
      )}

      {!component.attributes.logo.Mobile.active && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginTop: "3px",
            marginLeft: "3px",
          }}
        >
          <span style={{ width: "55px" }}>Mobile:</span>
          <Select
            className="logo-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              mobileImageTypes[component.attributes.logo.Mobile.active ? 0 : 1]
            }
            onChange={(val) => {
              setPropByPath("attributes.logo.Mobile.active", val!.value);
            }}
            options={mobileImageTypes}
            placeholder=""
          ></Select>
        </div>
      )}

      {Object.entries(component.attributes.logo).map(
        ([key, value], index) =>
          value.active && (
            <div className=" align-items-start b-mb-1" key={key}>
              <div
                className="background-item "
                style={{
                  display: "flex",

                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "3px",
                }}
              >
                <img
                  src={extractUrl(value.styles.backgroundImage!)}
                  onClick={() => {
                    setType(key);
                    setFileExplorerOpen(true);
                  }}
                ></img>

                <Select
                  className="logo-menu_input"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  noOptionsMessage={({}) => ""}
                  value={
                    key === "Mobile"
                      ? mobileImageTypes[
                          component.attributes.logo.Mobile.active ? 0 : 1
                        ]
                      : desktopImageTypes[
                          component.attributes.logo.Desktop.active ? 0 : 1
                        ]
                  }
                  onChange={(val) => {
                    if (key === "Mobile") {
                      setPropByPath(
                        "attributes.logo.Mobile.active",
                        val!.value
                      );
                    }

                    if (key === "Desktop") {
                      setPropByPath(
                        "attributes.logo.Desktop.active",
                        val!.value
                      );
                    }
                  }}
                  options={
                    key === "Mobile" ? mobileImageTypes : desktopImageTypes
                  }
                  placeholder=""
                ></Select>
              </div>
              <div className="d-flex w-100">
                <div
                  className="flex-row flex-wrap"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1fr)",
                    gap: "5px",
                  }}
                >
                  {/* <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() => {
                        setIsDropdownOpen({
                          open: !isDropdownOpen.open,
                          type: key,
                        });
                      }}
                    >
                      {
                        repeats.find(
                          (x) => x.value === value.styles.backgroundRepeat
                        )!.label
                      }
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownOpen.open && isDropdownOpen.type === key && (
                      <ul className="logo-menu-dropdown">
                        {repeats.map((x) => (
                          <li
                            onClick={() =>
                              setPropByPath(
                                "attributes.logo." +
                                  key +
                                  ".styles.backgroundRepeat",
                                x.value
                              )
                            }
                            key={x.value}
                          >
                            <span
                              className={
                                "dropdown-item builder-dropdown__item" +
                                (x.value === value.styles.backgroundRepeat
                                  ? " builder-dropdown__item--active"
                                  : "")
                              }
                            >
                              {x.label}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div> */}

                  <div className="background-item-option">
                    {renderBackgroundSizeOption(
                      key,
                      value.styles.backgroundSize,
                      "px px"
                    )}
                  </div>

                  {/* <div
                    className="background-item-option"
                    style={{ width: "100px" }}
                  >
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownPositionOpen({
                          open: !isDropdownPositionOpen.open,
                          type: key,
                        })
                      }
                    >
                      {
                        positions.find(
                          (x) => x.value === value.styles.backgroundPosition
                        )!.label
                      }
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownPositionOpen.open &&
                      isDropdownPositionOpen.type === key && (
                        <ul className="logo-menu-dropdown">
                          <div className="d-grid background-grid-container">
                            {positions.map((x) => (
                              <div
                                key={x.value}
                                className={
                                  "background-grid-item" +
                                  (value.styles.backgroundPosition === x.value
                                    ? " background-grid-item--active"
                                    : "")
                                }
                                onClick={() => {
                                  setPropByPath(
                                    "attributes.logo." +
                                      key +
                                      ".styles.backgroundPosition",
                                    x.value
                                  );
                                }}
                              >
                                {x.label}
                              </div>
                            ))}
                          </div>
                        </ul>
                      )}
                  </div> */}

                  {/* <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownSizeOpen({
                          open: !isDropdownSizeOpen.open,
                          type: key,
                        })
                      }
                    >
                      {value.styles.backgroundSize}
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownSizeOpen.open &&
                      isDropdownSizeOpen.type === key && (
                        <ul className="logo-menu-dropdown-size">
                          {sizes.map((x) => (
                            <li
                              key={x}
                              onClick={() => {
                                if (
                                  getSelectedBackgroundSize(
                                    value.styles.backgroundSize!.toString()
                                  ) != x
                                ) {
                                  setPropByPath(
                                    "attributes.logo." +
                                      key +
                                      ".styles.backgroundSize",
                                    x
                                  );
                                }
                              }}
                            >
                              <span
                                className={
                                  "dropdown-item " +
                                  (getSelectedBackgroundSize(
                                    value.styles.backgroundSize!.toString()
                                  ) === x
                                    ? ""
                                    : "")
                                }
                              >
                                {renderBackgroundSizeOption(
                                  key,
                                  value.styles.backgroundSize,
                                  x
                                )}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                  </div> */}
                  {/* <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownOriginOpen({
                          open: !isDropdownOriginOpen.open,
                          type: key,
                        })
                      }
                    >
                      {origins.find((x) => x === value.styles.backgroundOrigin)}
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownOriginOpen.open &&
                      isDropdownOriginOpen.type === key && (
                        <ul className="logo-menu-dropdown">
                          {origins.map((x) => (
                            <li
                              onClick={() =>
                                setPropByPath(
                                  "attributes.logo." +
                                    key +
                                    ".styles.backgroundOrigin",
                                  x
                                )
                              }
                              key={x}
                            >
                              <span
                                className={
                                  "dropdown-item builder-dropdown__item" +
                                  (x === value.styles.backgroundOrigin
                                    ? " builder-dropdown__item--active"
                                    : "")
                                }
                              >
                                {x}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                  </div> */}
                  {/* <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownAttachmentOpen({
                          open: !isDropdownAttachmentOpen.open,
                          type: key,
                        })
                      }
                    >
                      {
                        attachments.find(
                          (x) => x.value === value.styles.backgroundAttachment
                        )!.label
                      }
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownAttachmentOpen.open &&
                      isDropdownAttachmentOpen.type === key && (
                        <ul className="logo-menu-dropdown">
                          {attachments.map((x) => (
                            <li
                              onClick={() =>
                                setPropByPath(
                                  "attributes.logo." +
                                    key +
                                    ".styles.backgroundAttachment",
                                  x.value
                                )
                              }
                              key={x.value}
                            >
                              <span
                                className={
                                  "dropdown-item builder-dropdown__item" +
                                  (x.value === value.styles.backgroundAttachment
                                    ? " builder-dropdown__item--active"
                                    : "")
                                }
                              >
                                {x.label}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                  </div> */}
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
}
