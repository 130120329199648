import { handleDragOverPos } from "./handleDragOverPos";

export const handleDragLeavePos = (e, ref) => {
  // Remove styles & classes from target when leave
  e.target?.classList.remove("dragover");
  e.target.style.webkitBoxShadow = ")";
  e.target.style.mozBoxShadow = "";

  e.target.style.boxShadow = "";
  e.target.transition = `none`;

  if (e.target.offsetParent) {
    e.target.offsetParent.style.webkitBoxShadow = "";
    e.target.offsetParent.style.mozBoxShadow = "";
    e.target.offsetParent.style.boxShadow = "";
    e.target.offsetParent.transition = `none`;
  }

  if (
    e.target.getAttribute("data-component-type") === "image" &&
    e.target.firstChild &&
    e.target.firstChild.classList.contains("builder-image--loaded")
  ) {
    e.target.firstChild.style.webkitBoxShadow = "";
    e.target.firstChild.style.mozBoxShadow = "";
    e.target.firstChild.style.boxShadow = "";
    e.target.firstChild.transition = `none`;
  }

  e.target.removeEventListener("dragover", handleDragOverPos);
};
