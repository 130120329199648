import { inClass } from "../../../../../utils/Helpers";

export const handleDragEnd = (e, ref) => {
  const builderElement = inClass("builder-component--active", ref.current!);
  builderElement?.setAttribute("draggable", "false");
  let dropTargets = Array.from(document.querySelectorAll(".builder-component"));
  dropTargets.forEach((dropTarget) => {
    dropTarget.classList.remove("dragover");
  });
};
