import store from "../../../store"
import { LanguageSetting } from './languageSettingsApi'
import {
    UPDATE_LANGUAGE_SETTINGS,
} from './languageSettingsReducer'

export function updateLanguageSettings(payload: LanguageSetting) {
    return store.dispatch(
      UPDATE_LANGUAGE_SETTINGS(payload)
    )
  }