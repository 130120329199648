import { useEffect, useLayoutEffect, useRef, useState } from "react";
import React from "react";
export default function Confirmation({
  onConfirm,
  onCancel,
  text = "Are you sure you want to remove this?",
  confirmText = "Remove",
  isLoading,
}: {
  id?: string;
  onConfirm: () => void;
  onCancel: () => void;
  text: string;
  confirmText?: string;
  isLoading?: boolean;
}) {
  const ref = useRef<any>(null);
  const [left, setLeft] = useState<number>(0);
  const [pos, setPos] = useState<DOMRect>();

  useLayoutEffect(() => {
    const resizeListener = () => {
      setPos(
        document
          .querySelector(".editor-confirmation__container")!
          .getBoundingClientRect()
      );
    };
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  });

  // Set pos on initial load
  useEffect(() => {
    setPos(
      document
        .querySelector(".editor-confirmation__container")!
        .getBoundingClientRect()
    );
  }, []);

  return (
    <div
      className="editor-confirmation editor-confirmation__container text-center"
      ref={ref}
      style={{ left: `${left}px` }}
    >
      <div className="editor-confirmation editor-confirmation__overlay"></div>
      <div className="editor-confirmation editor-confirmation__form">
        <div className="b-row">
          <div
            className="col-12 b-fs-6 b-mb-3"
            style={{ fontFamily: "sans-serif", fontWeight: "normal" }}
          >
            {text}
          </div>
          <div className="col-6">
            <button
              className="b-btn btn-primary w-100"
              style={{
                background: "#444",
                border: "none",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button
              className="b-btn btn-danger w-100"
              onClick={onConfirm}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "sans-serif",
                fontWeight: "normal",
              }}
            >
              <span>{confirmText}</span>

              {isLoading && (
                <span
                  style={{ marginLeft: "5px" }}
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
