import { useEffect, useState } from "react";
import { RgbaStringColorPicker } from "react-colorful";
import Select from "react-select";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { colord } from "colord";
import { TextEditorColorPickerSelectStyle } from "../../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../../store/hooks";
import ColorAPI from "../../../../../../store/reducers/builderReducers/color/colorApi";
import React from "react";

export default function ColorPicker({
  value = "#fff",
  title = "untitled",
  onChange,
  color_type,
  setColorPickerOpen,
  returnType = "rgb",
  align = "left",
  open = false,
  top = 0,
}: {
  value: string;
  title: string;
  color_type?: "background" | "background_hover" | "border";
  onChange: (e: any, id?: number) => void;
  setColorPickerOpen: (val) => void;
  returnType?: "rgb" | "hex";
  id?: number;
  align?: string;
  open?: boolean;
  top?: number;
}) {
  const builderElements = useAppSelector((state) => state.builder);

  const type = color_type ? color_type : "background";
  const colors = useAppSelector((state) => state.builder.colors);

  const component = useAppSelector(
    (state) =>
      state.builder.structure.components[builderElements.editor.component_id!]
  );

  let componentColorId;

  if (type === "background") {
    componentColorId = component.attributes.color_id;
  } else if (type === "background_hover") {
    componentColorId = component.attributes.hover_color_id;
  } else if (type === "border") {
    componentColorId = component.attributes.border_color_id;
  }

  if (!colors) {
    ColorAPI.loadColors();
  }

  const [selectedColorId, setSelectedColorId] = useState<null | number>(
    componentColorId ? componentColorId : null
  );
  const [saveFormActive, setSaveFormActive] = useState(false);
  const [colorName, setColorName] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const formats = [
    { value: "hex", label: "Hex" },
    { value: "rgb", label: "RGB" },
    { value: "hsl", label: "HSL" },
    { value: "hwb", label: "HWB" },
  ];
  const [format, setFormat] = useState<(typeof formats)[0] | null>(formats[0]);

  function handleReset() {
    if (title === "Background") {
      onChange(colord("#fff").toRgbString());
      return;
    }

    const componentID = builderElements.editor.component_id!;

    const typograpgyID =
      builderElements.structure.components[componentID].attributes
        .typography_id;

    const colorIDToFind = builderElements.typographies.find(
      (item) => item.id === typograpgyID
    )?.color_id;

    // @ts-ignore

    const colors = [...builderElements.colors];

    const correctColor = colors.find((x) => x.id === colorIDToFind);

    if (!correctColor) {
      return;
    }

    if (correctColor) {
      onChange(colord(correctColor.color).toRgbString());
    }
  }

  // Close color picker

  //Add event listener, that detects, if the click is outside of the "#color-picker-container-main" div, then close it

  useEffect(() => {
    console.log("THIS RAN COLOR PICKER");

    const handleClose = (event: any) => {
      console.log("handleClose");

      const colorPickerContainer = document.getElementById(
        "color-picker-container-main"
      );

      console.log(colorPickerContainer, event.target);

      if (
        colorPickerContainer &&
        !colorPickerContainer.contains(event.target) &&
        open === true &&
        !event.target.classList.contains("color-picker")
      ) {
        setColorPickerOpen("");
      }

      // setColorPickerOpen(false);
    };

    document.addEventListener("click", handleClose);

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  // Add new color
  function handleSubmit() {
    ColorAPI.addColor({
      color: colord(value).toRgbString(),
      name: colorName,
    }).then((x: any) => {
      setSaveFormActive(false);
      setColorName("");
    });
  }

  return (
    <div
      id="color-picker-container-main"
      style={{
        color: "#fff",
        position: "absolute",
        zIndex: "99999",
        top: top ? `${top}px` : 0,
        left: align === "left" ? "0" : "unset",
        right: align === "left" ? "unset" : "0",
      }}
      className="color-picker-position"
    >
      {saveFormActive && (
        <>
          <div className="color-picker color-picker__save-overlay"></div>
          <div className="row color-picker__save-modal">
            <div className="col-12 mt-2">Save color</div>
            <div
              className="col-12 my-2 d-flex align-items-center"
              style={{ zIndex: "inherit" }}
            >
              <div className="position-relative">
                <div className="color-box" style={{ backgroundColor: value }} />
              </div>
              <input
                className="d-flex align-items-center color-picker-value text-center"
                value={colord(value).toHex()}
                readOnly
                style={{ width: "65px" }}
              />
              <input
                className="builder-input b-ms-3 align-self-center"
                autoFocus
                value={colorName}
                onChange={(ev) => setColorName(ev.target.value)}
              />
            </div>
            <div className="my-3">
              <button
                className="btn btn-primary"
                onClick={() => handleSubmit()}
              >
                Save
              </button>
              <a
                className="b-px-3 hover-underline"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setSaveFormActive(false);
                }}
              >
                Cancel
              </a>
            </div>
          </div>
        </>
      )}
      <div>
        <label className="color-picker color-picker__title b-mb-1">
          {title}
        </label>
        <div className="float-end">
          <MoreHorizIcon
            onClick={(e) => {
              e.preventDefault();
              setDropDownOpen(!dropDownOpen);
            }}
          />

          {dropDownOpen && (
            <ul className="color-picker-dropdown">
              <li>
                <span
                  onClick={(e) => {
                    handleReset();
                  }}
                  className="dropdown-item builder-dropdown__item"
                >
                  Reset
                </span>
              </li>
              {/* <li>
                <span className="dropdown-item builder-dropdown__item">
                  Remove
                </span>
              </li> */}
              <li>
                <span
                  className="dropdown-item builder-dropdown__item"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSaveFormActive(true);
                    setDropDownOpen(false);
                  }}
                >
                  Save
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center color-picker color-picker__swatches-container b-mb-2">
        {colors?.map((x: any) => (
          <div
            className="b-m-1 color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative"
            key={x.id}
          >
            <div
              key={x.id}
              onClick={() => {
                setSelectedColorId(x.id);
                onChange(x.color, x.id);
                // setColorPickerOpen(false);
              }}
              className={
                "color-picker color-picker__swatch-item" +
                (selectedColorId === x.id
                  ? " color-picker__swatch-item--active"
                  : "")
              }
              style={{ background: x.color }}
            ></div>
          </div>
        ))}
      </div>
      <RgbaStringColorPicker
        color={colord(value).toRgbString()}
        onChange={(val) => {
          setSelectedColorId(null);

          if (returnType === "rgb") {
            onChange(colord(val).toRgbString());
          } else {
            onChange(colord(val).toHex());
          }
        }}
      />
      <div
        className={
          "d-flex b-gap-1 b-mt-2" + (format?.value == "rgb" ? " b-mb-2" : "")
        }
        style={{ fontSize: "12px" }}
      >
        <Select
          isSearchable={false}
          className="m-0"
          styles={TextEditorColorPickerSelectStyle}
          noOptionsMessage={({}) => ""}
          value={format}
          onChange={(val) => setFormat(val)}
          options={formats}
          placeholder=""
        ></Select>
        {format?.value === "hex" && (
          <input
            className="color-picker color-picker__input builder-input text-center"
            style={{ width: "75px", height: "19.2px" }}
            onChange={(evt) => {
              if (returnType === "rgb") {
                onChange(colord(evt.target.value).toRgbString());
              } else {
                onChange(colord(evt.target.value).toHex());
              }
            }}
            value={colord(value).toHex()}
          />
        )}
        {format?.value === "rgb" && (
          <>
            <div className="position-relative">
              <input
                maxLength={3}
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                value={colord(value).toRgb().r}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), r: evt.target.value })
                }
              />
              <label className="color-picker color-picker__letter">R</label>
            </div>
            <div className="position-relative">
              <input
                maxLength={3}
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                value={colord(value).toRgb().g}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), g: evt.target.value })
                }
              />
              <label className="color-picker color-picker__letter">G</label>
            </div>
            <div className="position-relative">
              <input
                maxLength={3}
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), b: evt.target.value })
                }
                value={colord(value).toRgb().b}
              />
              <label className="color-picker color-picker__letter">B</label>
            </div>
            <div className="position-relative" style={{ minWidth: "40px" }}>
              <input
                max="1"
                className="color-picker color-picker__input builder-input text-center"
                style={{ height: "19.2px" }}
                onChange={(evt) =>
                  onChange({ ...colord(value).toRgb(), a: evt.target.value })
                }
                value={colord(value).toRgb().a}
              />
              <label className="color-picker color-picker__letter">A</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
