import { ApiClient } from './api_clientsApi'
import { Set } from 'immutable'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Set<ApiClient> = Set([])

export const documentsSlice = createSlice({
  name: 'api_clients',
  initialState,
  reducers: {
    SET_API_CLIENTS: (state: any, action: PayloadAction<ApiClient[]>) => {
        state = Set(action.payload)
        return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { SET_API_CLIENTS } = documentsSlice.actions

export default documentsSlice.reducer
