import React, { useState } from "react";

import Select from "react-select";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import { FormatColorText } from "@mui/icons-material";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";

import "./LanguageFontMenu.scss";

interface Props {
  id: number;
  onMouseUp?: MouseEvent;
  keyDownEvent: KeyboardEvent;
  triggerResize: () => void;
  setRange: (val: Range | undefined) => void;
}

export default function LanguageFontMenu({
  id,
  onMouseUp,
  keyDownEvent,
  triggerResize,
}: Props) {
  React.useEffect(() => {
    TypographiesAPI.loadTypographies();
  }, []);

  const typographies = useAppSelector((state) => state.builder.typographies);
  const languageComponent = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as any;

  const [menuOpen, setMenuOpen] = useState(false);

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as any;

  const languageSizeUnits = [
    { label: "px", value: "px" },
    { label: "rem", value: "rem" },
  ];

  const typographyOptions = typographies
    ? typographies.map((typography: any) => {
        return {
          label: typography.name,
          value: typography.id,
        };
      })
    : [];

  function extractNumbers(str: string) {
    let numbers = str.match(/^-?\d+/);
    if (numbers) {
      return numbers[0];
    }
    if (str === "small") {
      return 12;
    }

    if (str === "medium") {
      return 16;
    }

    if (str === "large") {
      return 20;
    }

    return "";
  }

  function extractUnits(str: string) {
    if (str === "small") return "px";
    if (str === "medium") return "px";
    if (str === "large") return "px";

    let numbers = extractNumbers(str);

    return str.replace(numbers.toString(), "");
  }

  return (
    <>
      <div className="d-flex">
        <div
          className="d-flex flex-wrap text-editor-options col-10"
          style={{ gap: "5px" }}
        >
          {component.attributes.type !== "image" && (
            <>
              <Select
                menuPortalTarget={document.getElementById(
                  "react-select-container"
                )}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(val) => {
                  updateStructureComponent(
                    component.id,
                    "attributes.typography_id",
                    val.value
                  );
                }} //onTypoChange(val)}
                className="m-0 cart-menu_input"
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={
                  typographyOptions.find(
                    (x) => x.value === component.attributes.typography_id
                  )
                    ? typographyOptions.find(
                        (x) => x.value === component.attributes?.typography_id
                      )
                    : null
                }
                options={typographyOptions}
                placeholder=""
              ></Select>
            </>
          )}

          <div
            className="background-menu_input"
            style={{
              margin: "0px",
            }}
          >
            <input
              type="number"
              style={{ width: "30px" }}
              className="global-editor__input builder-input"
              size={1}
              onChange={(e) => {
                updateStructureComponent(
                  component.id,
                  "attributes.size",
                  e.target.value +
                    languageSizeUnits.find(
                      (x) =>
                        x.value ===
                        extractUnits(component.attributes.size ?? "12px")
                    )?.value
                );
              }}
              value={extractNumbers(component.attributes?.size ?? "12px")}
            ></input>
            <Select
              isSearchable={false}
              menuPortalTarget={document.getElementById(
                "react-select-container"
              )}
              className="m-0"
              styles={TextEditorSelectStyle}
              value={languageSizeUnits.find(
                (x) =>
                  x.value === extractUnits(component.attributes?.size ?? "12px")
              )}
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.components.language.size",
                  extractNumbers(component.attributes?.size ?? "12px") +
                    val.value
                );
              }}
              options={languageSizeUnits}
              placeholder=""
            ></Select>
          </div>

          <div className="" style={{ position: "relative" }}>
            <FormatColorText onClick={() => setMenuOpen(!menuOpen)} />

            {menuOpen && (
              <div className="languageFontMenuColorPicker">
                <ColorPicker
                  title="Background"
                  value={component.attributes.styles.color ?? "#000"}
                  onChange={(val) => {
                    updateStructureComponent(
                      id,
                      "attributes.styles.color",
                      val
                    );
                  }}
                  setColorPickerOpen={() => {
                    (document.querySelector(
                      '.color-picker.show[data-bs-toggle="dropdown"]'
                    ) as HTMLElement)!.click();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
