import store from "../../../store"
import { Typography } from './typographiesApi'
import {
    CREATE_TYPOGRAPHY,
    REMOVE_TYPOGRAPHY_BY_ID,
    UPDATE_TYPOGRAPHIES,
    UPDATE_TYPOGRAPHY_BY_ID,
} from './typographiesReducer'


export function updateTypographies(payload: Typography[]) {
    return store.dispatch(
      UPDATE_TYPOGRAPHIES(payload)
    )
}
  
export function updateTypographyById(payload: {
    id: number
    data: Typography
}) {
    return store.dispatch(
      UPDATE_TYPOGRAPHY_BY_ID(payload)
    )
}
  
export function createTypography(payload: Typography) {
    return store.dispatch(
      CREATE_TYPOGRAPHY(payload)
    )
}
  
export function removeTypographyById(id: number) {
    return store.dispatch(
      REMOVE_TYPOGRAPHY_BY_ID({ id })
    )
}