import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import {
  HeaderComponent,
  LanguageComponent,
} from "../../../../_default/interfaces/base";
import Select from "react-select";
import {
  addNewComponent,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";

export default function LanguageMenu({
  id,
  triggerResize,
}: {
  id: number;
  triggerResize: () => void;
}) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as LanguageComponent;

  const website = useAppSelector((state) => state.builder.website);
  const languages = useAppSelector((state) => state.builder.languageSettings);

  const [currentActiveLanguage, setCurrentActiveLanguage] = React.useState(
    languages.activeLanguages.find(
      (lang) => lang.order === website.use_browser_language
    )
  );

  const languageSizeUnits = [
    { label: "px", value: "px" },
    { label: "rem", value: "rem" },
  ];

  const languageValues: any = [
    {
      value: "image",
      label: `Image`,
    },

    {
      value: "name",
      label: "Name",
    },

    {
      value: "locale",
      label: "Locale",
    },
  ];

  const languageDropdowns = [
    {
      value: "none",
      label: "None",
    },

    {
      value: "dropdown",
      label: "Dropdown",
    },
  ];

  const languageMenuType = [
    {
      value: "block",
      label: "Language",
    },
    {
      value: "none",
      label: "None",
    },
  ];

  const languageSizes = [
    {
      value: "small",
      label: "Small",
    },
    {
      value: "medium",
      label: "Medium",
    },
    {
      value: "large",
      label: "Large",
    },
  ];

  function onSizeChange(val: any) {
    updateStructureComponent(
      id,
      "attributes.components.language.size",
      val.value
    );
  }

  function onDropdownChange(val) {
    updateStructureComponent(id, "attributes.dropdown", val.value);
  }

  function onLanguageMenuTypeChange(val) {
    updateStructureComponent(
      id,
      "attributes.components.language.display",
      val.value === "none" ? "none" : "block"
    );
  }

  function onDisplayChange(val) {
    updateStructureComponent(id, "attributes.type", val.value);
  }

  function extractNumbers(str: string) {
    let numbers = str.match(/^-?\d+/);
    if (numbers) {
      return numbers[0];
    }
    if (str === "small") {
      return 12;
    }

    if (str === "medium") {
      return 16;
    }

    if (str === "large") {
      return 20;
    }

    return "";
  }

  function extractUnits(str: string) {
    if (str === "small") return "px";
    if (str === "medium") return "px";
    if (str === "large") return "px";

    let numbers = extractNumbers(str);

    return str.replace(numbers.toString(), "");
  }
  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div
      className="d-flex flex-wrap align-items-center"
      style={{ gap: "10px" }}
    >
      {/* <Select
        onChange={onLanguageMenuTypeChange}
        isSearchable={false}
        className="m-0"
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ""}
        value={
          component.attributes.components?.language &&
          component.attributes.components?.language.display === "block"
            ? languageMenuType[0]
            : languageMenuType[1]
        }
        options={languageMenuType}
      ></Select> */}
      <span>Type:</span>

      <Select
        onChange={onDisplayChange}
        isSearchable={false}
        className="m-0"
        styles={{
          ...TextEditorSelectStyle,
          container: (base) => ({
            ...base,
            width: "auto",
          }),
        }}
        noOptionsMessage={({}) => ""}
        value={
          component.attributes?.type
            ? languageValues.find((x) => x.value === component.attributes.type)
            : languageValues[0]
        }
        options={languageValues}
        placeholder=""
      ></Select>

      <span>Dropdown:</span>
      <Select
        onChange={onDropdownChange}
        isSearchable={false}
        className="m-0"
        styles={{
          ...TextEditorSelectStyle,
          container: (base) => ({
            ...base,
            width: "auto",
          }),
        }}
        noOptionsMessage={({}) => ""}
        value={
          component.attributes?.dropdown
            ? languageDropdowns.find(
                (x) => x.value === component.attributes?.dropdown
              )
            : languageDropdowns[0]
        }
        options={languageDropdowns}
        placeholder=""
      ></Select>
    </div>
  );
}
