import { useAppSelector } from "../../../../../store/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dropdown from "../../../Dropdown/Dropdown";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useEffect, useState } from "react";
import { SectionComponent } from "../../../../_default/interfaces/base";
import React from "react";
import { TextEditorSelectStyle } from "../SingleProductMenu/SingleProductMenu";
import Select from "react-select";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function CheckoutMenu({ id, triggerResize }: Props) {
  console.log("checkoutMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as any;

  const displays = [
    { label: "Hidden", value: "hidden" },
    { label: "Visible", value: "visible" },
  ];

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div className="d-flex flex-wrap align-items-center">
      <div style={{ marginRight: "5px" }}>Additional information:</div>

      <div className="border-menu_input" style={{ padding: "0px" }}>
        <Select
          menuPosition="fixed"
          className="b-mx-1 "
          isSearchable={false}
          styles={{
            ...TextEditorSelectStyle,
            container: (styles, data) => {
              return {
                ...TextEditorSelectStyle.container!(styles, data),

                width: "fit-content",
              };
            },

            singleValue: (styles, data) => {
              return {
                ...TextEditorSelectStyle.singleValue!(styles, data),
                width: "fit-content",
                padding: "0px",
              };
            },
          }}
          noOptionsMessage={({}) => ""}
          value={
            component.attributes.additional_info
              ? displays.find(
                  (x) => x.value === component.attributes.additional_info
                )
              : displays[0]
          }
          onChange={(val) => {
            if (!val) return;

            if (val.value) {
              updateStructureComponent(
                id,
                "attributes.additional_info",
                val.value
              );
            }
          }}
          options={displays}
          placeholder=""
        ></Select>
      </div>
    </div>
  );
}
