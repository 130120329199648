import { ReusableComponent } from './reusableComponentsApi'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: ReusableComponent | any = {}

export const reusableComponentsSlice = createSlice({
  name: 'reusableComponents',
  initialState,
  reducers: {
    UPDATE_REUSABLE_COMPONENTS: (state: any, action: PayloadAction<ReusableComponent>) => {
        state = action.payload
        return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { UPDATE_REUSABLE_COMPONENTS } = reusableComponentsSlice.actions

export default reusableComponentsSlice.reducer
