import React from "react";

interface Props {
  value: number;
  setValue: (value: number) => void;
}

export default function AddSubtractInputNumber({ value, setValue }: Props) {
  return (
    <div className="d-flex">
      <div
        style={{ fontSize: "14px" }}
        className="b-px-1"
        onClick={() => setValue(value - 1)}
      >
        {" "}
        −{" "}
      </div>
      <input
        type="number"
        className="global-editor__input builder-input spacing-menu_input"
        size={1}
        value={value}
        onChange={(ev) => {
          setValue(Number(ev.target.value));
        }}
      />
      <div
        style={{ fontSize: "14px" }}
        className="b-px-1"
        onClick={() => setValue(value + 1)}
      >
        {" "}
        +{" "}
      </div>
    </div>
  );
}
