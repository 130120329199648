import { useState } from "react";
import "./ProductSliderMenu.scss";
import React from "react";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import Select from "react-select";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../../store/hooks";
import { ProductDetailComponent } from "../../../../_default/interfaces/base";

export default function ProductSliderMenu({ id }: any) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as any;

  const attributes =
    component.attributes as ProductDetailComponent["attributes"];

  const [selectedHeight, setSelectedHeight] = useState(
    component.attributes.imageHeightNumber
      ? component.attributes.imageHeightNumber
      : "500"
  );

  const [selectedWidth, setSelectedWidth] = useState(
    component.attributes.imageWidthNumber
      ? component.attributes.imageWidthNumber
      : "500"
  );

  const [selectedSlideWidth, setSelectedSlideWidth] = useState(
    component.attributes.slideWidth ? component.attributes.slideWidth : 100
  );

  const [selectedThumbnailAlign, setSelectedThumbnailAlign] = useState(
    component.attributes.thumbnailAlign
      ? component.attributes.thumbnailAlign
      : "start"
  );

  const [selectedWidthUnit, setSelectedWidthUnit] = useState(
    component.attributes.imageWidthUnit
      ? component.attributes.imageWidthUnit
      : "px"
  );

  const [selectedHeightUnit, setSelectedHeightUnit] = useState(
    component.attributes.imageHeightUnit
      ? component.attributes.imageHeightUnit
      : "px"
  );

  const [selectedThumbHeight, setSelectedThumbHeight] = useState(
    component.attributes.thumbnailHeightNumber
      ? component.attributes.thumbnailHeightNumber
      : "120"
  );

  const [selectedThumbnailMediaWidth, setSelectedThumbnailMediaWidth] =
    useState(
      component.attributes.thumbnailMediaWidth
        ? component.attributes.thumbnailMediaWidth
        : "19"
    );

  const [selectedAutoscrollSpeed, setSelectedAutoscrollSpeed] = useState(
    component.attributes.autoscrollSpeed
      ? component.attributes.autoscrollSpeed
      : 1
  );

  const [selectedAutoplayInterval, setSelectedAutoplayInterval] = useState(
    component.attributes.autoplayInterval
      ? component.attributes.autoplayInterval
      : 3000
  );

  const [selectedThumbHeightUnit, setSelectedThumbHeightUnit] = useState(
    component.attributes.thumbnailHeightUnit
      ? component.attributes.thumbnailHeightUnit
      : "px"
  );

  const [selectedSlideWidthUnit, setSelectedSlideWidthUnit] = useState(
    component.attributes.slideWidthUnit
      ? component.attributes.slideWidthUnit
      : "%"
  );

  let widthTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  let alignTypes = [
    {
      value: "start",
      label: "left",
    },
    {
      value: "center",
      label: "center",
    },
    {
      value: "end",
      label: "right",
    },
  ];

  let speedTypes = [
    {
      value: 0.25,
      label: "0.25",
    },

    {
      value: 0.5,
      label: "0.5",
    },
    {
      value: 0.75,
      label: "0.75",
    },

    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
  ];

  let autoPlayTypes = [
    {
      value: "autoscroll",
      label: "Autoscroll",
    },
    {
      value: "autoplay",
      label: "Autoplay",
    },
    {
      value: "none",
      label: "None",
    },
  ];

  let objectFitTypes = [
    {
      value: "contain",
      label: "contain",
    },
    {
      value: "cover",
      label: "cover",
    },
    {
      value: "fill",
      label: "fill",
    },
    {
      value: "none",
      label: "none",
    },
    {
      value: "scale-down",
      label: "scale-down",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <span style={{ marginRight: "0px" }}>Fullscreen:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              attributes.hasOwnProperty("fullscreen")
                ? attributes.fullscreen
                : false
            }
            onChange={(e) => {
              updateStructureComponent(
                id,
                "attributes.fullscreen",
                e.target.checked
              );
            }}
          />
          <span className="slider round"></span>
        </label>

        <span style={{ marginRight: "0px" }}>Loop:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              component.attributes.hasOwnProperty("loop")
                ? component.attributes.loop
                : false
            }
            onChange={(e) => {
              updateStructureComponent(id, "attributes.loop", e.target.checked);
            }}
          />
          <span className="slider round"></span>
        </label>

        <span style={{ marginRight: "0px" }}>Maintain aspect ratio:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              component.attributes.hasOwnProperty("maintainAspectRatio")
                ? component.attributes.maintainAspectRatio === true ||
                  component.attributes.maintainAspectRatio === 1
                  ? true
                  : false
                : true
            }
            onChange={(e) => {
              updateStructureComponent(
                id,
                "attributes.maintainAspectRatio",
                e.target.checked
              );
              updateStructureComponent(id, "attributes.slideWidth", 100);

              setSelectedSlideWidth(100);
            }}
          />
          <span className="slider round"></span>
        </label>

        <span style={{ marginRight: "0px" }}>Thumbnails:</span>
        <label className="switch" style={{ marginRight: "5px" }}>
          <input
            type="checkbox"
            checked={
              attributes.hasOwnProperty("thumbnails")
                ? attributes.thumbnails
                : false
            }
            onChange={(e) => {
              updateStructureComponent(
                id,
                "attributes.thumbnails",
                e.target.checked
              );
            }}
          />
          <span className="slider round"></span>
        </label>

        {component.type !== "MediaSlider" && (
          <>
            <span className="">objectFit: </span>

            <div style={{ marginRight: "5px" }}>
              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={
                  objectFitTypes.find(
                    (x) => x.value === component.attributes.styles.objectFit
                  )
                    ? objectFitTypes.find(
                        (x) => x.value === component.attributes.styles.objectFit
                      )
                    : objectFitTypes[0]
                }
                onChange={(val) => {
                  updateStructureComponent(
                    id,
                    "attributes.styles.objectFit",
                    val.value
                  );

                  //   setSelectedWidth(val.value);
                  //   updateComponentStyle(id, {
                  //     width: component.attributes.styles.width
                  //       ? component.attributes.styles.width.replace(
                  //           `${selectedWidth}`,
                  //           val.value
                  //         )
                  //       : "",
                  //   });
                }}
                options={objectFitTypes}
                placeholder=""
              ></Select>
            </div>
          </>
        )}

        {component.attributes.maintainAspectRatio === true ||
        component.attributes.maintainAspectRatio === 1 ? (
          <></>
        ) : (
          <>
            <span
              style={{
                marginRight: "5px",
              }}
            >
              Container height:{" "}
            </span>
            <div style={{ display: "flex", gap: "5px" }}>
              <input
                disabled={
                  component.attributes.maintainAspectRatio === true ||
                  component.attributes.maintainAspectRatio === 1
                    ? true
                    : false
                }
                className="global-editor__input builder-input spacing-menu_input"
                size={1}
                value={selectedHeight}
                onChange={(ev) => {
                  // if (ev.target.value === "" || ev.target.value === "0") {
                  //   setSelectedWidth("px");
                  //   return;
                  // }

                  if (ev.target.value === "0") return;

                  setSelectedHeight(ev.target.value);

                  updateStructureComponent(
                    id,
                    "attributes.imageHeightNumber",
                    ev.target.value
                  );

                  updateStructureComponent(
                    id,
                    "attributes.imageHeightUnit",
                    selectedHeightUnit ? selectedHeightUnit : "px"
                  );
                }}
              />

              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={
                  widthTypes.find((x) => x.value === selectedHeightUnit)
                    ? widthTypes.find((x) => x.value === selectedHeightUnit)
                    : widthTypes[0]
                }
                onChange={(val) => {
                  setSelectedHeightUnit(val.value);

                  updateStructureComponent(
                    id,
                    "attributes.imageHeightUnit",
                    val.value
                  );
                }}
                options={widthTypes}
                placeholder=""
              ></Select>
            </div>
          </>
        )}

        <span style={{ marginRight: "5px", marginLeft: "5px" }}>
          Container width:{" "}
        </span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={selectedWidth}
            onChange={(ev) => {
              // if (ev.target.value === "" || ev.target.value === "0") {
              //   setSelectedWidth("px");
              //   return;
              // }

              // if (ev.target.value === "0") return;

              setSelectedWidth(ev.target.value);

              updateStructureComponent(
                id,
                "attributes.imageWidthNumber",
                ev.target.value
              );

              updateStructureComponent(
                id,
                "attributes.imageWidthUnit",
                selectedWidthUnit ? selectedWidthUnit : "px"
              );
            }}
          />

          <Select
            menuPosition="fixed"
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              widthTypes.find((x) => x.value === selectedWidthUnit)
                ? widthTypes.find((x) => x.value === selectedWidthUnit)
                : widthTypes[0]
            }
            onChange={(val) => {
              setSelectedWidthUnit(val.value);

              updateStructureComponent(
                id,
                "attributes.imageWidthUnit",
                val.value
              );
            }}
            options={widthTypes}
            placeholder=""
          ></Select>
        </div>

        {component.type === "MediaSlider" &&
          !component.attributes.maintainAspectRatio && (
            <>
              <span
                style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              >
                Slide width:{" "}
              </span>

              <div style={{ display: "flex", gap: "5px" }}>
                <input
                  className="global-editor__input builder-input spacing-menu_input"
                  size={1}
                  value={selectedSlideWidth}
                  onChange={(ev) => {
                    // if (ev.target.value === "" || ev.target.value === "0") {
                    //   setSelectedWidth("px");
                    //   return;
                    // }

                    // if (ev.target.value === "0") return;

                    setSelectedSlideWidth(ev.target.value);

                    updateStructureComponent(
                      id,
                      "attributes.slideWidth",
                      ev.target.value
                    );

                    updateStructureComponent(
                      id,
                      "attributes.slideWidthUnit",
                      selectedSlideWidthUnit ? selectedSlideWidthUnit : "px"
                    );
                  }}
                />

                <Select
                  menuPosition="fixed"
                  className="m-0 button-menu_input"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  noOptionsMessage={({}) => ""}
                  value={widthTypes.filter((x) => x.value === "%")[0]}
                  onChange={(val) => {
                    setSelectedWidthUnit(val.value);

                    updateStructureComponent(
                      id,
                      "attributes.imageWidthUnit",
                      val.value
                    );
                  }}
                  options={widthTypes.filter((x) => x.value === "%")}
                  placeholder=""
                ></Select>
              </div>
            </>
          )}

        {/* {component.type === "MediaSlider" && (
          <>
            <span
              style={{
                marginRight: "5px",
                marginLeft: "5px",
                textDecoration: component.attributes.maintainAspectRatio
                  ? "line-through"
                  : "none",
              }}
            >
              Slide width:{" "}
            </span>

            <div style={{ display: "flex", gap: "5px" }}>
              <input
                disabled={
                  component.attributes.maintainAspectRatio === true ||
                  component.attributes.maintainAspectRatio === 1
                    ? true
                    : false
                }
                className="global-editor__input builder-input spacing-menu_input"
                size={1}
                value={selectedSlideWidth}
                onChange={(ev) => {
                  // if (ev.target.value === "" || ev.target.value === "0") {
                  //   setSelectedWidth("px");
                  //   return;
                  // }

             

                  // if (ev.target.value === "0") return;

                  setSelectedSlideWidth(ev.target.value);

                  updateStructureComponent(
                    id,
                    "attributes.slideWidth",
                    ev.target.value
                  );

                  updateStructureComponent(
                    id,
                    "attributes.slideWidthUnit",
                    selectedSlideWidthUnit ? selectedSlideWidthUnit : "px"
                  );
                }}
              />

              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={widthTypes.filter((x) => x.value === "%")[0]}
                onChange={(val) => {
                  setSelectedWidthUnit(val.value);

                  updateStructureComponent(
                    id,
                    "attributes.imageWidthUnit",
                    val.value
                  );
                }}
                options={widthTypes.filter((x) => x.value === "%")}
                placeholder=""
              ></Select>
            </div>
          </>
        )} */}

        {component.type === "MediaSlider" && (
          <>
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Slide align:{" "}
            </span>

            <div style={{ display: "flex", gap: "5px" }}>
              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={
                  alignTypes.find(
                    (x) => x.value === component.attributes.slideAlign
                  )
                    ? alignTypes.find(
                        (x) => x.value === component.attributes.slideAlign
                      )
                    : alignTypes[1]
                }
                onChange={(val) => {
                  updateStructureComponent(
                    id,
                    "attributes.slideAlign",
                    val.value
                  );
                }}
                options={alignTypes}
                placeholder=""
              ></Select>
            </div>
          </>
        )}

        {(component.attributes.thumbnails === true ||
          component.attributes.thumbnails === 1) && (
          <>
            {" "}
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Thumbnail height:{" "}
            </span>
            <div style={{ display: "flex", gap: "5px" }}>
              <input
                className="global-editor__input builder-input spacing-menu_input"
                size={1}
                value={selectedThumbHeight}
                onChange={(ev) => {
                  // if (ev.target.value === "" || ev.target.value === "0") {
                  //   setSelectedWidth("px");
                  //   return;
                  // }

                  if (ev.target.value === "0") return;

                  setSelectedThumbHeight(ev.target.value);

                  updateStructureComponent(
                    id,
                    "attributes.thumbnailHeightNumber",
                    ev.target.value
                  );

                  updateStructureComponent(
                    id,
                    "attributes.thumbnailHeightUnit",
                    selectedThumbHeightUnit ? selectedThumbHeightUnit : "px"
                  );
                }}
              />

              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={
                  widthTypes.find((x) => x.value === selectedThumbHeightUnit)
                    ? widthTypes.find(
                        (x) => x.value === selectedThumbHeightUnit
                      )
                    : widthTypes[0]
                }
                onChange={(val) => {
                  setSelectedThumbHeightUnit(val.value);

                  updateStructureComponent(
                    id,
                    "attributes.thumbnailHeightUnit",
                    val.value
                  );
                }}
                options={widthTypes}
                placeholder=""
              ></Select>
            </div>
          </>
        )}

        {(component.attributes.thumbnails === true ||
          component.attributes.thumbnails === 1) && (
          <>
            {" "}
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Thumbnail flex width:{" "}
            </span>
            <div style={{ display: "flex", gap: "5px" }}>
              <input
                className="global-editor__input builder-input spacing-menu_input"
                size={1}
                value={selectedThumbnailMediaWidth}
                onChange={(ev) => {
                  // if (ev.target.value === "" || ev.target.value === "0") {
                  //   setSelectedWidth("px");
                  //   return;
                  // }

                  if (ev.target.value === "0") return;

                  setSelectedThumbnailMediaWidth(ev.target.value);

                  updateStructureComponent(
                    id,
                    "attributes.thumbnailMediaWidth",
                    ev.target.value
                  );
                }}
              />

              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={widthTypes.filter((x) => x.value === "%")}
                onChange={(val) => {}}
                options={widthTypes.filter((x) => x.value === "%")}
                placeholder=""
              ></Select>
            </div>
          </>
        )}

        {component.type === "MediaSlider" &&
          (component.attributes.thumbnails === true ||
            component.attributes.thumbnails === 1) && (
            <>
              <span style={{ marginRight: "5px", marginLeft: "5px" }}>
                Thumbnail align:{" "}
              </span>

              <div style={{ display: "flex", gap: "5px" }}>
                <Select
                  menuPosition="fixed"
                  className="m-0 button-menu_input"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  noOptionsMessage={({}) => ""}
                  value={
                    alignTypes.find(
                      (x) => x.value === component.attributes.thumbnailAlign
                    )
                      ? alignTypes.find(
                          (x) => x.value === component.attributes.thumbnailAlign
                        )
                      : alignTypes[0]
                  }
                  onChange={(val) => {
                    updateStructureComponent(
                      id,
                      "attributes.thumbnailAlign",
                      val.value
                    );
                  }}
                  options={alignTypes}
                  placeholder=""
                ></Select>
              </div>
            </>
          )}

        {component.type === "MediaSlider" && (
          <>
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Autoplay:
            </span>

            <div style={{ display: "flex", gap: "5px" }}>
              <Select
                menuPosition="fixed"
                className="m-0 button-menu_input"
                isSearchable={false}
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={
                  autoPlayTypes.find(
                    (x) => x.value === component.attributes.autoplay
                  )
                    ? autoPlayTypes.find(
                        (x) => x.value === component.attributes.autoplay
                      )
                    : autoPlayTypes.find((x) => x.value === "none")
                }
                onChange={(val) => {
                  updateStructureComponent(
                    id,
                    "attributes.autoplay",
                    val.value
                  );
                }}
                options={autoPlayTypes}
                placeholder=""
              ></Select>
            </div>
          </>
        )}
        {component.attributes.autoplay === "autoscroll" && (
          <>
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Autoscroll speed:{" "}
            </span>

            <Select
              menuPosition="fixed"
              className="m-0 button-menu_input"
              isSearchable={false}
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={
                speedTypes.find(
                  (x) => x.value === component.attributes.autoscrollSpeed
                )
                  ? speedTypes.find(
                      (x) => x.value === component.attributes.autoscrollSpeed
                    )
                  : speedTypes.find((x) => x.value === 1)
              }
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.autoscrollSpeed",
                  val.value
                );
              }}
              options={speedTypes}
              placeholder=""
            ></Select>
          </>
        )}

        {component.attributes.autoplay === "autoplay" && (
          <>
            <span style={{ marginRight: "5px", marginLeft: "5px" }}>
              Autoplay interval ms:{" "}
            </span>{" "}
            <input
              className="global-editor__input builder-input spacing-menu_input"
              size={1}
              value={selectedAutoplayInterval}
              onChange={(ev) => {
                // if (ev.target.value === "" || ev.target.value === "0") {
                //   setSelectedWidth("px");
                //   return;
                // }

                setSelectedAutoplayInterval(ev.target.value);

                updateStructureComponent(
                  id,
                  "attributes.autoplayInterval",
                  ev.target.value
                );
              }}
            />
          </>
        )}
      </div>
    </>
  );
}
