import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../../store/hooks";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { MoreHorizOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import React from "react";

export default function ColMenu({
  id,
  triggerResize,
}: {
  id: number;
  triggerResize: () => void;
}) {
  console.log("ColMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  // Expand if more than 1 bootstrap class active
  const [expanded, setExpanded] = useState(
    component.attributes.classes.split(" ").filter((x) => x.includes("col-"))
      .length > 1
  );
  const cols = [
    { label: "1/12", value: "1" },
    { label: "1/6", value: "2" },
    { label: "1/4", value: "3" },
    { label: "1/3", value: "4" },
    { label: "5/12", value: "5" },
    { label: "1/2", value: "6" },
    { label: "7/12", value: "7" },
    { label: "2/3", value: "8" },
    { label: "3/4", value: "9" },
    { label: "5/6", value: "10" },
    { label: "11/12", value: "11" },
    { label: "12/12", value: "12" },
  ];
  const sizes = [
    { label: "XS", pixels: "(0<)", value: "col-" },
    { label: "SM", pixels: "(576<)", value: "col-sm-" },
    { label: "MD", pixels: "(768<)", value: "col-md-" },
    { label: "LG", pixels: "(992<)", value: "col-lg-" },
    { label: "XL", pixels: "(1200<)", value: "col-xl-" },
  ];

  const alignments = [
    { label: "Left", value: "start" },
    { label: "Center", value: "center" },
    { label: "Right", value: "end" },
  ];

  const alignContentAlignments = [
    { label: "Up", value: "start" },
    { label: "Center", value: "center" },
    { label: "Down", value: "end" },
  ];

  const displays = [
    { label: "Hidden", value: "none" },
    { label: "Visible", value: "block" },
  ];

  function addRemoveClass(size: string, number: string) {
    let classes = component.attributes.classes.split(" ");
    classes = classes.filter((x) => !x.match(`${size}\\d+`));
    // If class previously existed remove it from list
    if (!component.attributes.classes.split(" ").includes(size + number)) {
      classes.push(size + number);
    }
    updateStructureComponent(id, "attributes.classes", classes.join(" "));
  }

  function renderRow(size: (typeof sizes)[0]) {
    return (
      <>
        <div className="text-end" style={{ width: "17px" }}>
          {size.label}
        </div>
        <div className="text-end" style={{ width: "45px" }}>
          {size.pixels}
        </div>

        <Select
          menuPosition="fixed"
          className="b-mx-1 border-menu_input"
          isSearchable={false}
          styles={{
            ...TextEditorSelectStyle,
            container: (styles, data) => {
              return {
                ...TextEditorSelectStyle.container!(styles, data),

                width: "70px",
              };
            },
            control: (styles, data) => {
              return {
                ...TextEditorSelectStyle.control!(styles, data),
                width: "100%",
              };
            },
          }}
          noOptionsMessage={({}) => ""}
          value={cols.find((x) => {
            return component.attributes.classes
              .split(" ")
              .includes(size.value + x.value);
          })}
          onChange={(e) => {
            console.log(e);
            addRemoveClass(size.value, e!.value);
          }}
          options={cols}
          placeholder="Select.."
        ></Select>

        <div>
          <Select
            menuPosition="fixed"
            className="b-mx-1 border-menu_input"
            isSearchable={false}
            styles={{
              ...TextEditorSelectStyle,
              container: (styles, data) => {
                return {
                  ...TextEditorSelectStyle.container!(styles, data),

                  width: "70px",
                };
              },
              control: (styles, data) => {
                return {
                  ...TextEditorSelectStyle.control!(styles, data),
                  width: "100%",
                };
              },
            }}
            noOptionsMessage={({}) => ""}
            value={displays.find((x) => {
              const classes = component.attributes.classes.split(" ");

              if (size.label === "XS") {
                return classes.some((c) => {
                  if (c === "d-none") return x.value === "none";
                  if (c === "d-block") return x.value === "block";
                  if (c !== "d-none" && c !== "d-block")
                    return x.value === "block";
                });
              } else {
                return classes.some((c) => {
                  if (c === `d-${size.label.toLowerCase()}-none`)
                    return x.value === "none";
                  if (c === `d-${size.label.toLowerCase()}-block`)
                    return x.value === "block";
                  if (
                    c !== `d-${size.label.toLowerCase()}-none` &&
                    c !== `d-${size.label.toLowerCase()}-block`
                  )
                    return x.value === "block";
                });
              }
            })}
            onChange={(val) => {
              if (val === undefined || val === null) return;

              let value = component.attributes.classes;

              if (size.label === "XS") {
                if (value.includes("d-none")) {
                  if (val.value === "block") {
                    value = value.replace("d-none", ``);
                  } else {
                    value += ` d-none`;
                  }
                } else {
                  value += ` d-${val.value}`;
                }
              } else {
                if (size.label === "XL") {
                  if (
                    value.includes(
                      `d-${size.label.toLowerCase()}-none d-xxl-none`
                    )
                  ) {
                    if (val.value === "block") {
                      value = value.replace(
                        `d-${size.label.toLowerCase()}-none`,
                        ``
                      );

                      value = value.replace(`d-xxl-none`, ``);
                    }
                  } else {
                    if (val.value === "none") {
                      value += ` d-${size.label.toLowerCase()}-${
                        val.value
                      } d-xxl-none`;
                    }
                  }
                } else {
                  if (value.includes(`d-${size.label.toLowerCase()}-none`)) {
                    if (val.value === "block") {
                      value = value.replace(
                        `d-${size.label.toLowerCase()}-none`,
                        ``
                      );
                    }
                  } else {
                    value += ` d-${size.label.toLowerCase()}-${val.value}`;
                  }
                }
              }

              updateStructureComponent(id, "attributes.classes", value);
            }}
            options={displays}
            placeholder=""
          ></Select>
        </div>
        <span>Text:</span>
        <div>
          <Select
            menuPosition="fixed"
            className="b-mx-1 border-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              alignments.find((x) => {
                const classes = component.attributes.classes.split(" ");

                return classes.includes(
                  `text-${size.label.toLowerCase()}-${x.value}`
                );
              })
                ? alignments.find((x) => {
                    const classes = component.attributes.classes.split(" ");

                    return classes.includes(
                      `text-${size.label.toLowerCase()}-${x.value}`
                    );
                  })
                : alignments[0]
            }
            onChange={(val) => {
              const classes = component.attributes.classes.split(" ");

              if (
                classes
                  .map((x) => x.includes(`text-${size.label.toLowerCase()}`))
                  .includes(true)
              ) {
                updateStructureComponent(
                  id,
                  "attributes.classes",
                  classes
                    .map((x) => {
                      if (x.includes(`text-${size.label.toLowerCase()}`)) {
                        // Need to take into consideration, that `text-${size.label.toLowerCase()}` can end with -start -center or -end

                        return x.replace(
                          x,
                          `text-${size.label.toLowerCase()}-${val.value}`
                        );
                      } else {
                        return x;
                      }
                    })
                    .join(" ")
                );
              } else {
                updateStructureComponent(
                  id,
                  "attributes.classes",
                  `${classes.join(" ")} text-${size.label.toLowerCase()}-${
                    val.value
                  }`
                );
              }
            }}
            options={alignments}
            placeholder=""
          ></Select>
        </div>

        {/* <span>Align content:</span>
        <div>
          <Select
            menuPosition="fixed"
            className="b-mx-1 border-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={
              alignContentAlignments.find((x) => {
                const classes = component.attributes.classes.split(" ");

                return classes.includes(
                  `align-self-${size.label.toLowerCase()}-${x.value}`
                );
              })
                ? alignContentAlignments.find((x) => {
                    const classes = component.attributes.classes.split(" ");

                    return classes.includes(
                      `align-self-${size.label.toLowerCase()}-${x.value}`
                    );
                  })
                : alignContentAlignments[0]
            }
            onChange={(val) => {
              const classes = component.attributes.classes.split(" ");

              console.log(size.label, "THIS IS THE SIZE LABEL");

              if (
                classes
                  .map((x) =>
                    x.includes(`align-self-${size.label.toLowerCase()}`)
                  )
                  .includes(true)
              ) {
                updateStructureComponent(
                  id,
                  "attributes.classes",
                  classes
                    .map((x) => {
                      if (
                        x.includes(`align-self-${size.label.toLowerCase()}`)
                      ) {
                        if (size.label == "XS" || size.label === "xs") {
                          return x.replace(x, `align-self-${val.value}`);
                        } else {
                          return x.replace(
                            x,
                            `align-self-${size.label.toLowerCase()}-${
                              val.value
                            }`
                          );
                        }

                        // Need to take into consideration, that `text-${size.label.toLowerCase()}` can end with -start -center or -end
                      } else {
                        return x;
                      }
                    })
                    .join(" ")
                );
              } else {
                if (size.label == "XS" || size.label === "xs") {
                  console.log("THIS PART RAN, WE ARE HERE XS");

                  updateStructureComponent(
                    id,
                    "attributes.classes",
                    classes
                      .map((x) => {
                        if (x.includes(`align-self-`)) {
                          if (size.label == "XS" || size.label === "xs") {
                            return x.replace(x, `align-self-${val.value}`);
                          } else {
                            return x.replace(
                              x,
                              `align-self-${size.label.toLowerCase()}-${
                                val.value
                              }`
                            );
                          }

                          // Need to take into consideration, that `text-${size.label.toLowerCase()}` can end with -start -center or -end
                        } else if (
                          (!classes
                            .map((x) => x.includes(`align-self-`))
                            .includes(true) &&
                            size.label == "XS") ||
                          size.label === "xs"
                        ) {
                          return `align-self-${val.value}`;
                        } else {
                          return x;
                        }
                      })
                      .join(" ")
                  );
                } else {
                  updateStructureComponent(
                    id,
                    "attributes.classes",
                    `${classes.join(
                      " "
                    )} align-self-${size.label.toLowerCase()}-${val.value}`
                  );
                }
              }
            }}
            options={alignContentAlignments}
            placeholder=""
          ></Select>
        </div> */}
      </>
    );
  }

  return (
    <div className="overflow-auto">
      <div className="builder-column builder-column__container">
        <div className="builder-column builder-column__row">
          {renderRow(sizes[0])}

          <div className="flex-grow-1 text-end">
            <MoreHorizOutlined
              style={{ height: "16px", width: "19px" }}
              onClick={() => {
                setExpanded(!expanded);
                triggerResize();
              }}
            />
          </div>
        </div>
        {expanded &&
          sizes.slice(1).map((size) => (
            <div
              className="builder-column builder-column__row"
              key={size.value}
              style={{ marginTop: "3px" }}
            >
              {renderRow(size)}
            </div>
          ))}
      </div>
    </div>
  );
}
