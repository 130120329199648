import {
  fetchApi,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
  postFormHeaders,
} from "../../../../api/api";
import { Document } from "../documents/documentsApi";
import { updateWebsite } from "./websiteActions";

export interface Website {
  id: number;
  name: string;
  base_font_size: string;
  row_color: string;
  element_color: string;
  description: string;
  column_color: string;
  content_width_unit: string;
  content_width_size: number;
  shop_b_radius: string;
  status: string;
  tracking: string;
  type: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  use_browser_language: number;
  parent_custom_file_id: number;
  favicon: any;
  default_typography_id?: number;
}

const WebsiteAPI = {
  loadWebsite,
  saveWebsite,
  publishWebsite,
  setWebsiteFavicon,
};
export default WebsiteAPI;

export function loadWebsite() {
  return fetchApiGet<Website>(`/website`, {}).then((res) => updateWebsite(res));
}

function saveWebsite(data: Website) {
  return fetchApiPut<Website>(`/website`, {
    ...data,
  }).then((x) => {
    updateWebsite({ ...x });
  });
}

function publishWebsite() {
  return fetchApiPost(`/publish`, {}, false)
    .then((res: any) => res.text())
    .then((res) => console.log("Publish website response: ", res));
}

function setWebsiteFavicon(file: File) {
  const data = new FormData();
  data.append("files[]", file);
  return fetchApi<{ website: Website; documents: Document[] }>(
    `/setWebsiteFavicon`,
    postFormHeaders(data)
  ).then((x) => updateWebsite(x.website));
}
