
import { FontUrl } from './font_urlApi'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: FontUrl | any = {}

export const font_urlSlice = createSlice({
  name: 'font_url',
  initialState,
  reducers: {
    SET_FONT_URL: (state: any, action: PayloadAction<string>) => {
      return {...state, url: action.payload}
    },
  },
})

// Action creators are generated for each case reducer function
export const { SET_FONT_URL } = font_urlSlice.actions

export default font_urlSlice.reducer
