import store from "../../../store"
import { Color } from './colorApi'
import {
  UPDATE_COLORS,
  ADD_COLOR,
} from './colorReducer'

export function updateColors(payload: Color[]) {
  return store.dispatch(
    UPDATE_COLORS(payload)
  )
}

export function addNewColor(payload: Color) {
  return store.dispatch(
    ADD_COLOR(payload)
  )
}